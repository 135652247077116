import styled, { css } from 'styled-components';
import { fontSizes, gradients, colors, layout } from '../../constants';

const CtaButtonContainer = styled.a`
    padding: 0 ${layout.huge}px;
    display:flex;
    justify-content: center;
    align-items: center;
    transition-property: opacity;
    transition-duration: 200ms;
    height: 40px;
    border: 0px;
    border-radius: 8px;
    font-family: "latoBold", Helvetica, Arial, sans-serif;
    color: white;
    cursor: pointer;
    background: linear-gradient(
        135deg,
        ${gradients.mainGradient}
    );
    text-decoration: none;
    text-transform: uppercase;
    font-size: ${fontSizes.large}px;

    &.CtaWhite {
        background: white;
        border: none;
        color: ${colors.main};

        &:hover {
            opacity: 80%;
        }
    }

    ${props => ! props.disabled && css`
        &:hover {
            opacity: 60%;
        }
    `}

    ${props => props.disabled && css`
        cursor: not-allowed;
        background: #C4C4C4;
        background-color: #C4C4C4;
        border: none;
        box-shadow: none;
    `}
`;

const CtaButton = ({ className, href, onClick, children, disabled=false }) => {
    return (
        <CtaButtonContainer disabled={disabled} href={href} className={className} onClick={onClick}>
            {children}
        </CtaButtonContainer>
    );
}

export default CtaButton;