import i18n from '../plugin/i18next/i18next';

export const errorsCode = [
    {
        code: 1,
        title: i18n.t('errorPage.codes.1.title'),
        subtitle: i18n.t('errorPage.codes.1.subtitle'),
        description: i18n.t('errorPage.codes.1.description')
    },
    {
        code: 2,
        title: i18n.t('errorPage.codes.2.title'),
        subtitle: i18n.t('errorPage.codes.2.subtitle'),
        description: i18n.t('errorPage.codes.2.description'),
    },
    {
        code: 3,
        title: i18n.t('errorPage.codes.3.title'),
        subtitle: i18n.t('errorPage.codes.3.subtitle'),
        description: i18n.t('errorPage.codes.3.description'),
    },
    {
        code: 4,
        title: i18n.t('errorPage.codes.4.title'),
        subtitle: i18n.t('errorPage.codes.4.subtitle'),
        description: i18n.t('errorPage.codes.4.description')
    },
];