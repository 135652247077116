import { neonomadeBoUrl, neonomadeFinderUrl, neonomadeUrl } from './commonLinks';
import i18n from '../plugin/i18next/i18next';

export const menu = [
    {
        title: i18n.t('header.findEspace'),
        type: 'link',
        url: neonomadeFinderUrl,
        gaEventName: 'clic-menu-trouver-un-espace',
    },
    {
        title: i18n.t('header.ourRessource.text'),
        type: 'submenu',
        subMenu: [
            {
                url: `/nos-etudes`,
                isInternal: true,
                title: i18n.t('header.ourRessource.options.studies'),
                gaEventName: 'clic-menu-etudes',
            },
            {
                url: `/nos-clients-temoignent`,
                isInternal: true,
                title: i18n.t('header.ourRessource.options.customerTestimonials'),
                gaEventName: 'clic-menu-temoignages-clients',
            },
            {
                url: '/blog',
                isInternal: true,
                title: i18n.t('header.ourRessource.options.blog'),
                gaEventName: 'clic-menu-blog',
            },
        ]
    },
    {
        title: i18n.t('header.becomePartner'),
        type: 'link',
        url: '/partner',
        isInternal: true,
        gaEventName: 'clic-menu-devenir-espace-partenaire',
    },
    {
        title: i18n.t('header.buttonConnection.text'),
        type: 'submenu',
        subMenu: [
            {
                url: `${neonomadeUrl}/connexion/`,
                title: i18n.t('header.buttonConnection.options.front.title'),
                description: i18n.t('header.buttonConnection.options.front.description'),
                gaEventName: 'clic-connexion-nomade',
            },
            {
                url: `${neonomadeBoUrl}/espace/connexion/`,
                title: i18n.t('header.buttonConnection.options.gestionnaireEspace.title'),
                description: i18n.t('header.buttonConnection.options.gestionnaireEspace.description'),
                gaEventName: 'clic-connexion-gestionnaire-espace',
            },
            {
                url: `${neonomadeBoUrl}/entreprise/connexion/`,
                title: i18n.t('header.buttonConnection.options.gestionnaireEntreprise.title'),
                description: i18n.t('header.buttonConnection.options.gestionnaireEntreprise.description'),
                gaEventName: 'clic-connexion-administrateur-entreprise',
            },
        ]
    }
];