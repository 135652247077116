import styled from 'styled-components';
import Meta from "../components/structure/Meta"
import blueLogo from '../assets/logos/blue.svg';
import { ErrorPageContentBlock } from '../components/block/';
import { errorsCode } from '../datas/errorsCode';
import { colors, breakpoints } from '../components/constants'

const ErrorPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    text-align: center;
    min-height: 100vh;
    padding: 20px;
    font-weight: 100;
    line-height: 1.5;

    .errorPageHeader {
        margin-bottom: 24px;

        img {
            height: 23px;
            max-width: 100%;
        }
    }

    .errorImageContainer {
    	margin-bottom: 7px;
        
        img {
            max-width: 100%;
            height: auto;
            vertical-align: middle;
            border: 0;
        }
    }

    .errorTitleContainer {
    	color: ${colors.main};
    	text-transform: uppercase;
    	font-size: 18px;
    	margin-bottom: 19px;
    }

    .errorSubTitleContainer {
        color: ${colors.darkGrey2};
    	font-size: 16px;
    	margin-bottom: 12px;
    }

    .errorDescriptionContainer {
        color: ${colors.darkGrey2};
    	font-size: 15px;
    	margin-bottom: 24px;
    }

    .errorRedirectionsController {
    	display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    	width: 100%;
    	font-size: 15px;
    	color: ${colors.main};

        .flex1 {
            flex: 1;
        }

    	a {
    		color: ${colors.main} !important;
    		text-decoration: underline;

    		&:hover {
    			text-decoration: none;
    		}
    	}

        .link {
            border-bottom: none;
            font-size: 18px;
        }
    }

    .small-hidden {
        display: block;
    }


    @media (max-width: ${breakpoints.small}) {
        .imgError {
            display : none;
        }
        
    }

    @media (min-width: ${breakpoints.small}) {
    	.errorPageHeader {
            
    		max-width: 1063px;
    		width: 100%;
    		margin-bottom: 0px;
    		text-align: left;

    		img {
    			position: relative;
    			top: 11.5px;
    		}
    	}

    	.errorImageContainer {
	    	margin-bottom: 22px;
	    }

	    .errorTitleContainer {
	    	font-size: 40px;
	    	margin-bottom: 40px;
	    	max-width: 770px;
	    }

	    .errorSubTitleContainer {
	    	font-size: 26px;
	    	margin-bottom: 24px;
	    	max-width: 770px;
	    }

	    .errorDescriptionContainer {
	    	font-size: 20px;
	    	margin-bottom: 45px;
	    	max-width: 770px;
	    }

	    .errorRedirectionsController {
	    	width: 100%;
	    	font-size: 18px;
	    	max-width: 350px;
	    }

        .imgErrorMobile {
            display : none;
        }
    }
`;

const ErrorPage = ({errorCode}) => {    

    // On recupère l'erreur avec le code
    const error = errorsCode.find((er) => er.code === errorCode);

    return (
        <>
            <Meta 
                title="404 Page non trouvée - Neo-nomade"
                description = ""
            ></Meta>
            <ErrorPageContainer>
                <div className="errorPageHeader">
                    <img src={blueLogo} className="blueLogo" alt="Logo de Neo-nomade"/>
                </div>
                <ErrorPageContentBlock title={error.title} subtitle={error.subtitle} description={error.description} />
            </ErrorPageContainer>
        </>
    );
}

export default ErrorPage;