import Meta from "../components/structure/Meta"
import Header from '../components/structure/Header';
import { TypeformBlock } from '../components/block';

const TypeformPage = ({formId}) => {
    
    return (
        <>
            <Meta 
                title="Typeform page"
                description = "Donnez accès à plus de 1300 espaces de coworking à la demande à vos salariés partout en France"
                url="/coworking/typeform"
            />
            <Header fixed={false} />

            <div>
                <TypeformBlock formId={formId} />
            </div>
        </>
    );
}

export default TypeformPage;