import styled from 'styled-components';
import { colors, fontSizes, layout, selectTheme } from '../../constants';
import Icon from '../Icon';
import Select from 'react-select';

const GreySelectControl = styled.div`
    display: flex;
    border-radius: 6px;
    outline: none;
    background-color: #F1F1F1;
    height: 100%;
    width: 100%;
    padding: 0 ${layout.medium}px 0 ${layout.large}px;
    border: 0;
    font-size: ${fontSizes.medium}px;
    color: ${colors.darkGrey};
    cursor: default;

    & > div {
        margin: 0;
        padding: 0;
    }
`;

const GreySelectIndicatorsContainer = styled.div`
    display: flex;
    align-items: center;

    & > i {
        transition-duration: 200ms;
        transition-property: transform;
    }
`;

// On remplace l'apparence générale du select
const Control = ({children, innerRef, innerProps}) => (
    <GreySelectControl ref={innerRef} {...innerProps}>{children}</GreySelectControl>
);

// On remplace le chevron de base par une icône de chez nous
const DropdownIndicator = ({...props}) => {
    const orientation = props.selectProps.menuIsOpen ? 'top' : 'bottom';

    return (
        <Icon icon="arrow-slider-right" size="smaller" orientation={orientation} gap="2" />
    );
};

// On remplace la section qui contient les différentes actions possibles (chevron, nettoyage)
const IndicatorsContainer = ({children}) => (
    <GreySelectIndicatorsContainer>{children}</GreySelectIndicatorsContainer>
);

// On remplace le séparateur par rien pour le supprimer
const IndicatorSeparator = () => null;

const GreySelect = (props) => {
    const selectProps = {
        ...props,
        isClearable: props.isClearable || false,
        isDisabled: props.isDisabled || false,
        isSearchable: props.isSearchable || false,
        components: {
            DropdownIndicator,
            Control,
            IndicatorSeparator,
            IndicatorsContainer
        },
        theme: (defaultTheme) => ({
            ...defaultTheme,
            colors: {
                ...defaultTheme.colors,
                ...selectTheme.colors,
                neutral50: 'rgba(0, 0, 0, .25)',
                neutral60: colors.darkGrey,
                neutral70: colors.darkGrey,
                neutral80: colors.darkGrey,
                neutral90: colors.darkGrey,
            }
        }),
    };

    return (
        <Select {...selectProps} />
    );
}

export default GreySelect;