// Couleurs utilisées
const colors = {
    main: '#2FB3BE',
    mainLight1: '#60D0DA',
    mainLight2: '#01E7D5',
    white: '#FFFFFF',
    darkGrey: '#4D4D4D',
    darkGrey2: '#5B5B5B',
    lightGrey: '#E5E5E5',
    grey: '#C4C4C4',
    dark: '#353535',
    red: '#E2574C',
    orange: '#FB8053',
    green: '#17D89D',
    blueHover: '#60D0DA',
    blueGrey: '#8E9DAF',
}

// Couleurs en RGB
const rgbColors = {
    green: '23, 216, 157',
}

// Espacements utilisés
const layout = {
    verySmall: 5,
    small: 10,
    medium: 15,
    large: 20,
    veryLarge: 25,
    huge: 30,
}

// Tailles de polices
const fontSizes = {
    small: 12,
    medium: 14,
    large: 16,
    veryLarge: 18,
}

// Dégradés
const gradients = {
    mainGradient: `${colors.main} 0%, ${colors.mainLight1} 75%, ${colors.mainLight2} 115%`,
    blueBackgroundGradient: `${colors.main} 1.36%, #06E5D5 120.79%`,
}

// Breakpoints pour le responsive
const breakpoints = {
    tiny: '320px',
    small: '535px',
    medium: '868px',
    large: '1024px',
    extraLarge: '1300px',
    ultraLarge: '1600px',
}

// Graisse des polices
const weight = {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
}

// Couleurs par défaut pour les listes déroulantes
const selectTheme = {
    colors: {
        primary: colors.main,
        primary75: 'rgba(47, 179, 190, .75)',
        primary50: 'rgba(47, 179, 190, .50)',
        primary25: 'rgba(47, 179, 190, .25)',
        danger: colors.red,
        dangerLight: colors.orange,
    }
}

export {
    colors,
    rgbColors,
    layout,
    fontSizes,
    gradients,
    breakpoints,
    weight,
    selectTheme,
}