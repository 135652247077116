import styled from 'styled-components';
import { colors, fontSizes, layout } from '../../constants';

const ButtonContainer = styled.a`
    padding: 0 ${layout.huge}px;
    background-color: white;
    border-radius: 8px;
    border: solid 1px ${colors.blueGrey};
    color: ${colors.blueGrey};
    transition-property: background-color, color;
    transition-duration: 200ms;
    line-height: ${fontSizes.large}px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    text-transform: uppercase;
    font-size: ${fontSizes.large}px;
    font-family: "latoBold", Helvetica, Arial, sans-serif;

    &:hover {
        background-color: ${colors.blueGrey};
        color: ${colors.white};
    }
`;

const Button = ({ className, href, onClick, children, target }) => {
    return (
        <ButtonContainer href={href} target={target} className={className} onClick={onClick}>
            {children}
        </ButtonContainer>
    );
}

export default Button;