import ParisPhoto from '../assets/images/paris_photo.webp';
import ParisPhoto2x from '../assets/images/paris_photo@2x.webp';
import MarseillePhoto from '../assets/images/marseille_photo.webp';
import MarseillePhoto2x from '../assets/images/marseille_photo@2x.webp';
import LillePhoto from '../assets/images/lille_photo.webp';
import LillePhoto2x from '../assets/images/lille_photo@2x.webp';
import BordeauxPhoto from '../assets/images/bordeaux_photo.webp';
import BordeauxPhoto2x from '../assets/images/bordeaux_photo@2x.webp';
import LyonPhoto from '../assets/images/lyon_photo.webp';
import LyonPhoto2x from '../assets/images/lyon_photo@2x.webp';
import NantesPhoto from '../assets/images/nantes_photo.webp';
import NantesPhoto2x from '../assets/images/nantes_photo@2x.webp';
import image1_block6 from '../assets/images/blog1.webp';
import image2_block6 from '../assets/images/blog2.webp';
import image3_block6 from '../assets/images/blog3.webp';
import ChristophePont from '../assets/images/ChristophePont.jpg';
import imageBlock4 from '../assets/images/logo_prestashop.png';
import FredericGalPhoto from '../assets/images/FredericGal.jpg';
import KatyNaujoksPhoto from '../assets/images/KatyNaujoks.jpg';
import CharlotteClementPhoto from '../assets/images/CharlotteClement.jpg';
import generaliIcon from '../assets/images/Generali-Icon.jpg';
import BouyguesTelecom from '../assets/images/BouyguesTelecom.jpg';
import bearingPointIcon from '../assets/images/bearingpoint.jpg';
import allianzLogo1 from '../assets/images/allianz_logo_1.png';
import atosLogo1 from '../assets/images/Atos_logo_1.png';
import leboncoin from '../assets/images/leboncoin.webp';
import bearingPointLogo from '../assets/images/bearingpoint_logo.png';
import prestashopLogo from '../assets/images/prestashop_logo.png';
import IledeFranceLogo from '../assets/images/region_ile_de_france_logo.png';
import JorgeMaia from '../assets/images/JorgeMaia.jpeg';
import { neonomadeBlogUrl } from './commonLinks';
import i18n from '../plugin/i18next/i18next';

import bearingPointLogoColor from '../assets/images/companies/bearingpoint.webp';
import bearingPointLogoColor2x from '../assets/images/companies/bearingpoint@2x.webp';
import bearingPointLogoColor3x from '../assets/images/companies/bearingpoint@3x.webp';

import ileDeFranceLogoColor from '../assets/images/companies/idf.webp';
import ileDeFranceLogoColor2x from '../assets/images/companies/idf@2x.webp';
import ileDeFranceLogoColor3x from '../assets/images/companies/idf@3x.webp';

import prestashopLogoColor from '../assets/images/companies/prestashop.webp';
import prestashopLogoColor2x from '../assets/images/companies/prestashop@2x.webp';
import prestashopLogoColor3x from '../assets/images/companies/prestashop@3x.webp';

import allianzLogoColor from '../assets/images/companies/allianz.webp';
import allianzLogoColor2x from '../assets/images/companies/allianz@2x.webp';
import allianzLogoColor3x from '../assets/images/companies/allianz@3x.webp';

import atosLogoColor from '../assets/images/companies/atos.webp';
import atosLogoColor2x from '../assets/images/companies/atos@2x.webp';
import atosLogoColor3x from '../assets/images/companies/atos@3x.webp';

export const clientsData = [
    {
        src: {
            default: bearingPointLogo,
            color: bearingPointLogoColor
        },
        srcset: {
            color: `${bearingPointLogoColor2x} 2x, ${bearingPointLogoColor3x} 3x`
        },
        className: 'bearingPointLogo',
        alt: i18n.t('logoOf', { company: 'BearingPoint' })
    },
    {
        src: {
            default: IledeFranceLogo,
            color: ileDeFranceLogoColor
        },
        srcset: {
            color: `${ileDeFranceLogoColor2x} 2x, ${ileDeFranceLogoColor3x} 3x`
        },
        className: 'IledeFranceLogo',
        alt: i18n.t('logoOf', { company: 'la région Ile de France' })
    },
    {
        src: {
            default: prestashopLogo,
            color: prestashopLogoColor
        },
        srcset: {
            color: `${prestashopLogoColor2x} 2x, ${prestashopLogoColor3x} 3x`
        },
        className: 'prestashopLogo',
        alt: i18n.t('logoOf', { company: 'Prestashop' })
    },
    {
        src: {
            default: allianzLogo1,
            color: allianzLogoColor
        },
        srcset: {
            color: `${allianzLogoColor2x} 2x, ${allianzLogoColor3x} 3x`
        },
        className: 'allianzLogo1',
        alt: i18n.t('logoOf', { company: 'Allianz' })

    },
    {
        src: {
            default: atosLogo1,
            color: atosLogoColor,
        },
        srcset: {
            color: `${atosLogoColor2x} 2x, ${atosLogoColor3x} 3x`
        },
        className: 'atosLogo1',
        alt: i18n.t('logoOf', { company: 'Atos' })

    },
];

export const block1SubtitleDatas = [
    {
        text: i18n.t('home.block1.subtitleData.1'),
    },

    {
        text: i18n.t('home.block1.subtitleData.2'),
    }
];

export const block2Datas = [
    {
        title: i18n.t('home.block2.data.1.title'),
        text: i18n.t('home.block2.data.1.text'),
        //subText: 'Présents en France, Suisse, Belgique et Espagne',
    },

    {
        title: i18n.t('home.block2.data.2.title'),
        text: i18n.t('home.block2.data.2.text'),
    },

    {
        title: i18n.t('home.block2.data.3.title'),
        text: i18n.t('home.block2.data.3.text'),
    },
];

export const block3Datas = [
    {
        icon: 'grow',
        title: i18n.t('home.block3.data.1.title'),
        text: i18n.t('home.block3.data.1.text'),
    },

    {
        icon: 'euro',
        title: i18n.t('home.block3.data.2.title'),
        text: i18n.t('home.block3.data.2.text'),
    },

    {
        icon: 'feather',
        title: i18n.t('home.block3.data.3.title'),
        text: i18n.t('home.block3.data.3.text'),
    },

    {
        icon: 'corner',
        title: i18n.t('home.block3.data.4.title'),
        text: i18n.t('home.block3.data.4.text'),
    },
];

export const block5Datas = {
    leftContent: [
        {
            title: i18n.t('home.block5.data.leftContent.1.title'),
            text: i18n.t('home.block5.data.leftContent.1.text'),
            subText: i18n.t('home.block5.data.leftContent.1.subText'),
        },

        {
            title: i18n.t('home.block5.data.leftContent.2.title'),
            text: i18n.t('home.block5.data.leftContent.2.text'),
        },
    ],

    rightContent: [
        {
            title: i18n.t('home.block5.data.rightContent.3.title'),
            text: i18n.t('home.block5.data.rightContent.3.text'),
            subText: i18n.t('home.block5.data.rightContent.3.subText')
        },

        {
            title:  i18n.t('home.block5.data.rightContent.4.title'),
            text: i18n.t('home.block5.data.rightContent.4.text'),
            subText: i18n.t('home.block5.data.rightContent.4.subText'),
        },
    ]
};

export const Block6Datas = [
    {
        title: i18n.t('home.block6.data.1.title'),
        text:  i18n.t('home.block6.data.1.text'),
        image: image1_block6,
        href: `${neonomadeBlogUrl}/infographie-teletravail-5-scenarios`,
    },
    {
        title: i18n.t('home.block6.data.2.title'),
        text: i18n.t('home.block6.data.2.text'),
        image: image2_block6,
        href: `${neonomadeBlogUrl}/quest-ce-que-le-travail-hybride-siege-hubs-coworking-domicile-une-pluralite-de-lieux-de-travail`,
    },
    {
        title: i18n.t('home.block6.data.3.title'),
        text: i18n.t('home.block6.data.3.text'),
        image: image3_block6,
        href: `${neonomadeBlogUrl}/pensons-la-disparition-du-bureau/`,
    }
]

export const etudesDatas = [
    {
        title: i18n.t('home.block6.data.1.title'),
        description:  "E-book : 5 scénarios qui vous aident à positionner le curseur télétravail au sein de votre entreprise !",
        image: image1_block6,
        href: `/blog/infographie-teletravail-5-scenarios`,
    },
    {
        title: "Panorama du coworking",
        description:  "Panorama du coworking : Découvrez la première analyse de données sur l’utilisation des espaces de coworking à la demande, par les entreprises.",
        image: "https://www.neo-nomade.com/entreprises/wp-content/uploads/2020/10/Espace-partage%CC%81-scaled.jpg",
        externalLink: true,
        href: `https://go.neo-nomade.com/%C3%A9tude-panorama`,
    },
    {
        title: "Livre blanc",
        description: "Livre blanc : on vous partage notre vision de la transformation du travail, dont le coworking est un accélérateur.",
        image: 'https://www.neo-nomade.com/entreprises/wp-content/uploads/2022/05/LB-coworking-1536x899-1-768x450.jpeg',
        externalLink: true,
        href: `https://go.neo-nomade.com/%C3%A9tude-panorama-0`,
    },
]

export const block7Datas = [
    {
        city: 'Paris',
        picture: ParisPhoto,
        picture2x: ParisPhoto2x,
    },
    {
        city: 'Marseille',
        picture: MarseillePhoto,
        picture2x: MarseillePhoto2x,
    },
    {
        city: 'Lille',
        picture: LillePhoto,
        picture2x: LillePhoto2x,
    },
    {
        city: 'Bordeaux',
        picture: BordeauxPhoto,
        picture2x: BordeauxPhoto2x,
    },
    {
        city: 'Lyon',
        picture: LyonPhoto,
        picture2x: LyonPhoto2x,
    },
    {
        city: 'Nantes',
        picture: NantesPhoto,
        picture2x: NantesPhoto2x,
    },
];

export const formContactData = [
    {
        text: i18n.t('formContact.data.1')
    },

    {
        text: i18n.t('formContact.data.2'),
    },

    {
        text: i18n.t('formContact.data.3'),
    },

    {
        text: i18n.t('formContact.data.4'),
    },
];

export const formContactEntrepriseSize = [
    { value: '1-10', label: '1-10' },
    { value: '11-50', label: '11-50' },
    { value: '51-500', label: '51-500' },
    { value: '501-1000', label: '501-1000' },
    { value: '1001-3000', label: '1001-3000' },
    { value: '3000+', label: '3000+' },
];

export const formContactJobs = [
    { value: 'Ressources humaines', label: i18n.t('formContact.jobs.RH') },
    { value: 'Office management', label: i18n.t('formContact.jobs.OfficeManagement') },
    { value: 'Responsable opérationnel', label: i18n.t('formContact.jobs.OperationalManager') },
    { value: 'Finance et Comptabilité', label: i18n.t('formContact.jobs.Finance&Accounting') },
    { value: 'Direction générale', label: i18n.t('formContact.jobs.ExecutiveManagement') },
    { value: 'Membre du CSE', label: i18n.t('formContact.jobs.CSEMember') },
    { value: 'Autre', label: i18n.t('formContact.jobs.other') },
];

export const trustOpinions = [
        {
            picture: CharlotteClementPhoto,
            image: imageBlock4,
            entrepriseLink: 'https://www.prestashop.com/fr',
            fullName: i18n.t('trustOpinions.Charlotte-Clément.fullName'),
            job: i18n.t('trustOpinions.Charlotte-Clément.job'),
            client: 'PrestaShop',
            opinion: i18n.t('trustOpinions.Charlotte-Clément.opinion'),
        },

        {
            picture: JorgeMaia,
            image: leboncoin,
            entrepriseLink: 'https://www.leboncoin.fr',            
            fullName: i18n.t('trustOpinions.Jorge-Maia.fullName'),
            job: i18n.t('trustOpinions.Jorge-Maia.job'),
            client: 'Leboncoin',
            opinion: i18n.t('trustOpinions.Jorge-Maia.opinion'),
        },
        {
            picture: KatyNaujoksPhoto,
            image: bearingPointIcon,
            entrepriseLink: 'https://www.bearingpoint.com',
            fullName: i18n.t('trustOpinions.Katy-Naujoks.fullName'),
            job: i18n.t('trustOpinions.Katy-Naujoks.job'),
            client: 'BearingPoint',
            opinion: i18n.t('trustOpinions.Katy-Naujoks.opinion'),
        },

        {
            picture: FredericGalPhoto,
            image: BouyguesTelecom,
            entreprise: 'Bouygues Bâtiment',
            fullName: i18n.t('trustOpinions.Frédéric-Gal.fullName'),
            job: i18n.t('trustOpinions.Frédéric-Gal.job'),
            client: 'Bouygues Bâtiment',
            opinion: i18n.t('trustOpinions.Frédéric-Gal.opinion'),
        },
        {
            picture: ChristophePont,
            image: generaliIcon,
            entrepriseLink: 'https://www.generali.fr/',
            fullName: i18n.t('trustOpinions.Christophe-Pont.fullName'),
            job: i18n.t('trustOpinions.Christophe-Pont.job'),
            client: 'Generali',
            opinion: i18n.t('trustOpinions.Christophe-Pont.opinion'),
        },

];