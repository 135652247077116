import styled, { css } from 'styled-components';
import { fontSizes, breakpoints } from '../../components/constants';
import Header from '../../components/structure/Header';
import Meta from "../../components/structure/Meta"
import RenderHeaderSticky from '../../components/hook/RenderHeaderSticky';
import Footer from '../../components/structure/Footer';
import Pagination from '../../components/block/Pagination';
import BlogLoader from './BlogLoader';
import TitleBlock from '../../components/ui/title/TitleBlock';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { htmlDecode } from '../../utils/blogUtils';
import BlogMenu from './BlogMenu';
import AlternativeBlogArticleThumbnail from '../../components/block/AlternativeBlogArticleThumbnail';



// Style de conteneur main du Bloc 6
const BlockContainer = styled.div`
    width: 100%;
    padding: 5rem 2rem 4rem 2rem;
    font-style: normal;
    font-size: ${fontSizes.large}px;
    color: #555555;
    
    ${(props) => props.displayMenu ? css`padding-top: 11rem !important;`: ''}

    @media screen and (min-width: ${breakpoints.medium}) and (max-width: ${breakpoints.large}) {
        padding: 6rem 4rem 7rem 4rem;
        font-size: ${fontSizes.medium}px;
    }

    @media screen and (min-width: ${breakpoints.large}) and (max-width: ${breakpoints.extraLarge}) {
        padding: 7rem 10rem 7rem 10rem;
        font-size: ${fontSizes.large}px;
    }

    @media screen and (min-width: ${breakpoints.extraLarge}) {        
        padding: 7rem 14rem 8rem 14rem;
        font-size: ${fontSizes.large}px;
    }
`;

// Style du conteneur de tous les contenus de bloc 6
const ListBlogThumbnail = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1100px;

    & > div {
        @media screen and (min-width: ${breakpoints.large}) {
            flex: calc(33% - 4rem);
        }
    }

    @media screen and (max-width: ${breakpoints.medium}) {
        grid-template-columns: 1fr;     
    }

    @media screen and (min-width: ${breakpoints.large}) {
        width: 100%        
    }
`;

const BlogArticleList = ({title, page, pageCount, postList, isLoading, description, displayMenu, linkText}) => {
    // On appelle le hook pour rendre le header sticky + ajouter un margin au block 1
    const isScrollingUp = RenderHeaderSticky();
    const location = useLocation();
    const params = useParams();

    return (
        <>
            <Meta 
                title={`${htmlDecode(title)} - Neo-nomade`}
                description = {description}
                url={location.pathname}
            ></Meta>
            <Header isScrollingUp={isScrollingUp} fixed={true} />
            {displayMenu && <BlogMenu currentPage={params.slug} />}
            <BlockContainer displayMenu={displayMenu}>
                <TitleBlock center html={true}>{title}</TitleBlock>
                <ListBlogThumbnail>
                    {isLoading && (<BlogLoader/>)}
                    {postList && postList.map((post,index) => (<AlternativeBlogArticleThumbnail key={`post-${index}`} post={post} linkText={linkText}/>))}
                    {!isLoading && !postList.length && <span>Aucun article à afficher</span>}
                </ListBlogThumbnail>

                <Pagination page={page} pageCount={pageCount} />
            </BlockContainer>

            <Footer />
        </>
    )
};

BlogArticleList.propTypes = {
    page: PropTypes.number,
    pageCount: PropTypes.number,
    isLoading: PropTypes.bool,
    title: PropTypes.string,
    postList: PropTypes.array,
};

BlogArticleList.defaultProps = {
    page: 1,
    pageCount: 1,
    isLoading: false,
}

export default BlogArticleList;