import styled from 'styled-components';
import { colors, fontSizes } from '../../constants';
import Icon from '../Icon';

const DirectionButtonContainer = styled.button`
    display:flex;
    align-items: center;
    justify-content: center;
    transition-property: background, color, border;
    transition-duration: 200ms;
    font-size: ${fontSizes.medium}px;
    height: 40px;
    width: 40px;
    background-color: ${colors.white};
    border: 1px solid ${colors.main};
    border-radius: 8px;
    color: ${colors.main};
    cursor: pointer;

    &:hover {
        border-color: ${colors.main};
        color: ${colors.white};
        background-color: ${colors.main};
    }
`;

const DirectionButton = ({ direction, onClick, label }) => {
    return (
        <DirectionButtonContainer aria-label={label} onClick={(e) => onClick(e)}>
            <Icon icon="arrow-slider-right" orientation={direction} />
        </DirectionButtonContainer>
    );
}

export default DirectionButton;