import Header from '../components/structure/Header';
import Meta from "../components/structure/Meta"
import { PartnerBlock, WorklibBanner } from '../components/block';
import RenderHeaderSticky from '../components/hook/RenderHeaderSticky';

const Partner = () => {
    // On appelle le hook pour rendre le header sticky + ajouter un margin au block 1
    const isScrollingUp = RenderHeaderSticky();

    return (
        <>
            <Meta 
                title="Neo-nomade – Devenez partenaire !"
                description = "Commercialisez gratuitement et facilement votre espace de coworking, et accédez à un flux complémentaire de réservations"
                url="/coworking/partner"
            ></Meta>
            <Header isScrollingUp={isScrollingUp} fixed={true} />
            <WorklibBanner/>
            <div>
                <PartnerBlock />
            </div>
        </>
    );
}

export default Partner;