import { Link } from "react-router-dom";
import { formateInternalUri } from "../hook/FormateInternalUri";
import { ClickEventGA4 } from "../hook/TrackingGA4";
import ArrowButton from "../ui/buttons/ArrowButton";
import Icon from "../ui/Icon";
import DesktopSubMenu from "./DesktopSubMenu";

const DesktopMenuConnected = ({ menuUser, setUserMenu }) => {

    // Si dans le menu utilisateur on a plus de 5 lien
    const tripleColumnClass = menuUser.menu.length > 5 ? 'tripleColumn' : '';

    return (
        <>
            <div className='userMenuContainer'>
                <span className="verticalDash">|</span>
                <div className="blockMenu">
                    <img src={`/profilPicture/${menuUser.user.id}=72x72xcarre`} alt="profil" className="imgProfil" />
                    <span className="lato userName">{menuUser.user.firstname}</span><Icon icon="arrow-light" className="arrowMenu" size="smaller" orientation="bottom" gap="2" />
                    <DesktopSubMenu className={`subUserMenu ${tripleColumnClass}`} menuUser={menuUser} setUserMenu={setUserMenu} />
                </div>
            </div>
        </>
    );
};

const DesktopMenu = ({ menu, menuUser = null, setUserMenu = null }) => {

    return (
        <>
            <div className='headerLinks'>
                {menu.slice(0, -1).map((item, i) => (
                    <div className="headerLink" key={'link-' + i}>
                        {item.type === 'link' && !!item.isInternal && (
                            <Link to={formateInternalUri(item.url)} onClick={() => ClickEventGA4('clic-menu', item.gaEventName)}>
                                {item.title}
                            </Link>
                        )}

                        {item.type === 'link' && !item.isInternal && (
                            <a href={item.url} onClick={() => ClickEventGA4('clic-menu', item.gaEventName)}>
                                {item.title}
                            </a>
                        )}

                        {item.type === 'submenu' && (
                            <div className='ressourcesContainer'>
                                {item.title} <Icon icon="arrow-slider-right" size="smaller" orientation="bottom" gap="2" />
                                <DesktopSubMenu className='subMenuRessources' menuList={item.subMenu} />
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {/* Si on a des informations utilisateur enregistrées (utilisateur connecté) */}
            {menuUser ? <DesktopMenuConnected menuUser={menuUser} setUserMenu={setUserMenu} /> :
                menu.slice(-1).map((item, i) => (
                    <div key={'link-' + i} className='connexionButton'>
                        <ArrowButton className='headerArrowButton'>{item.title}</ArrowButton>
                        <DesktopSubMenu className='subMenuConnexion' menuList={item.subMenu} />
                    </div>
                ))
            }
        </>
    );
};

export default DesktopMenu;