import { useTranslation } from "react-i18next";
import styled from "styled-components"
import Icon from "../../components/ui/Icon"

const LoaderContainer = styled.div`
    position: fixed;
    background-color: #FFFFFFB8;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0;
    flex-direction: column;
`

const BlogLoader = () => {
    const { t } = useTranslation('translation');

    return (
        <LoaderContainer>
            <Icon icon="loading" />
            {t('loading')}...
        </LoaderContainer>
    );
}

export default BlogLoader