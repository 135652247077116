import { Block1, Block2, Block3, Block4, Block5, Block6, Block7, BlockNewsletter } from '../components/block';
import { useContext, useEffect } from "react";
import Meta from "../components/structure/Meta"
import Header from '../components/structure/Header';
import Footer from '../components/structure/Footer';
import RenderHeaderSticky from '../components/hook/RenderHeaderSticky';
import { initGA4 } from '../components/hook/TrackingGA4';
import { UserContext } from '../utils/context';

const Home = () => {
    // On appelle le hook pour rendre le header sticky + ajouter un margin au block 1
    const isScrollingUp = RenderHeaderSticky();

    const {user} = useContext(UserContext);

    // Une fois que la page est prête
    useEffect(() => {

        if (navigator.userAgent !== "ReactSnap") {
            let forfaitType = 'forfait-grand-public';

            if (user && user.currentForfaitId !== 37) {
                forfaitType = 'forfait-grand-compte';
            }

            const gaOptions = {
                ...(user && { 'user-id': user.id }),
                'titre-page': 'Bureaux et coworking pour vos salariés',
                'content_type': 'accueil',
                'type-connecte': (user) ? 'Oui' : 'Non',
                'type-forfait': forfaitType
            }

            initGA4('G-YKDRHSWN0X', gaOptions);
        }
    }, [user]);

    return (
        <>
            <Meta
                title="Bureaux et coworking pour vos salariés"
                description="Neo-nomade : Trouvez vos bureaux et  accédez aux meilleurs espaces de coworking/salles de réunions"
            ></Meta>
            <Header fixed={true} isScrollingUp={isScrollingUp} />

            <div>
                <Block1 />
                <Block2 />
                <Block3 />
                <Block4 />
                <Block5 />
                <Block6 />
                <Block7 />
                <BlockNewsletter />
            </div>

            <Footer />
        </>
    );
}

export default Home;