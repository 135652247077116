import { neonomadeWordpressUrl } from "../datas/commonLinks";

/**
 * Retourne une image de la taille voulue parmi les propriété wordpress
 * @param {object} post article wordpress
 * @param {string} size taille (enregistrée sur wordpress)
 * @returns {string} url de l'image 
 */
export const getSizePic = (post, size='medium_large') => {
    // Si on a des médias
    if (post._embedded.hasOwnProperty('wp:featuredmedia')) {
        // On récupère l'image principale
        const media = post._embedded['wp:featuredmedia'][0];
        
        // si on a la taille voulue, on retourne l'url
        if (media.media_details && media.media_details.sizes.hasOwnProperty(size)) {
            return media.media_details.sizes[size].source_url;
        }

        // Sinon on retourne l'url globale (non croppée) si elle existe
        if (media.source_url) {
            return media.source_url;
        }
    }
    
    // Si on a pas trouvé d'image on retourne une image générique
    return `${neonomadeWordpressUrl}/wp-content/uploads/2020/03/Splash-2-768x432.png`;
}

/**
 * Décode une string html
 * @param {string} encodedData 
 * @returns {string} decodedData 
 */
export const htmlDecode = (encodedData) => {
    const doc = new DOMParser().parseFromString(encodedData, "text/html");
    return doc.documentElement.textContent;
}

/**
 * Retourne une date lisible depuis une date ISO
 * @param {date} isoDate 
 * @returns {string} date dd mmmm YYYY 
 */
export const formatDate = (isoDate) => {
    const publishedDate = new Date(isoDate);
    return `${publishedDate.getDate()} ${publishedDate.toLocaleString('default', { month: 'long' })} ${publishedDate.getFullYear()}`
}

/**
 * Depuis un objet post de l'API wordpress, créé un objet pour affichage dans l'element BlogArticleThumbnail
 * @param {object} post object wordpress
 * @returns {object} objet utilisable dans l'element BlogArticleThumbnail
 */
export const formatPostToThumbnail = (post) => {

    return {
        title:  post.title.rendered,
        text:   post.excerpt.rendered,
        description : post.content ? post.content.rendered : null,
        author: post._embedded.author[0].name,
        image:  getSizePic(post),
        href:   `/blog/${post.slug}`,
        date:   formatDate(post.date),
        html:   true
    }
};

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export const fetchPosts  = async (param) => {
    // Data à retourner
    const returnedData = {
        status : false
    }

    // Url de l'API blog
    const apiUrl = `${neonomadeWordpressUrl}/wp-json/wp/v2/posts/`;

    // Paramètre par défaut à passer à l'API
    const standardParam = {
        '_embed':''
    }

    const cleanParam = Object.entries(param).reduce((a,[k,v]) => (v === null ? a : (a[k]=v, a)), {});
    
    const uriParam = new URLSearchParams({...standardParam, ...cleanParam}).toString();
    const res = await fetch(`${apiUrl}?${uriParam}`);
    
    // Si tout est OK
    if (res.status === 200) {
        // On récupère le nb de pages
        returnedData.pageCount = parseInt(res.headers.get('x-wp-totalpages'));

        // On récupère le nb de posts
        returnedData.postCount = parseInt(res.headers.get('x-wp-total'));
        
        // On parse le json
        const data = await res.json();

        // On récupère la liste des posts
        returnedData.postList = data;

        // On assigne true au statut
        returnedData.status = true;
    }

    // On retourne le block de données
    return returnedData;
}

/**
 * Récupère les articles similaires 
 * @param {int} postId
 * @returns 
 */
 export const fetchRelatedPosts  = async (postId) => {
    // Url de l'API blog
    const apiUrl = `${neonomadeWordpressUrl}/wp-json/yarpp/v1/related/${postId}`;

    // Paramètre par défaut à passer à l'API
    const standardParam = {
        '_embed':''
    }
    const uriParam = new URLSearchParams({...standardParam}).toString();

    const res = await fetch(`${apiUrl}?${uriParam}`);
    
    // Si tout est OK
    if (res.status === 200) {       
        // On parse le json
        const data = await res.json();

        // On récupère la liste des posts
        return data;
    }

    // On retourne le block de données
    return false;
}


/**
 * Récupère les categories
 * @param {int} postId
 * @returns 
 */
 export const fetchCategories  = async (param = {}) => {
    const cleanParam = Object.entries(param).reduce((a,[k,v]) => (v === null ? a : (a[k]=v, a)), {});
    const uriParam = new URLSearchParams(cleanParam).toString();

    // Url de l'API blog
    const res = await fetch(`${neonomadeWordpressUrl}/wp-json/wp/v2/categories?${uriParam}`);
    
    // Si tout est OK
    if (res.status === 200) {       
        // On parse le json
        const data = await res.json();

        // On récupère la liste des posts
        return data;
    }

    // On retourne le block de données
    return false;
}

/**
 * Récupère les Tags 
 * @param {int} postId
 * @returns 
 */
 export const fetchTags  = async (param = {}) => {
    const cleanParam = Object.entries(param).reduce((a,[k,v]) => (v === null ? a : (a[k]=v, a)), {});
    const uriParam = new URLSearchParams(cleanParam).toString();

    // Url de l'API blog
    const res = await fetch(`${neonomadeWordpressUrl}/wp-json/wp/v2/tags?${uriParam}`);
    
    // Si tout est OK
    if (res.status === 200) {       
        // On parse le json
        const data = await res.json();

        // On récupère la liste des posts
        return data;
    }

    // On retourne le block de données
    return false;
}