import { useState, useEffect } from 'react';

// Hook Component pour savoir si on doit rendre sticky le header ou non
const RenderHeaderSticky = () => {

    // On initialise le state à false
    const [renderSticky, setRenderSticky] = useState(false);

    // Fonction qui va set une nouvelle valeur au state
    const logit = () => {
        const scrollY = window.pageYOffset;

        // Boolean qui indique si on a scroll ou non
        const isScrolled = scrollY > 0;

        // On set le boolean
        setRenderSticky(isScrolled);
    }

    // On utilise un effet au scroll afin de savoir si on doit rendre le header sticky
    useEffect(() => {
        function watchScroll() {
            window.addEventListener("scroll", logit);
        }
        watchScroll();
        return () => {
            window.removeEventListener("scroll", logit);
        };
    });

    return renderSticky;
}

export default RenderHeaderSticky;