import styled, { css } from 'styled-components';
import { useTranslation , Trans } from 'react-i18next';
import { colors, layout, fontSizes, breakpoints, weight } from '../constants';
import Icon from '../ui/Icon';
import espacesForAllNeeds from '../../assets/images/espacesForAllNeeds.webp';
import espacesForAllNeeds2x from '../../assets/images/espacesForAllNeeds@2x.webp';
import espacesForAllNeeds3x from '../../assets/images/espacesForAllNeeds@3x.webp';
import { block5Datas } from '../../datas/blocks';

const Block5Container = styled.div`
    padding: 80px 30px;
    display: flex;
    flex-direction: column;

    & > h2 {
        text-align: center;
        font-weight: ${weight.medium};

        &:first-of-type {
            color: ${colors.main};
            font-size: ${fontSizes.medium}px;
            text-transform: uppercase;
            margin: 0;

            @media (min-width: ${breakpoints.extraLarge}) {
                font-size: ${fontSizes.veryLarge}px;
            }
        }

        &:last-of-type {
            color: ${colors.darkGrey};
            font-size: 30px;
            margin: ${layout.large}px 0 ${layout.huge}px 0;

            br {
                display: none;

                @media (min-width: ${breakpoints.extraLarge}) {
                    display: block;
                }
            }

            @media (min-width: ${breakpoints.extraLarge}) {
                font-size: 40px;
                margin-bottom: 60px;
            }
        }
    }

    & > div {
        display: flex;
        flex-direction: column;
        align-items: center;

        & > img {
            max-width: 408px;
            width: 100%;
            order: 1;
            margin-top: ${layout.veryLarge}px;
            margin-bottom: 65px;

            @media (min-width: ${breakpoints.extraLarge}) {
                order: 2;
                margin: 0;
            }
        }

        & > div {
            flex: 1;
            width: 100%;
            display: flex;
            flex-direction: column;

            &:first-of-type {
                order: 2;

                @media (min-width: ${breakpoints.extraLarge}) {
                    order: 1;
                    margin-right: 50px;
                    align-items: flex-end;
                }
            }

            &:last-of-type {
                order: 3;

                @media (min-width: ${breakpoints.extraLarge}) {
                    margin-left: 50px;
                    align-items: flex-start;
                }
            }
        }

        @media (min-width: ${breakpoints.extraLarge}) {
            flex-direction: row;
        }
    }

    @media (min-width: ${breakpoints.extraLarge}) {
        padding: 150px 140px;
    }
`;

const AnswerContainer = styled.div`
    border-radius: 8px;
    border: solid 1px ${colors.main};
    padding: ${layout.veryLarge}px;
    margin-bottom: ${layout.large}px;

    @media (min-width: ${breakpoints.extraLarge}) {
        max-width: 380px;
    }

    h3 {
        color: ${colors.main};
        margin-top: 0;
        margin-bottom: ${layout.small}px;
        font-weight: ${weight.regular};
    }

    p {
        margin: 0;
        color: ${colors.darkGrey};
        font-weight: ${weight.light};

        & + p, b {
            font-weight: bold;
            font-weight: ${weight.regular};

            & > i {
                display: inline-block;
                font-size: 8px;
                margin-right: 5px;
            }
        }
    }

    @media (min-width: ${breakpoints.extraLarge}) {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            background-color: ${colors.main};
            height: 1px;
            width: 52px;
            top: 50%;

            ${props => {
        switch (props.position) {
            case 'left': {
                return css`
                            right: 100%;
                        `;
            }

            case 'right': {
                return css`
                            left: 100%;
                        `;
            }
            default: {
                return css``;
            }
        }
    }}
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const Answer = ({ position, title, text, subtext = null }) => {
    return (
        <AnswerContainer position={position}>
            <h3>{title}</h3>
            <p dangerouslySetInnerHTML={{ __html: text }} />

            {subtext && (
                <p>
                    <Icon icon="arrow-slider-right" />
                    {subtext}
                </p>
            )}
        </AnswerContainer>
    );
}

const Block5 = () => {

     // On recupère la fonction de traduction
     const { t } = useTranslation('translation', { keyPrefix: 'home.block5' });

    return (
        <Block5Container>
            <h2>{t('title')}</h2>
            <h2>
                <Trans i18nKey="home.block5.subtitle">
                    Des espaces de travail à la carte qui <br /> répondent à tous vos besoins.
                </Trans>
            </h2>

            <div>
                <img src={espacesForAllNeeds} srcSet={`${espacesForAllNeeds2x} 2x, ${espacesForAllNeeds3x} 3x`} alt={t('altListEspace')} width="408" height="408" />

                <div>
                    {block5Datas.leftContent.map((data, index) => (
                        <Answer key={index} title={data.title} text={data.text} subtext={data.subText} position="right" />
                    ))}
                </div>

                <div>
                    {block5Datas.rightContent.map((data, index) => (
                        <Answer key={index} title={data.title} text={data.text} subtext={data.subText} position="left" />
                    ))}
                </div>
            </div>
        </Block5Container>
    );
}

export default Block5;