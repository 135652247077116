import styled from 'styled-components';
import blueLogo from '../../assets/logos/blue.svg';
import { neonomadeFinderUrl } from '../../datas/commonLinks';
import { cityList, FooterContent } from '../../datas/Footer';
import { colors, fontSizes, breakpoints, weight } from '../constants';
import Icon from '../ui/Icon';
import NNLink from '../ui/Link';
import { Link } from 'react-router-dom';
import { ClickEventGA4 } from '../hook/TrackingGA4';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';


// Style de conteneur main du Footer
const FooterContainer = styled.footer`
    background-color: rgba(47, 179, 190, 0.05);
    width: 100%;
    bottom: 0;
    padding: 5rem 2rem 4rem 2rem;
    font-style: normal;
    font-size: ${fontSizes.medium}px;
    line-height: 22px;
    color: rgba(54, 54, 54, 0.5);

    @media screen and (min-width: ${breakpoints.medium}) and (max-width: ${breakpoints.large}) {
        padding: 2rem 4rem 3rem 4rem;
    }

    @media screen and (min-width: ${breakpoints.large}) {
        padding: 3rem 6rem 10rem 6rem;
    }
`;

// Style de la liste du bloc conteneur
const BlockContainers = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${breakpoints.large}) {

    }
`;

// Style du bloc conteneur
const BlockContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;


    @media screen and (min-width: ${breakpoints.large}) {
        justify-content: space-between;
        flex-wrap: nowrap;
    }

    &.lastBlockContainer {
        flex-direction: column;
        margin-top: 30px;
        font-weight: ${weight.light};

        @media screen and (min-width: ${breakpoints.large}) {
            flex-direction: row;
        }
    }
`;

const PartContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    margin: 30px 0;
    padding: 0 10px;

    @media screen and (min-width: ${breakpoints.large}) {
        width: inherit;
    }

    .showMoreLink span {
        display: flex;
        align-items: center;

        i {
            margin-top: 5px;
            margin-left: 5px;
        }
    }

    &.NNPart {
        width: 100%;

        @media screen and (min-width: ${breakpoints.large}) {
            width: inherit;
        }

        & > div {
            display: flex;
            flex-direction: column;

            .content-0 {
                order:2;
            }

            .content-1 {
                order: 1;
            }
        }
    }

    img {
        height: 30px;
    }
`;

// Style du contenu de bloc conteneur
const BlockContentContainer = styled.ul`
    line-height: 1.6rem;
    list-style: none;
    margin-top: -1.1rem;
    padding: 0;
    font-weight: ${weight.light};

    & > a, & > div {
        display: flex;
        flex-direction: row;
        text-decoration: none;
        color: rgba(54, 54, 54, 0.5);

        & > i:before {
            background: linear-gradient(90.48deg, ${colors.main} 0.32%, #06E5D5 99.58%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin: 0.4rem 0.8rem 0 0;
        }
    }

    & > a:not(.fakeLink) {
        cursor: pointer;
        &:hover {
            transition-property: color;
            transition-duration: 200ms;
            color: ${colors.main};
        }
    }
};
`;

// Style du contenu de 1er bloc conteneur en bas
const BlockContentBottom1Container = styled.ul`
    display: flex;
    flex-direction: column;
    text-align: center;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: center;

    @media (min-width: ${breakpoints.large}) {
        flex-direction: row;
        order 2;
    }

    a {
        text-decoration: none;
        color: rgba(54, 54, 54, 0.5);
        cursor: pointer;

        &:hover {
            transition-property: color;
            transition-duration: 200ms;
            color: ${colors.main};
        }

        @media (min-width: ${breakpoints.large}) {
            margin-left: 50px;
        }
    }
`;

// Style du contenu de 2ème bloc onteneur en bas
const BlockContentBottom2Container = styled(BlockContentBottom1Container)`
    margin-top: 1.5rem;

    @media (min-width: ${breakpoints.large}) {
        margin-top: 0;
        order 1;
    }
`;

// Style de conteneur pour la liste des réseaux sociaux
const ListMediasContainer = styled.ul`
    margin: 2rem 0 2rem 0;
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 40px 0;

    @media screen and(min - width: ${breakpoints.medium}) and(max - width: ${breakpoints.large}) {
        margin: 1rem 0 1rem 0;
    }
`;

// Style de conteneur de réseaux sociaux
const MediaContainer = styled.a`
    padding: 1.5rem;
    margin-right: 1rem;
    background: linear-gradient(90.48deg, rgba(47, 179, 190, 0.1) 0.32%, rgba(6, 229, 213, 0.1) 99.58%);
    border-radius: 10px;
    width: 20px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

        & > i:before {
        background: linear-gradient(90.48deg, ${colors.main} 0.32%, #06E5D5 99.58%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    };

    @media screen and(min-width: ${breakpoints.medium}) and(max - width: ${breakpoints.large}) {
        padding: 1rem;
        border-radius: 5px;
        margin-right: 0.4rem;
    }

    @media screen and(min-width: ${breakpoints.large}) {
        padding: 1.2rem;
        border-radius: 5px;
        margin-right: 0.8rem;
    }
`;

// Style de titre
const Title = styled.h5`
    font-size: ${fontSizes.medium}px;
    color: #393939;
    line-height: 1.5rem;
    font-weight: ${weight.medium};
`;

// Style de ligne pour séparer les blocs
const LineBreak = styled.hr`
    border: 1px solid rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 1px;
    margin: 30px 0px;
`;

const CityBlock = ({ title, urlEnding, nameStart, hideSeeMore, dataOnClick }) => {

    const { t } = useTranslation('translation', { keyPrefix: 'footer.cityBlock' });
    // console.log(dataOnClick);

    return (
        <PartContainer>
            <Title>{title}</Title>
            <BlockContentContainer>
                {cityList.map((city, index) => (
                    dataOnClick ?
                        <a key={index} href={`${neonomadeFinderUrl}/${city.toLowerCase()}/${urlEnding}`} onClick={() => ClickEventGA4('clic-footer-SEO', `${dataOnClick}-${city.toLocaleLowerCase()}`)}>{nameStart} {city}</a>
                        :
                        <a key={index} href={`${neonomadeFinderUrl}/${city.toLowerCase()}/${urlEnding}`} onClick={() => ClickEventGA4('clic-footer', `clic-footer-${city.toLocaleLowerCase()}`)}>{nameStart} {city}</a>
                ))}
            </BlockContentContainer>
            {!hideSeeMore && <NNLink className='showMoreLink' href={`${neonomadeFinderUrl}/france/${urlEnding}`}>{t('seeMore')}<Icon icon="arrow-slider-right" size='smaller' /></NNLink>}
        </PartContainer>
    );
}

const CommonFooterBlock = ({ title, content }) => (
    <PartContainer>
        <Title>{title}</Title>
        <BlockContentContainer>
            {content.map((c) => c.isInternal ? (
                <Link key={c.name} to={c.href} onClick={() => ClickEventGA4('clic-footer', `${c.trackerClick}`)}>{c.name}</Link>
            ) : (
                <a key={c.name} href={c.href} onClick={() => ClickEventGA4('clic-footer', `${c.trackerClick}`)}>{c.name}</a>
            ))}
        </BlockContentContainer>
    </PartContainer>
)

const ContactBlock = ({ title, content }) => (
    <div>
        <Title>{title}</Title>
        <BlockContentContainer>
            {content.map((content) => (
                <a key={content.name} href={content.href} className={content.className}>
                    <Icon icon={content.icon} />
                    {content.name}
                </a>
            ))}
        </BlockContentContainer>
    </div>
)

const LeftFooter = ({ leftFooterContent }) => {

    const { t } = useTranslation('translation', { keyPrefix: 'footer.cityBlock' });

    return (
        <PartContainer className="NNPart">
            <img src={blueLogo} alt={t('logoOf', { company: 'Neo-nomade' })} />

            {leftFooterContent.map((data, i) => (
                <div className={"content-" + i} key={"content-" + i}>
                    {data.title === "Contact" ? <ContactBlock title={data.title} content={data.content} /> : <FollowIconBlock socialNetworkList={data.content} />}
                </div>
            ))}
        </PartContainer>
    );
}

const FollowIconBlock = ({ socialNetworkList }) => (
    <ListMediasContainer>
        {socialNetworkList.map((socialNetwork) => (
            <MediaContainer key={socialNetwork.name} alt={socialNetwork.name} href={socialNetwork.href}>
                <Icon icon={socialNetwork.icon} size="bigger" />
            </MediaContainer>
        ))}
    </ListMediasContainer>
)

const BlockContainerContent = ({ data }) => {
    if (data.type === 'leftFooter')
        return (<LeftFooter leftFooterContent={data.content} />);

    if (data.type === 'city')
        return (<CityBlock title={data.title} nameStart={data.nameStart} urlEnding={data.urlEnding} hideSeeMore={data.hideSeeMore} dataOnClick={data.trackerClick} />);

    return (<CommonFooterBlock title={data.title} content={data.content} />);
}

/**
 * Composant Footer
 * @returns Contenu de Footer
 */
const Footer = () => {

    const { t } = useTranslation('translation', { keyPrefix: 'footer.lastBlock' });

    return (
        <FooterContainer>
            <BlockContainers>
                {FooterContent.map((blockContainer, index) => (
                    <Fragment key={`container${index}`}>
                        <BlockContainer>
                            {blockContainer.map((data, index) => (<BlockContainerContent key={index} data={data} />))}
                        </BlockContainer>
                        <LineBreak />
                    </Fragment>
                ))}

                <BlockContainer className='lastBlockContainer'>
                    <BlockContentBottom1Container>
                        <Link to="/conditions-utilisation">{t('TOS')}</Link>
                        <Link to="/politique-cookies">{t('cookiesPolicy')}</Link>
                        <Link to="/donnees-personnelles">{t('useOfPersonnalData')}</Link>
                    </BlockContentBottom1Container>
                    <BlockContentBottom2Container>
                        <li key="neonomade">@Neo-nomade 2022</li>
                    </BlockContentBottom2Container>
                </BlockContainer>
            </BlockContainers>
        </FooterContainer>
    );
};

export default Footer;