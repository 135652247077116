import styled from 'styled-components';

const FooterBannerContainer = styled.div`
    position: fixed;
    bottom: 0;
    display: flex;
    border: solid 1px rgba(142,157,175,0.2);
    background-color: white;
    flex-direction: row;
    justify-content: flex-end;
    padding: 20px;
    padding-right: 30px;
    align-items: center;
    width: 100%;
    box-shadow: 0 3px 15px 7px rgba(142, 157, 175, 0.2);
`

const FooterBanner = ({children}) => (	
    <FooterBannerContainer>
        {children}
    </FooterBannerContainer>
)

export default FooterBanner;