import styled from 'styled-components';
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { Home, Contact, Partner, ErrorPage, NotionPage, ValidateCgu, TypeformPage, CalendlyPage } from './pages';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { initGA } from './components/hook/Tracking';
import ScrollToTop from './components/hook/ScrollToTop';
import GclidHandler from './components/hook/GclidHandler';
import TestModeBanner from './components/structure/TestModeBanner';
import { BlogArticle, BlogCategory, BlogSummary, BlogCategoryFromSlug, BlogTagFromSlug } from './blog';
import { useTranslation } from 'react-i18next';
import { studiesCategoryId, testimonyCategoryId } from './datas/commonLinks';
import { UserProvider } from './utils/context';

const AppContainer = styled.div`
	@media (min-width: 1921px) {
		max-width: 1920px;
		margin: auto;
		min-height: 100vh;
	}
	* {
		box-sizing: border-box;
	}
`;

function App() {
	const { t } = useTranslation('translation');

	// Une fois que la page est prête
	useEffect(() => {
		// On supprime les marges du body
		document.body.style.margin = 0;

		if (navigator.userAgent !== "ReactSnap") {
			// On initialise Google analytics
			initGA('UA-56491800-1');
		}
	}, []);

	return (
		<AppContainer>
			<Helmet>
				<title>Neo-nomade - Pass Coworking</title>
			</Helmet>
			{process.env.REACT_APP_ENV !== 'production' && <TestModeBanner />}
			<Router>
				<GclidHandler />
				<ScrollToTop />
				<UserProvider>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route exact path={`/${process.env.PUBLIC_URL}`} element={<Home />} />
						<Route path={`/${process.env.PUBLIC_URL}`}>
							<Route exact path="validation-conditions-utilisation" element={<ValidateCgu />} />

							<Route path="contact-us" element={<Contact />} />
							<Route path="partner" element={<Partner />} />
							<Route exact path="qualification" element={<TypeformPage formId="vU0Nlf86" />} />
							<Route path="demo-neo-nomade" element={<CalendlyPage url="https://calendly.com/baptiste-broughton/demo-neo-nomade-20-minutes?hide_gdpr_banner=1" />} />
						</Route>
						<Route exact path="/conditions-utilisation" element={<NotionPage title={`${t('footer.lastBlock.TOS')} Neo-nomade`} pageId="8381e8d050b645d48d1be00c15d23466" />} />
						<Route exact path="/conditions-utilisation/:notionPageId" element={<NotionPage title={`${t('footer.lastBlock.TOS')}`} />} />
						<Route exact path="/politique-cookies" element={<NotionPage title={`${t('footer.lastBlock.cookiesPolicy')} Neo-nomade`} pageId="a75a5d088a244cf096f443b7b680e21d" />} />
						<Route exact path="/donnees-personnelles" element={<NotionPage title={`${t('footer.lastBlock.useOfPersonnalData')} Neo-nomade`} pageId="4e23c1939d0d4451b7cb466989916ff2" />} />
						<Route exact path="/validation-conditions-utilisation" element={<ValidateCgu />} />
						<Route exact path="/Manifeste" element={<NotionPage title={t('footer.nnPart.visionColumn.manifest')} pageId="e6bba43f381e4863a112d18a4033be0f" smallwidth={true} />} />
						<Route path="/nos-etudes" element={<BlogCategory title='Toutes nos <br/><span>études</span> sur le coworking' description="On partage avec vous plus de 5 ans d'expériences sur le coworking" categoryId={studiesCategoryId} linkText={t("blog.readStudy")} />} />
						<Route path="/nos-clients-temoignent" element={<BlogCategory title='Nos clients <br/><span>témoignent</span>' description='Temoignage de nos clients' categoryId={testimonyCategoryId} linkText={t("blog.readTestimony")} />} />
						<Route path={`/blog`}>
							<Route path="" element={<BlogSummary />} />
							<Route path="page/:page" element={<BlogSummary />} />
							<Route path=":slug" element={<BlogArticle />} />
							<Route path="category/:slug" element={<BlogCategoryFromSlug />} />
							<Route path="category/:slug/page/:page" element={<BlogCategoryFromSlug />} />
							<Route path="tag/:tagslug" element={<BlogTagFromSlug />} />
							<Route path="tag/:tagslug/page/:page" element={<BlogTagFromSlug />} />
						</Route>
						<Route path="*" element={<ErrorPage errorCode={4} />} />
					</Routes>
				</UserProvider>
			</Router>
		</AppContainer>
	);
}

export default App;
