import styled, { css } from 'styled-components';
import { breakpoints, weight, gradients } from '../../constants';

const TitleBlockContainer = styled.h1`
    ${(props) => props.center ? (css`
        text-transform: uppercase;
        text-align: center;
    `) : null}
    span {
        background: linear-gradient(
            135deg,
            ${gradients.mainGradient}
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    };

    font-size: 40px !important;
    font-weight: ${weight.medium};
    line-height: 48px;
    margin-top: 0;

    @media (min-width: ${breakpoints.large}) {
        font-size: 50px !important;
        line-height: 60px;
    }
`;

const TitleBlock = ({children, html, center}) => html ? (
    <TitleBlockContainer center={center} dangerouslySetInnerHTML={{__html:children}}/>
) : (
    <TitleBlockContainer center={center}>{children}</TitleBlockContainer>
);

export default TitleBlock;