import { neonomadeUrl } from "../datas/commonLinks";

export const fetchNotionData = async (pageId) => {
    // On lance l'appel ajax qu'on parse en json
    const ajaxReturn = await fetch(`${neonomadeUrl}/inc/ajax/landings/getNotionData.php?pageId=${pageId}`);
    const data = await ajaxReturn.json();

    // On extrait le titre de la page notion qu'on ajoute à l'objet
    data.title = data?.properties?.properties?.title?.title?.shift()?.plain_text;

    return data;
}