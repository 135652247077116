import Header from '../components/structure/Header';
import Meta from "../components/structure/Meta"
import RenderHeaderSticky from '../components/hook/RenderHeaderSticky';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../components/constants';
import { useTranslation } from 'react-i18next';
import { neonomadeUrl } from '../datas/commonLinks';
import { useLocation } from 'react-router-dom';
import NotionBlock from '../components/block/NotionBlock';
import NnTabs from '../components/ui/tabs/NnTabs';
import FooterBanner from '../components/structure/FooterBanner';
import CtaButton from '../components/ui/buttons/CtaButton';
import CheckBox from '../components/ui/inputs/CheckBox';
import Modal from '../components/structure/Modal';
import Button from '../components/ui/buttons/Button';
import { fetchNotionData } from '../utils/notionUtils';


const PageContent = styled.div`
    padding: 80px 30px 120px;

    @media (min-width: ${breakpoints.small}) {
        padding: 100px 120px 120px;
    }
`
const ValidateCgu = () => {
    const { t } = useTranslation('translation');

    const defaultTabs = [
        {
            title:t("cguValidation.cguTabTitle"),
            pageId:"8381e8d050b645d48d1be00c15d23466",
        },
        {
            title:t("cguValidation.personnalDataTabTitle"),
            pageId:"4e23c1939d0d4451b7cb466989916ff2",
        }
    ];

	const [notionData, setNotionData] = useState([]);
	const [modalData, setModalData] = useState({title:'', message:''});
	const [showModal, setShowModal] = useState(false);
	const [specificTabs, setSpecificTabs] = useState([]);
	const [activeTab, setActiveTab] = useState(0);
	const [cguChecked, setCguChecked] = useState(false);

    // On appelle le hook pour rendre le header sticky + ajouter un margin au block 1
    const isScrollingUp = RenderHeaderSticky();
    const location = useLocation();

    useEffect(() => {
        const fetchData  = async () => {
            // On récupère les cgu specific via ajax
            const data = await fetch(`${neonomadeUrl}/inc/ajax/landings/getSpecificTerms.php`).then(res => res.json());

            // Si on a récupéré des données
            if (data.status) {
                // On extrait les tabs à partir des datas récupérées
                const specificTabs = data.data.map(d => ({
                    title:`${t('cguValidation.companyTerms')} ${d.name}`,
                    pageId:d.cgu,
                }));

                // on ajoute dans le state specificTabs
                setSpecificTabs(specificTabs);
            }
        }
        fetchData();
    }, [t]);

    const saveCguValidation = async () => {
        // Si la case de validation des cgu est bien cochée
        if (cguChecked) {
            // On créé un form data avec l'info acceptCGU
            var formData = new FormData();
            formData.append('acceptCGU', cguChecked);

            try {
                // On 
                const response = await fetch(`${neonomadeUrl}/inc/ajax/utilisateur/acceptCGU.php`, {
                    method: 'POST',
                    body: formData,
                }).then(res => res.json());

                // Si le statut du retour ajax est ok
                if (response.status) {
                    // On affiche une modale avec message de retour
                    setShowModal(true);
                    setModalData({title:t("cguValidation.cguValidationPageTitle"),message : t("cguValidation.cguValidated")});

                    // On récupère les paramètres de l'url
                    const urlParams = new URLSearchParams(window.location.search);

                    // On récupère le paramètre redirect
                    const redirect = urlParams.get('redirect');

                    // Redirection vers une page voulue ou vers le finder
                    window.location.href = (!! redirect ? redirect : '/s');
                } else {
                    // Si le statut est à false on affiche la modale avec le message d'erreur
                    setShowModal(true);
                    setModalData({title:t("cguValidation.cguValidationPageTitle"),message:response.message});
                }
              } catch (err) {
                // Si une erreur s'est produite on affichge une modale avec un message standard
                setShowModal(true);
                setModalData({title:t("cguValidation.cguValidationPageTitle"),message:t("cguValidation.errorOccured")});
                console.error(err);
              }
        }
    }

    const tabs = [...defaultTabs, ...specificTabs];
    const pageId = tabs[activeTab].pageId;
    
    useEffect(() => {
        // Si on a pas déja chargé les données notion
        if (! notionData[activeTab]) {
            // Fonction pour la récupération des données notion et placement dans le state notionData
            (async () => {
                // On récupère les données notion
                const data = await fetchNotionData(pageId);

                // On copie le tableau de data
                const notionDataArray = [...notionData];

                // On ajoute les data récupéré via ajax
                notionDataArray[activeTab] = data.content.object === 'error' ? null : data;

                // On set le tableau actualisé
                setNotionData(notionDataArray);
            })();
        }
    }, [activeTab, pageId, notionData]);

    return (
        <>
            <Meta 
                title={`${t("cguValidation.cguValidationPageTitle")} - Neo-nomade`}
                url={location.pathname}
            />
            <Header isScrollingUp={isScrollingUp} fixed={true} />

            <PageContent>
                {showModal && <Modal setShowModal={setShowModal} title={modalData.title}>{modalData.message}</Modal>}
                <NnTabs tabs={tabs} defaultTabClick={setActiveTab} activeTab={activeTab} />

                {! notionData[activeTab] && (<p>{t('loading')}...</p>)}
                {notionData[activeTab] && <>
                    <h1>{notionData[activeTab]?.title ?? tabs[activeTab].title}</h1>
                    <NotionBlock notionData={notionData[activeTab].content ?? notionData[activeTab] ?? notionData} />
                </>}
            </PageContent>

            <FooterBanner>
                {/* Si on n'est pas sur le dernier onglet alors on affiche un bouton suivant */}
                {activeTab < tabs.length - 1 && (
                    <Button onClick={() => {setActiveTab(activeTab + 1)}}>Suivant</Button>
                )}
                
                {/* Si on est sur le dernier onglet alors on affiche la checkbox de validation et un bouton enregistrer */}
                {activeTab === tabs.length - 1 && (<>
                    <CheckBox onChange={(checked => setCguChecked(checked))} title={t("cguValidation.acceptanceOfTerms")}/>
                    <CtaButton disabled={! cguChecked} onClick={saveCguValidation}>{t("cguValidation.save")}</CtaButton>
                </>)}
            </FooterBanner>
        </>
    );
}

export default ValidateCgu;