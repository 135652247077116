import styled from "styled-components";
import { useTranslation } from 'react-i18next';
import { colors, fontSizes, layout, breakpoints, weight } from "../constants";
import { block2Datas } from '../../datas/blocks';
import Icon from "../ui/Icon";
import InternalLinkAsButton from '../ui/buttons/InternalLinkAsButton';
import passCoworkingApp from '../../assets/images/passCoworkingApp.webp';
import passCoworkingApp2x from '../../assets/images/passCoworkingApp@2x.webp';
import passCoworkingApp3x from '../../assets/images/passCoworkingApp@3x.webp';
import { ClickEventGA4 } from '../hook/TrackingGA4';

const Block2Container = styled.div`
    padding: 80px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
        @media (min-width: ${breakpoints.large}) {
            width: 60%;
        }

        & > h2 {
            &:first-of-type {
                color: ${colors.main};
                font-size: ${fontSizes.veryLarge}px;
                text-transform: uppercase;
                margin: 0;
                font-weight: ${weight.medium};

                @media (min-width: ${breakpoints.large}) {
                    font-size: ${fontSizes.veryLarge}px;
                }
            }

            &:last-of-type {
                color: ${colors.darkGrey};
                font-size: 30px;
                margin: ${layout.large}px 0 ${layout.huge}px 0;
                font-weight: ${weight.medium};
                line-height: 120%;

                @media (min-width: ${breakpoints.large}) {
                    font-size: 40px;
                }
            }
        }

        @media (min-width: ${breakpoints.large}) {
            padding-right: 80px;
        }
    }

    & > .imgContainer {

        @media (min-width: ${breakpoints.large}) {
            width: 40%;
            display:flex;
            justify-content: center;
        }
        img {
            width: 100%;
            margin-top: ${layout.huge}px;

            @media (min-width: ${breakpoints.large}) {
                margin-top: 0;
                max-height: 530px;
                width: auto;
            }
        }

    }

    @media (min-width: ${breakpoints.large}) {
        flex-direction: row;
    }

    @media (min-width: ${breakpoints.extraLarge}) {
        padding: 150px 130px;
    }
`;

const Argument = styled.div`
    display: flex;
    color: ${colors.darkGrey};
    margin-bottom: ${layout.veryLarge}px;

    & > i {
        color: ${colors.main};
        min-width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        background-color: rgba(47, 179, 190, 0.2);
        font-size: ${fontSizes.small}px;
        margin-top: 5px;
    }

    & > div {
        padding-left: ${layout.small}px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;


        h3 {
            color: ${colors.main};
            font-size: 20px;
            margin: 0 0 ${layout.small}px 0;
            font-weight: ${weight.medium};
        }

        p {
            margin: 0;
            font-size: ${fontSizes.large}px;
            font-weight: ${weight.light};

            & + p {
                font-style: italic;

                & > i {
                    display: inline-block;
                    font-size: 8px;
                    margin-right: 5px;
                }
            }
        }

        a {
            margin-top: ${layout.huge}px;
        }
    }
`;

const Block2 = () => {

    // On recupère la fonction de traduction
    const { t } = useTranslation('translation', { keyPrefix: 'home.block2' });

    return (
        <Block2Container>
            <div>
                <h2> {t('titleDiscorverPass')} </h2>
                <h2> {t('subtitle')} </h2>

                {block2Datas.map((data, index) => (
                    <Argument key={index}>
                        <Icon icon="check" />
                        <div>
                            <h3>{data.title}</h3>
                            <p>{data.text}</p>

                            {data.subText && (
                                <p>
                                    <Icon icon="arrow-slider-right" />
                                    {data.subText}
                                </p>
                            )}

                            {index === block2Datas.length - 1 && (
                                <InternalLinkAsButton to='/coworking/contact-us?from=je-decouvre' onClick={() => ClickEventGA4('clic-CTA', 'clic-CTA-je-decouvre')}>
                                    {t('buttonDiscover')}
                                </InternalLinkAsButton>
                            )}
                        </div>
                    </Argument>
                ))}
            </div>

            <div className='imgContainer'>
                <img src={passCoworkingApp} srcSet={`${passCoworkingApp2x} 2x, ${passCoworkingApp3x} 3x`} alt={t('alt')} width="470" height="530" />
            </div>
        </Block2Container>
    );
}

export default Block2;