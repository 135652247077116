import { InlineWidget } from "react-calendly";
import styled from 'styled-components';

const CalendlyBlockContainer = styled.div`
    height: calc(100vh - 85px);
    
    .calendlyWidget {
        height: 100%;
    } 
`;

const CalendlyBlock  = ({url}) => {

    return (
        <CalendlyBlockContainer>
            <InlineWidget  className='calendlyWidget' styles={{height:"100%"}} url={url}/>
        </CalendlyBlockContainer>
    );
}

export default CalendlyBlock;