import styled from 'styled-components';

const TestModeBannerContainer = styled.div`
    background: linear-gradient(135deg,#e2574c,#fb8053,#ff9f15);
    position: fixed;
    z-index: 99999;
    width: 100%;
    top: 0;
    color: #fff;
    text-align: center;
    max-width: 1920px;
`

const TestModeBanner = () => <TestModeBannerContainer>Test mode</TestModeBannerContainer>;

export default TestModeBanner;