import styled from "styled-components";
import { gradients, fontSizes, layout, colors } from '../../constants';
import Loader from "../Loader";

const InputSubmit = styled.button`
    padding: 0 ${layout.huge}px;
    transition-property: opacity;
    transition-duration: 200ms;
    height: 40px;
    border: 0px;
    border-radius: 8px;
    line-height: 27.85px;
    color: white;
    cursor: pointer;
    text-decoration: none;
    background: linear-gradient(90deg, ${gradients.blueBackgroundGradient});
    display: flex;
    align-items: center;
    font-family: "latoBold", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-size: ${fontSizes.large}px;

    &:not(:disabled):hover {
        opacity: .6;
    }

    &:disabled {
        cursor: not-allowed;
        background: ${colors.grey};
    }

    & > span {
        &:nth-of-type(2) {
            margin-left: ${layout.small}px;
        }
    }
`;

const Submit = ({children, isLoading}) => {
    return (
        <InputSubmit type="submit" className="tk-sofia-pro" disabled={isLoading}>
            <span>{children}</span>

            {isLoading && (
                <Loader />
            )}
        </InputSubmit>
    );
}

export default Submit;