import {Helmet} from "react-helmet";
import { neonomadeUrl } from '../../datas/commonLinks';
import img from '../../assets/images/neo-nomade-share.jpg';

const Meta = ({ title, description, url = '/', imageUri }) => {

    return ( 
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />

            <meta property="og:url" content={neonomadeUrl+url}/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            <meta property="og:image" content={imageUri ? imageUri : img}/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta property="twitter:domain" content="neo-nomade.com"/>
            <meta name="twitter:site" content="@neonomade_com"/>
            <meta property="twitter:url" content={neonomadeUrl+url}/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:description" content={description}/>
            <meta name="twitter:image" content={imageUri ? imageUri : img}/>
            <meta name="twitter:creator" content="@neonomade_com"/>


            {/* Bannières application mobile  */}
            <meta name="apple-itunes-app" content="app-id=1133062522"/>
            <meta name="google-play-app" content="app-id=com.lbmg.app_neonomade"/>
        </Helmet>
    );
};

export default Meta;