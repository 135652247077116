import { neonomadeUrl } from "./commonLinks";
import i18n from '../plugin/i18next/i18next';

const followBlock = {
    title: i18n.t('footer.nnPart.followUsColumn.title'),
    content: [
        {
            name: 'Linkedin',
            icon: 'linkedin',
            href: 'https://fr.linkedin.com/company/neo-nomade-com',
            trackerClick: 'clic-footer-linkedin'
        },
        {
            name: 'Twitter',
            icon: 'twitter',
            href: 'https://twitter.com/neonomade_com',
            trackerClick: 'clic-footer-twitter'
        },
        {
            name: 'Facebook',
            icon: 'facebook',
            href: 'https://fr-fr.facebook.com/neonomadecom',
            trackerClick: 'clic-footer-facebook'
        },
        {
            name: 'Instagram',
            icon: 'instagram',
            href: 'https://www.instagram.com/neo.nomade',
            trackerClick: 'clic-footer-instagram'
        }
    ]
};

export const FooterContent = [
    [
        {
            type: 'leftFooter',
            content: [{
                title: i18n.t('footer.nnPart.contactColumn.title'),
                content: [
                    {
                        name: i18n.t('footer.nnPart.contactColumn.openingHour'),
                        className: 'fakeLink',
                    },
                    {
                        name: i18n.t('footer.nnPart.contactColumn.openingDay'),
                        className: 'fakeLink',
                    },
                    {
                        name: 'contact@neo-nomade.com',
                        icon: 'email-fill',
                        href: 'mailto:contact@neo-nomade.com',
                    }
                ]
            },
                followBlock,
            ]
        },
        {
            type: 'common',
            title: i18n.t('footer.nnPart.nnColumn.title'),
            content: [
                {
                    name: i18n.t('footer.nnPart.nnColumn.home'),
                    href: neonomadeUrl,
                    trackerClick: 'clic-footer-accueil'
                },
                {
                    name: i18n.t('footer.nnPart.nnColumn.search'),
                    href: 'https://www.neo-nomade.com/s/france/location-bureau-coworking',
                    trackerClick: 'clic-footer-recherche'
                },
                {
                    name: i18n.t('footer.nnPart.nnColumn.connection'),
                    href: `${neonomadeUrl}/connexion`,
                    trackerClick: 'clic-footer-connexion'
                },
                {
                    name: i18n.t('footer.nnPart.nnColumn.inscription'),
                    href: `${neonomadeUrl}/inscription`,
                    trackerClick: 'clic-footer-inscription'
                },
                {
                    name: i18n.t('footer.nnPart.nnColumn.forgotPassword'),
                    href: `${neonomadeUrl}/mdp-perdu`,
                    trackerClick: 'clic-footer-mot-de-passe-perdu'
                },
                {
                    name: i18n.t('footer.nnPart.nnColumn.contact'),
                    href: `${neonomadeUrl}/contact`,
                    trackerClick: 'clic-footer-contact'
                },
                {
                    name: i18n.t('footer.nnPart.nnColumn.jobs'),
                    href: 'https://www.welcometothejungle.com/fr/companies/neo-nomade',
                    trackerClick: 'clic-footer-jobs'
                },
                {
                    name: i18n.t('footer.nnPart.nnColumn.appMobileIOS'),
                    href: 'https://itunes.apple.com/fr/app/neo-nomade/id1133062522?l=fr&ls=1&mt=8',
                    trackerClick: 'clic-footer-app-mobile-ios'
                },
                {
                    name: i18n.t('footer.nnPart.nnColumn.appMobileAndroid'),
                    href: 'https://play.google.com/store/apps/details?id=com.lbmg.app_neonomade',
                    trackerClick: 'clic-footer-app-mobile-android'
                },
            ]
        },
        {
            title: i18n.t('footer.nnPart.coworkingInFranceColumn.title'),
            type: 'city',
            hideSeeMore: true,
            urlEnding: 'location-bureau-coworking',
        },
        {
            type: 'common',
            title: i18n.t('footer.nnPart.visionColumn.title'),
            content: [
                {
                    isInternal: true,
                    name: i18n.t('footer.nnPart.visionColumn.studies'),
                    href: `/nos-etudes`,
                    trackerClick: 'clic-footer-vision-etudes'
                },
                {
                    isInternal: true,
                    name: i18n.t('footer.nnPart.visionColumn.customerTestimonials'),
                    href: `/nos-clients-temoignent`,
                    trackerClick: 'clic-footer-temoignages-clients'
                },
                {
                    isInternal: true,
                    name: i18n.t('footer.nnPart.visionColumn.blog'),
                    href: `/blog`,
                    trackerClick: 'clic-footer-vision-blog'
                },
                {
                    isInternal: true,
                    name: i18n.t('footer.nnPart.visionColumn.manifest'),
                    href: `/manifeste`,
                    trackerClick: 'clic-footer-manifeste'
                }
            ]
        },
        followBlock
    ], [
        {
            title: i18n.t('footer.cityBlock.espaceCoworkingColumn.title'),
            type: 'city',
            nameStart: i18n.t('footer.cityBlock.espaceCoworkingColumn.nameStart'),
            urlEnding: 'espace-coworking',
            trackerClick: 'clic-footer-SEO-coworking'
        },
        {
            title: i18n.t('footer.cityBlock.bureauFerme.title'),
            type: 'city',
            nameStart: i18n.t('footer.cityBlock.bureauFerme.nameStart'),
            urlEnding: 'bureau-ferme-coworking',
            trackerClick: 'clic-footer-SEO-bureau'
        },
        {
            title: i18n.t('footer.cityBlock.salleReunionFormation.title'),
            type: 'city',
            nameStart: i18n.t('footer.cityBlock.salleReunionFormation.nameStart'),
            urlEnding: 'salle-reunion-formation-location',
            trackerClick: 'clic-footer-SEO-reunion'
        },
        {
            title: i18n.t('footer.cityBlock.salleConferance.title'),
            type: 'city',
            nameStart: i18n.t('footer.cityBlock.salleConferance.nameStart'),
            urlEnding: 'salle-seminaire-conference-evenementiel',
            trackerClick: 'clic-footer-SEO-evenementiel'
        }
    ]
];

export const cityList = [
    'Paris', 'Lyon', 'Nantes', 'Bordeaux', 'Marseille', 'Strasbourg', 'Lille', 'Nancy'
]