import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors, rgbColors, gradients, breakpoints, layout, weight, fontSizes } from '../constants';
import fakeVideoImg from '../../assets/images/Group91.webp';
import fakeVideoImg2x from '../../assets/images/Group91@2x.webp';
import fakeVideoImg3x from '../../assets/images/Group91@3x.webp';
import backgroundPartnerPage from '../../assets/images/backgroundPartnerPage.jpg';
import { ClickEvent } from '../hook/Tracking';
import { ClickEventGA4 } from '../hook/TrackingGA4';
import InternalLinkAsButton from '../ui/buttons/InternalLinkAsButton';
import { neonomadeUrl } from '../../datas/commonLinks';
import CtaButton from '../ui/buttons/CtaButton';
import Button from '../ui/buttons/Button';
import { formateInternalUri } from '../hook/FormateInternalUri';
import Icon from "../ui/Icon";
import { useState } from 'react';
import PlayButton from '../../assets/svg/play_button.svg';
import YouTube from 'react-youtube';
import { Trans } from 'react-i18next';

const HomePageBlockTypeContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 30px;
    color: ${colors.darkGrey};
    padding-top: 100px;

    @media (min-width: ${breakpoints.large}) {
        flex-direction: row;
        flex-wrap: no-wrap;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0px 30px;
        padding-top: 110px;
    }

    @media (min-width: ${breakpoints.extraLarge}) {
        padding: 110px 130px 0;
    }

    .imgBlock {
        width: 100%;
        justify-content: center;
    }

    &.partnerPage {
        min-height: calc(100vh);
        border-radius: 0;
        align-items: center;
        justify-content: center;
        padding-top: 140px;
        background: white;

        @media (min-width: ${breakpoints.large}) {
            padding-top: 100px;
        }

        .homePageBlockContentType {
            order: 1;
            margin-right: 0;

            @media (min-width: ${breakpoints.large}) {
                order: 2;
                margin-left: 60px;
                margin-bottom: 0;
                width: 100%;
                max-width: 700px;
            }

            .logosImg {
                max-width: 350px;
                width: 100%;
            }

            .homePageBlockTypeButtons {
                @media (min-width: ${breakpoints.large}) {
                    margin-bottom: 0;
                }
            }
        }

        .imgBlock {
            order: 2;
            align-self: center;
            margin-bottom: 0px;

            @media (min-width: ${breakpoints.large}) {
                order: 1;
                width: 100%;
                max-width: 600px;
            }

            .fakeVideoImg {
                border-radius: 8px;
                width: 100%;
            }
        }
    }
`;

const TitleBlock = styled.h1`
    span {
        background: linear-gradient(
            135deg,
            ${gradients.mainGradient}
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    };

    font-size: 40px !important;
    font-weight: ${weight.medium};
    line-height: 48px;
    margin-top: 0;

    @media (min-width: ${breakpoints.large}) {
        font-size: 50px !important;
        line-height: 60px;
    }
`;

const HomePageBlockTypeContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: ${breakpoints.large}) {
        margin-right: 60px;
        margin-bottom: 40px;
    }

    .homePageBlockTypeText {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: ${layout.large}px;
        font-weight: ${weight.light};


        @media (min-width: ${breakpoints.large}) {
            font-size: 20px;
            max-width: 600px;
            margin-right: 60px;
        }

        .homePageBlockTypeSecondText {
            font-size: 18px;
            line-height: 20px;
            margin-top: ${layout.large}px;
            font-weight: ${weight.light};

            @media (min-width: ${breakpoints.large}) {
                font-size: 20px;
                margin-top: 40px;
            }
        }

        &.second {
            margin-top: ${layout.huge}px;
        }
    }

    .homePageBlockTypeButtons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: ${layout.huge}px;
        flex-wrap: wrap;

        a {
            margin-top: ${layout.large}px;
            width: 100%;

            @media (min-width: ${breakpoints.large}) {
                width: initial;
            }

            span {
                display: flex;
                align-items: center;

                i {
                    margin-left: ${layout.verySmall}px;
                    margin-top: 3px;
                }
            }
        }

        @media (min-width: ${breakpoints.extraLarge}) {
            flex-direction: row;
            align-items: center;
        }

        .CtaButtonPartner, .ctaCustomNeeds {
            @media (min-width: ${breakpoints.medium}) {
                margin-right: ${layout.veryLarge}px;
            }
        }
    }

    .homePageBlockSubCtaText {
        font-size: 16px;
        line-height: 20px;
        margin-top: 0;
        margin-bottom: ${layout.huge}px;
        font-weight: ${weight.light};
        font-style: italic;

        @media (min-width: ${breakpoints.large}) {
            font-size: 18px;
        }
    }
`;

const FakeVideoBlock = styled.div`
    display: flex;
    align-self:center;
    z-index: 1;
    position: relative;

    .fakeVideoImg {
        max-width: 350px;
        height: 100%;
        z-index: 1;
        transition-duration: 200ms;
        transition-property: opacity;

        &.zero-opacity {
            opacity: 0;
        }
    }

    .playButtonImg {
        width: 100px;
        height: 100px;
        position: absolute;
        top: calc(50% - 50px);
        left: calc(50% - 50px);
        z-index: 10;
        cursor: pointer;
        transition-duration: 200ms;
        transition-property: transform opacity;
        transform-origin: center;

        &:hover {
            transform: scale(1.5);
        }

        &:active {
            transform: scale(0.8);
        }

        &.zero-opacity {
            opacity: 0;
        }

        @media (min-width: ${breakpoints.medium}) {
            &:active {
                transform: scale(1.2);
            }
        }
    }

    iframe {
        box-shadow: 0 0.3rem 1.5rem 0.7rem rgb(142 157 175 / 20%);
        transition-duration: 200ms;
        transition-property: opacity;
        position: absolute;
        top: calc(50% - ${props => props.iframeHeight / 2}px);
        z-index: 1000;
        left: -30px;

        &.zero-opacity {
            z-index: -1;
            opacity: 0;
        }

        @media (min-width: 770px) {
            left: calc((100% - ${props => props.iframeWidth}px) / 2);
        }

        @media (min-width: ${breakpoints.large}) {
            left: 0;
        }
    }

    @media (min-width: ${breakpoints.large}) {
        max-width: none;
        position: relative;
        margin-bottom: -78px;

        .fakeVideoImg {
            max-width: none;
            width: 522px;
            height: initial;
        }
    }
`;

const Argument = styled.div`
    display: flex;
    align-items: top;
    margin-top: ${layout.medium}px;

    & > i {
        color: ${colors.green};
        min-width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        background-color: rgba(${rgbColors.green}, 0.2);
        font-size: ${fontSizes.small}px;
    }

    p {
        margin: 0 0 0 ${layout.small}px;
        font-size: ${fontSizes.large}px;
        font-weight: ${weight.light};
    }
`;

const HomePageBlockType = ({ className, title, text, textData = null, secondText = null, pageName, subCtaText = null }) => {
    // On indique que la vidéo n'est pas initialisée
    const [isVideoPlayed, setIsVideoPlayed] = useState(false);
    const [player, setPlayer] = useState(null);

    // On crée une fonction pour jouer la vidéo de présentation
    const onPlayButtonPress = () => {
        // On indique qu'on veut afficher la vidéo
        setIsVideoPlayed(true);

        // GA
        ClickEventGA4('clic-CTA', 'clic-CTA-video');

        // Si la vidéo est bien initialisée
        if (player) {
            player.playVideo();
        }
    }

    // On récupère la fonction de traduction
    const { t } = useTranslation('translation');

    // On récupère la largeur de la fenêtre actuelle
    const currentWindowWidth = window.innerWidth;

    // On en déduit les dimensions de l'iframe de la vidéo YT
    const iframeWidth = currentWindowWidth <= 1024 ? Math.min(770, currentWindowWidth) : 500;
    const iframeHeight = currentWindowWidth <= 1024 ? Math.min(433, Math.floor(currentWindowWidth / 1.77) - 1) : 281;

    // On prépare les paramètres de la vidéo YT
    const introducingVideoOptions = {
        width: iframeWidth,
        height: iframeHeight,
        playerVars: {
            origin: 'https://www.neo-nomade.com',
            modestbranding: 1,
            rel: 0,
        }
    }

    return (
        <div>
            <HomePageBlockTypeContainer className={className}>
                <HomePageBlockTypeContent className='homePageBlockContentType'>
                    <TitleBlock>
                        {title}
                    </TitleBlock>

                    <div className='homePageBlockTypeText'>
                        {text}
                        {textData !== null && (
                            <>
                                {textData.map((data, index) => (
                                    <Argument key={index}>
                                        <Icon icon="check" />
                                        <p>{data.text}</p>
                                    </Argument>
                                ))}
                            </>
                        )}
                        {secondText ?
                            <div className='homePageBlockTypeSecondText'>{secondText}</div>
                            : null
                        }
                    </div>

                    <div className='homePageBlockTypeButtons lato'>
                        {pageName === 'homePage' && (
                            <>
                                <InternalLinkAsButton className="ctaCustomNeeds" mode="shadow-blue" to={formateInternalUri('/qualification')} onClick
                                    ={() => ClickEventGA4('clic-CTA', 'clic-CTA-sur-mesure')}>
                                    {t("button.needCustomSolution")}
                                </InternalLinkAsButton>
                                <Button className='CtaButtonFindSpace' onClick={() => { ClickEventGA4('clic-CTA', 'clic-CTA-trouver-un-espace') }} href={`${neonomadeUrl}/s`}>
                                    {t("button.findSpaceNow")}
                                </Button>
                            </>
                        )}

                        {pageName === 'partner' && (
                            <>
                                <CtaButton className='CtaButtonPartner' onClick={() => { ClickEvent('Clic', 'partenaire-rejoindre-worklib') }} href={`https://host.worklib.io/`}>
                                    {t("partnerBlock.joinWorklib")}
                                </CtaButton>
                                <Button href="https://wp.neo-nomade.com/wp-content/uploads/2023/12/Host-Deck.pdf" target="_blank" onClick={() => { ClickEvent('Clic', 'partenaire-en-savoir-plus') }}>
                                    En savoir plus
                                </Button>
                            </>
                        )}
                    </div>

                    {pageName === 'partner' && (
                    <><div className='homePageBlockTypeText second'>
                        {<Trans i18nKey="partnerBlock.text2" components={{ strong: <strong /> }}> </Trans>}
                    </div>

                    <div className='homePageBlockTypeButtons lato'>
                        
                            <>
                                <CtaButton className='CtaButtonPartner' onClick={() => { ClickEvent('Clic', 'partenaire-nous-rejoindre') }} href={`https://host.worklib.io`}>
                                {t("partnerBlock.joinWorklib")}
                                </CtaButton>
                                <Button href="https://www.neo-nomade.com/entreprises/wp-content/uploads/2022/09/81dc6298614c-Plaquette_Neo_nomade_espace_2022.pdf" target="_blank" onClick={() => { ClickEvent('Clic', 'partenaire-en-savoir-plus') }}>
                                    En savoir plus
                                </Button>
                            </>
                        
                    </div></>)}


                    {pageName === 'homePage' && (
                        <div className="homePageBlockSubCtaText">{subCtaText}</div>
                    )}
                </HomePageBlockTypeContent>

                <FakeVideoBlock className='imgBlock' iframeWidth={iframeWidth} iframeHeight={iframeHeight}>
                    {pageName === 'homePage' && (
                        <>
                            <YouTube
                                videoId='EN0OoF1SeOg'
                                iframeClassName={isVideoPlayed ? '' : 'zero-opacity'}
                                opts={introducingVideoOptions}
                                onReady={(event) => setPlayer(event.target)}
                            />

                            <img className={isVideoPlayed ? 'playButtonImg zero-opacity' : 'playButtonImg'} alt={t('home.fakeVideoBlock.playButtonAlt')} src={PlayButton} onClick={onPlayButtonPress} width='100' height='100' />
                            <img src={fakeVideoImg} srcSet={`${fakeVideoImg2x} 2x, ${fakeVideoImg3x} 3x`} className={isVideoPlayed ? 'fakeVideoImg zero-opacity' : 'fakeVideoImg'} alt={t("home.fakeVideoBlock.alt")} width="522" height="650" fetchpriority="high" />
                        </>
                    )}

                    {pageName === 'partner' && (
                        <img src={backgroundPartnerPage} className={isVideoPlayed ? 'fakeVideoImg zero-opacity' : 'fakeVideoImg'} alt={t("home.fakeVideoBlock.alt")} width="500" height="627" />
                    )}
                </FakeVideoBlock>
            </HomePageBlockTypeContainer>
        </div>
    );
}

export default HomePageBlockType;