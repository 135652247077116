export const neonomadeUrl = process.env.REACT_APP_NN_URL;
export const neonomadeApiUrl = process.env.REACT_APP_NN_API_URL;
export const neonomadeFinderUrl = process.env.REACT_APP_NN_FINDER_URL;
export const neonomadeBlogUrl = process.env.REACT_APP_NN_BLOG_URL;
export const neonomadeWordpressUrl = process.env.REACT_APP_NN_WP_URL;
export const neonomadeBoUrl = process.env.REACT_APP_NN_BO_URL;
export const hubspotLink = process.env.REACT_APP_HUBSPOT_URL;

export const blogMenuId = process.env.REACT_APP_NN_WP_MENU_ID;
export const testimonyCategoryId = process.env.REACT_APP_NN_WP_TESTIMONY_CAT_ID;
export const studiesCategoryId = process.env.REACT_APP_NN_WP_STUDIES_CAT_ID;