import { neonomadeFinderUrl, neonomadeUrl } from '../../datas/commonLinks';
import { useTranslation } from 'react-i18next';
import errorDefault from '../../assets/svg/notFoundPage/default.svg';
import errorDefaultMobile from '../../assets/svg/notFoundPage/default-mobile.svg';
import { Link } from 'react-router-dom';

const ErrorPageContentBlock = ({title, subtitle, description}) => {

    // On recupère la fonction de traduction
    const { t } = useTranslation('translation', { keyPrefix: 'errorPage' });

    return (
        <>
            <div className='errorImageContainer'>
                <img src={errorDefault} alt='erreur' className='imgError'/>
                <img src={errorDefaultMobile} alt='erreur' className='imgErrorMobile'/>
            </div>

            <div className='errorTitleContainer brandonBold'>
                {title}
            </div>

            <div className='errorSubTitleContainer latoBold'>
                {subtitle}
            </div>

            <div className='errorDescriptionContainer'>
                {description}
            </div>

            <div className="errorRedirectionsController">
                <div className="flex1">
                    <Link to="/" className="link">
                        {t('accueilLink')}
                    </Link>
                </div>

                <div>&#47;&#47;</div>

                <div className="flex1">
                    <a href={`${neonomadeUrl}/connexion/`}> {t('connectionLink')} </a>
                </div>

                <div>&#47;&#47;</div>

                <div className="flex1">
                    <a href={neonomadeFinderUrl}>  {t('searchLink')} </a>
                </div>
            </div>
        </>        
    );
}

export default ErrorPageContentBlock;