import styled from 'styled-components';
import { colors, fontSizes, layout } from '../../constants';

const GreyInputContainer = styled.input`
    border-radius: 6px;
    outline: none;
    background-color: #F1F1F1;
    height: 45px;
    width: 100%;
    padding: 0 ${layout.medium}px 0 ${layout.large}px;
    border: 0;
    font-size: ${fontSizes.medium}px;
    color: ${colors.darkGrey};

    ::placeholder {
        color: rgba(0, 0, 0, .25);
    }
`;

const GreyInput = (props) => {
    const inputProps = {
        ...props,
        className: `${props.className} tk-sofia-pro`
    }

    return (
        <GreyInputContainer 
            {...inputProps}
        />
    );
}

export default GreyInput;