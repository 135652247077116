import Header from '../components/structure/Header';
import Meta from "../components/structure/Meta"
import RenderHeaderSticky from '../components/hook/RenderHeaderSticky';
import Footer from '../components/structure/Footer';
import { useEffect, useState, useContext} from 'react';
import styled from 'styled-components';
import { colors, breakpoints } from '../components/constants';
import { useParams, useSearchParams } from 'react-router-dom';
import Pagination from '../components/block/Pagination';
import AlternativeBlogArticleThumbnail from '../components/block/AlternativeBlogArticleThumbnail';
import ErrorPage from '../pages/ErrorPage';
import { fetchPosts, formatPostToThumbnail } from '../utils/blogUtils';
import MainBlogArticleThumbnail from '../components/block/MainBlogArticleThumbnail';
import ContentLoader from 'react-content-loader'
import BlogMenu from './components/BlogMenu';
import { initGA4 } from '../components/hook/TrackingGA4';
import { UserContext } from '../utils/context';

const PageContent = styled.div`
    color: ${colors.darkGrey2};
    padding: 30px;
    padding-top: 170px;

    .blogBody {

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;
        margin-left: auto;
        margin-right: auto;

        &.isLoading {
            display: block !important;
        }

        h3 {
            display:inline-block;
            width:100%;
            padding:15px;
            grid-column-end: span 2;
        }

        & > div {
            grid-column-end: span 2;
        }

        @media (max-width: ${breakpoints.small}) {
            display: block;
            max-width: none;

        }

        @media (min-width: ${breakpoints.extraLarge}) {
            max-width: 1100px;

        }
    }
`


const BlogSummary = ({ tag }) => {
    let [searchParams] = useSearchParams();
    const params = useParams();
    const page = !!params.page ? parseInt(params.page) : 1;
    const search = !!searchParams.get("s") ? searchParams.get("s") : null;

    const [isLoading, setLoading] = useState(true);
    const [apiData, setApiData] = useState({ status: true });

    useEffect(() => {
        //setLoading(true);
        window.scrollTo({ top: 0, behavior: 'smooth' })

        const fetchData = async () => {
            const tags = !!tag ? tag.id : null;
            const postsData = await fetchPosts({ page, search, tags });
            setApiData(postsData);
            setLoading(false);
        }

        fetchData();
    }, [page, search, tag]);

    const {user} = useContext(UserContext);

    // Une fois que la page est prête
    useEffect(() => {

        if (navigator.userAgent !== "ReactSnap") {
            let forfaitType = 'forfait-grand-public';

            if (user && user.currentForfaitId !== 37) {
                forfaitType = 'forfait-grand-compte';
            }

            const gaOptions = {
                ...(user && { 'user-id': user.id }),
                'titre-page': 'coworking',
                'content_type': 'blog-categorie',
                'blog-category': "coworking",
                'type-connecte': (user) ? 'Oui' : 'Non',
                'type-forfait': forfaitType
            }

            initGA4('G-YKDRHSWN0X', gaOptions);
        }
    }, [user]);


    // On appelle le hook pour rendre le header sticky + ajouter un margin au block 1
    const isScrollingUp = RenderHeaderSticky();
    return apiData.status ? (
        <>
            <Meta
                title={`Le blog Neo-nomade`}
                description={'Le blog neo-nomade vous informe des actualités du coworking et du travail hybride'}
                url='/blog'
            />
            <Header isScrollingUp={isScrollingUp} fixed={true} />
            <BlogMenu currentPage='home' />
            <PageContent>
                {isLoading && (<ContentLoader className="blogBody" viewBox="0 0 380 240">
                    <rect x="5" y="5" rx="2" ry="2" width="370" height="125" />
                    <rect x="5" y="140" rx="3" ry="3" width="180" height="100" />
                    <rect x="195" y="140" rx="2" ry="2" width="180" height="100" />
                </ContentLoader>)}
                {!isLoading && (
                    <div className="blogBody">
                        {!!tag && apiData.postList && (<h3>{apiData.postCount} articles à découvrir avec le tag "{tag.name}"</h3>)}
                        {!!search && apiData.postList && (<h3>{apiData.postCount} articles à découvrir {!!search && `pour la recherche "${search}"`}</h3>)}
                        {!tag && !search && page === 1 && apiData.postList && (<MainBlogArticleThumbnail post={[...apiData.postList].shift()} />)}
                        {apiData.postList && apiData.postList.slice(!!tag || !!search || page > 1 ? 0 : 1).map(post => (<AlternativeBlogArticleThumbnail key={`post-${post.id}`} post={formatPostToThumbnail(post)} />))}

                        <Pagination page={page} pageCount={apiData.pageCount} />
                    </div>
                )}
            </PageContent>
            <Footer />
        </>
    ) : (
        <ErrorPage errorCode={4} />
    );
}

export default BlogSummary;