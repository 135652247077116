import styled from 'styled-components';
import Icon from '../ui/Icon'
import { colors, weight, fontSizes } from '../constants';
import { neonomadeUrl } from '../../datas/commonLinks';
import { useState } from 'react';
import { useCallback } from 'react';
import RadioButton from '../ui/buttons/RadioButton';
import { useTranslation } from 'react-i18next';

const ManageForfaitContainer = styled.div`
    width: 50%;
    transition: 1s ease;
    color: ${colors.darkGrey2};
    background-color: white;
    cursor: default;
    padding: 5px 20px;
    border-radius: 12px;

    &.mobileView {
        top: 70px;
        margin-top: 15px;
        padding: 5px 10px;

        .ManageForfaitBlock {
            display: flex;
            flex-direction: column;
            height: auto;

            .textManageForfait{
                margin-bottom: 30px;
                &:after{
                    display: none;
                }
            }
            .forfaitList {
                grid-gap: 0px !important;
                padding-left: 0;
                position: unset;
                font-size: ${fontSizes.large}px;

                li {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    margin-bottom: 0 !important;
                    border-top: 1px solid ${colors.grey};

                    .radioBtnManageForfait {
                        justify-content: space-between;

                        input {
                            margin-right: 0px;
                        }
                    }
                }

                border-left: none !important;
            }
        }

        .returnBtnManageForfait {
            margin-bottom: 15px;
        }
    }

    .ManageForfaitBlock {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 75%;
        margin-bottom: 40px;
        position: relative;
        overflow: hidden;

        .textManageForfait {
            display: flex;
            padding-right: 15px;
            position: relative;
            font-size: 14px;
            height: min-content;

            .iconManageForfait {
                height: 30px;
                width: 24px;
                font-size: 30px;
                display: inline-block;
                color: ${colors.main};
                margin-right: 15px;
            }

            .textBlockManageForfait{
                color: ${colors.grey};
                font-size: 13px;
                font-weight: 300;

                div {
                    color: ${colors.darkGrey};
                    font-size: 16px;
                    font-weight: 400;
                    margin-bottom: 5px;
                }
            }

        }

        .forfaitList {
            display: grid;
            padding-left: 30px;
            grid-gap: 15px !important;
            grid-template-columns: 1fr!important;
            list-style-type: none;
            margin: 0;
            grid-column: 2 / span 2;
            border-left: 1px solid ${colors.grey};
            height: 100%;
            overflow: scroll;
            position: absolute;
            font-size: ${fontSizes.medium}px;

            button {
                width: 100%;
            }

            .radioBtnManageForfait {
                display: flex;

                .radioBtnForfait {
                    display: flex;
                    flex-direction: column;
                    align-items: end;

                    input {
                        vertical-align: text-bottom;
                    }
                }

                .activatedRadioText {
                    font-size: 11px;
                    font-style: italic;
                    font-weight: 100;
                }
            }

            li {
                color: ${colors.darkGrey2};

                span {
                    text-decoration-line: none !important;
                    align-self: center;
                }

                &.activated {
                    font-weight: ${weight.bold};
                }

                &:not(.activated) {
                    margin-bottom: 13px;
                }

                label {
                    cursor: pointer;
                }
            }

        }
    }

    .returnBtnManageForfait {
    display: flex;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    background-color: transparent;
    align-items: center;
    transition: color .1s;
    margin-bottom: 25px;
    margin-top: 10px;

    i {
        margin-right: 5px;
    }

    &:hover {
        color: ${colors.main};
    }
    }

    &.visible {
        visibility: visible;
        left : 0;
    }

`;

const RadioBtnManageForfait = ({ forfait, idForfaitCheck, changeCurrentForfaitId, mobileView, csrf }) => {

    const { t } = useTranslation('translation', { keyPrefix: 'header.userMenu' });

    return (mobileView ? (
        <label htmlFor={`forfait-${forfait.id}`} className='radioBtnManageForfait'>
            <span>{forfait.nom}</span>
            <div className='radioBtnForfait'>
                <RadioButton onChange={(e) => changeCurrentForfaitId(parseInt(e.target.value), csrf)} checked={idForfaitCheck === forfait.id} id={`forfait-${forfait.id}`} name={`forfait-${forfait.id}`} value={forfait.id} />
                {idForfaitCheck === forfait.id && (<div className='activatedRadioText'>{t('activated')}</div>)}
            </div>
        </label>
    ) : (
        <label htmlFor={`forfait-${forfait.id}`} className='radioBtnManageForfait'>
            <div className='radioBtnForfait'>
                <RadioButton onChange={(e) => changeCurrentForfaitId(parseInt(e.target.value), csrf)} checked={idForfaitCheck === forfait.id} id={`forfait-${forfait.id}`} name={`forfait-${forfait.id}`} value={forfait.id} />
            </div>
            <div>
                <span>{forfait.nom}</span>
                {idForfaitCheck === forfait.id && (<div className='activatedRadioText'>{t('activated')}</div>)}
            </div>
        </label>
    ));
};

const ManageForfaitBlock = ({ menuUser, setUserMenu, mobileView = false }) => {

    const { t } = useTranslation('translation', { keyPrefix: 'header.userMenu.blockForfaitManager' });

    // On recupère le forfait forfait courant
    const idCurrentForfait = menuUser ? menuUser.user.currentForfaitId : null

    // State pour l'ouverture du menu mobile
    const [currentForfaitId, setCurrentForfaitId] = useState(idCurrentForfait);

    const csrf = menuUser.csrfToken;

    function toggleManageForfaitBlock(e) {
        e.stopPropagation();

        const subMenuUserContainerToSlide = document.getElementById('subMenuUserContainerToSlide');
        subMenuUserContainerToSlide.classList.toggle("wentToSeeManageForfaitBlock");
    }

    const changeCurrentForfait = useCallback(async (idForfait, csrf) => {

        // On change le radio bouton selectionné
        setCurrentForfaitId(idForfait);

        // On prépare les valeurs à envoyer
        const formData = new FormData();
        formData.append('idForfait', idForfait);
        formData.append('csrfToken', csrf);

        // On va chercher le menu avec ajax
        let rep = await fetch(`${neonomadeUrl}/inc/ajax/utilisateur/changeForfaitCourant.php`, {
            method: 'POST',
            body: formData
        });
        let reponse = await rep.json();

        // Si la tout s'est bien passé
        if (reponse.statut) {

            // On enregistre le nouveau menu
            setUserMenu(reponse.data.userMenu);

        }

    }, [setUserMenu]);

    return (
        <ManageForfaitContainer id='jsManageForfaitBlock' onClick={(e) => e.stopPropagation()} className={mobileView ? 'mobileView' : ''}>
            <button onClick={(e) => { toggleManageForfaitBlock(e) }} className="returnBtnManageForfait" ><Icon icon="right-slim-arrow" orientation="left" />{t('returnBtn')}</button>
            <div className="ManageForfaitBlock">
                <div className="textManageForfait">
                    <Icon className="iconManageForfait" icon="mes-contrats" />
                    <div className='textBlockManageForfait'>
                        <div>{t('title')}</div>
                        {t('subtitle')}
                    </div>
                </div>
                <ul className="forfaitList">
                    {menuUser.forfaits.map((f, index) => (
                        <li key={'forfait-' + index} className={f.id === currentForfaitId ? 'activated' : ''}>
                            <RadioBtnManageForfait mobileView={mobileView} forfait={f} idForfaitCheck={currentForfaitId} changeCurrentForfaitId={changeCurrentForfait} csrf={csrf} />
                        </li>
                    ))}
                </ul>
            </div>
        </ ManageForfaitContainer>
    );
}

export default ManageForfaitBlock;