import styled from 'styled-components';
import Icon from '../ui/Icon';
import { htmlDecode } from '../../utils/blogUtils';
import { breakpoints, fontSizes } from '../constants';
import { useTranslation } from 'react-i18next';
import InternalOrExternalLink from '../ui/InternalOrExternalLink';

const ArticleContainer = styled.article`
    width: 100%;
    padding: 15px 0;
    height: 100%;

    & > div {
        height: 100%;
        background: white;
        box-shadow: 0 3px 15px 7px rgba(142,157,175,.2);
        border-radius: 10px;
        border: 1px solid #e5e5e5;
        position: relative;
        height: 400px;
        overflow: hidden;

        &.withDescription {
            @media (min-width: ${breakpoints.small}){

                &:hover {
                    .entry-content {
                        top: 25%;

                        .entry-description {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
             }

        }

        img {
            border-radius: 12px 12px 0 0;
            height: 40%;
            width: 100%;
            object-fit: cover;
        }

        .entry-content {
            padding: 30px 25px;
            width: 100%;
            position: absolute;
            bottom: 0;
            top: 40%;
            background: white;
            border-radius: 0px 0px 10px 10px;
            transition: top 0.4s ease;

            .entry-description {
                font-size: 15px;
                opacity: 0;
                transition: opacity 0.3s cubic-bezier(1,-0.01, 0.36, 1);
                text-decoration:none;
                color: #5B5B5B;
                visibility: hidden;

                &.smallDescription {
                    font-size: ${fontSizes.medium}px;

                }
            }
        }

        .entry-header {
            margin-bottom: 40px;
        }

        .entry-title {
            font-size: 18px;
            line-height: 1.5;
            margin: 0 0 10px;

            &.hugeTitle {
                font-size: 16px;
            }
        }

        .entry-meta {
            font-size: 14px;
            color: #c4c4c4;
            font-weight: 100;
            margin-bottom: 10px;
        }

        .read-more {
            font-size: 18px;
            position: relative;
            color: #29b7c3;
            text-decoration: none;
            display: flex;
            align-items: center;
            position: absolute;
            bottom: 0;
            padding: 30px 25px;
            width: 100%;
            i {
                margin-left:10px;
            }
        }

        @media (min-width: ${breakpoints.small}) {
            img {
                height: 45%;
            }

            .entry-content {
                top: 45%;

            }
        }

        @media (min-width: ${breakpoints.medium}) {
            img {
                height: 50%;
            }

            .entry-content {
                top: 50%;
            }
        }
    }
`

/**
 * Composant BlogArticle
 * @returns Contenu de BlogArticle
 */
const AlternativeBlogArticleThumbnail = ({ post, linkText, relatedPost }) => {
    const { t } = useTranslation('translation');

    return (
        <ArticleContainer>
            <div className={`${!!post.description ? "withDescription" : ""}`}>
                {!!post.image && (<img className='mainImage' alt={htmlDecode(post.title)} src={post.image} width="508" height="220" />)}
                <div className="entry-content withDescription">
                    <header className="entry-header">
                        <h2 className={`entry-title ${htmlDecode(post.title).length > 110 ? "hugeTitle" : ""}`}>{htmlDecode(post.title)}</h2>
                        {!!post.author && (<div className="entry-meta"> Par&nbsp;: {post.author} | {post.date}</div>)}
                        {!!post.description && (
                            <InternalOrExternalLink isExternalLink={post.externalLink} href={post.href} className={`entry-description ${htmlDecode(post.title).length > 110 ? "smallDescription" : ""}`}>
                                {`${htmlDecode(post.description).split(" ").slice(0, 30).join(" ")} ...`}
                            </InternalOrExternalLink>
                        )}
                    </header>
                </div>
                <InternalOrExternalLink isExternalLink={post.externalLink} href={post.href} className="read-more" relatedPost={relatedPost}>{linkText ?? t('blog.readArticle')} <Icon icon="right-slim-arrow" /></InternalOrExternalLink>
            </div>
        </ArticleContainer>
    )
};

export default AlternativeBlogArticleThumbnail;