import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors, fontSizes, breakpoints, weight } from '../constants';
import { Block6Datas } from '../../datas/blocks';
import BlogArticleThumbnail from '../../blog/components/BlogArticleThumbnail';


// Style de conteneur main du Bloc 6
const Block6Container = styled.div`
    background: linear-gradient(90.48deg, rgba(47, 179, 190, 0.05) 0.32%, rgba(6, 229, 213, 0.05) 99.58%);
    width: 100%;
    padding: 4rem 3rem 4rem 3rem;
    font-style: normal;
    font-size: ${fontSizes.large}px;
    color: #555555;

    @media screen and (min-width: ${breakpoints.medium}) and (max-width: ${breakpoints.large}) {
        padding: 5rem 4rem 7rem 4rem;
        font-size: ${fontSizes.medium}px;
    }

    @media screen and (min-width: ${breakpoints.large}) and (max-width: ${breakpoints.extraLarge}) {
        padding: 5rem 5rem 7rem 5rem;
        font-size: ${fontSizes.large}px;
    }

    @media screen and (min-width: ${breakpoints.extraLarge}) {
        padding: 6rem 8rem 8rem 8rem;
        font-size: ${fontSizes.large}px;
    }
`;

// Style du conteneur pour les titres
const TitlesContainer = styled.div`
   display: grid;
   grid-template-rows: repeat(2, 50%);
   margin-bottom: 4rem;
`;

// Style du conteneur 1er titre
const FirstTitle = styled.h4`
    color: ${colors.main};
    text-align: center;
    text-transform: uppercase;
    font-weight: ${weight.medium};
`;

// Style du conteneur 2ème titre
const SecondTitle = styled.h1`
    margin-top: -0.2rem;
    line-height: 40px;
    text-align: center;
    font-weight: ${weight.medium};

    @media screen and (min-width: ${breakpoints.medium}) and (max-width: ${breakpoints.large}) {
        margin-top: 0;
    }

    @media screen and (min-width: ${breakpoints.extraLarge}) {
        margin-top: 0;
    }
`;

// Style du conteneur de tous les contenus de bloc 6
const Block6ContentsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: ${breakpoints.large}) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-height: 650px;
        margin-right: -4rem;
        width:100%
    }
`;



/**
 * Composant Bloc 6
 * @returns Contenu de Bloc 6
 */
const Block6 = () => {

     // On recupère la fonction de traduction
    const { t } = useTranslation('translation', { keyPrefix: 'home.block6' });

    return (
        <Block6Container>
            <TitlesContainer>
                <FirstTitle>{t('firstTitle')}</FirstTitle>
                <SecondTitle>{t('secondTitle')}</SecondTitle>
            </TitlesContainer>

            <Block6ContentsContainer>
                {Block6Datas.map((data, index) => (
                    <BlogArticleThumbnail data={data} key={index} index={index} showMoreTitle={t('readArticle')} />
                ))}
            </Block6ContentsContainer>
        </Block6Container>
    )
};

export default Block6;