import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { colors, layout, fontSizes, breakpoints, weight } from '../constants';
import { block3Datas } from '../../datas/blocks';
import Icon from '../ui/Icon';

const Block3Container = styled.div`
    background: ${colors.main};
    padding: 80px 55px;

    h2 {
        color: ${colors.white};
        text-align: center;
        font-size: 30px;
        margin: 0;
        margin-bottom: 50px;
        font-weight: ${weight.medium};

        br {
            display: none;
        }

        @media (min-width: ${breakpoints.extraLarge}) {
            font-size: 40px;
            margin-bottom: 80px;

            br {
                display: block;
            }
        }

    }

    & > div {
        display: flex;
        flex-direction: column;

        @media (min-width: ${breakpoints.medium}) {
            flex-direction: row;
            flex-wrap: wrap;
        }

        @media (min-width: ${breakpoints.extraLarge}) {
            flex-wrap: nowrap;
        }
    }

    @media (min-width: ${breakpoints.extraLarge}) {
        padding: 150px 60px;
    }
`;

const Argument = styled.div`
    border: solid 1px ${colors.white};
    border-radius: 8px;
    padding: ${layout.veryLarge}px ${layout.huge}px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0.03) 100%);
    margin-bottom: 40px;
    position: relative;

    & > h3, p {
        color: white;
        margin: 0;
        font-weight: ${weight.light};
    }

    & > h3 {
        margin-bottom: ${layout.small}px;
        font-size: ${fontSizes.veryLarge}px;
        font-weight: ${weight.regular};
    }

    & > i {
        align-items: center;
        justify-content: center;
        color: ${colors.white};
        font-size: ${fontSizes.veryLarge}px;
        color: ${colors.main};
        width: 45px;
        height: 45px;
        background-color: ${colors.white};
        border-top-left-radius: 18px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 18px;
        border-top-right-radius: 8px;
        position: absolute;
        top: -16px;
        left: -16px;
    }

    @media (min-width: ${breakpoints.medium}) {
        width: calc(50% - 18px);

        &:nth-child(odd) {
            margin-right: 18px;
        }

        &:nth-child(even) {
            margin-left: 18px;
        }
    }

    @media (min-width: ${breakpoints.extraLarge}) {
        margin: 0 18px;
        width: auto;
        flex: 1;
    }
`;

const Block3 = () => {

    return (
        <Block3Container>
            <h2>
                <Trans i18nKey="home.block3.h2">
                  Mettez le coworking au coeur <br /> de vos pratiques de travail
                </Trans>
            </h2>

            <div>
                {block3Datas.map((data, index) => (
                    <Argument key={index}>
                        <Icon icon={data.icon} />
                        <h3>{data.title}</h3>
                        <p>{data.text}</p>
                    </Argument>
                ))}
            </div>
        </Block3Container>
    );
}

export default Block3;