import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors, fontSizes, breakpoints, weight } from '../constants';
import CtaButton from '../ui/buttons/CtaButton';
import InputText from '../ui/inputs/InputText';
import formeN1 from '../../assets/svg/formeN1.svg';
import formeN2 from '../../assets/svg/formeN2.svg';
import formeN3 from '../../assets/svg/formeN3.svg';
import formeN4 from '../../assets/svg/formeN4.svg';
import formeN5 from '../../assets/svg/formeN5.svg';
import formeN6 from '../../assets/svg/formeN6.svg';
import { ClickEventGA4 } from '../hook/TrackingGA4';
import { neonomadeUrl } from '../../datas/commonLinks';
import { useState } from 'react';
import Modal from '../structure/Modal';

// Style de conteneur main du Bloc Newsletter
const BlockNewsletterContainer = styled.div`
    background: linear-gradient(90.48deg, #2FB3BE 0.32%, #06E5D5 99.58%);
    color: ${colors.white};
    font-size: ${fontSizes.medium}px;
    overflow: hidden;
    height: 450px;

    & > img:nth-child(n) {
        visibility: hidden;
        width: 0%;
    }

    @media screen and (min-width: ${breakpoints.medium}) {
        & > img:nth-child(1) {
            position: sticky;
            left: 0%;
            top: 100%;
            visibility: visible;
            width: auto;
        }

        & > img:nth-child(2) {
            position: sticky;
            top: 10%;
            right: 78%;
            visibility: visible;
            width: auto;
        }

        & > img:nth-child(3) {
            position: sticky;
            top: 55%;
            right: 75%;
            visibility: visible;
            width: auto;
        }

        & > img:nth-child(4) {
            position: sticky;
            top: 25%;
            left: 72%;
            visibility: visible;
            width: auto;
        }

        & > img:nth-child(5) {
            position: sticky;
            top: 60%;
            left: 78%;
            visibility: visible;
            width: auto;
        }

        & > img:nth-child(6) {
            position: sticky;
            bottom: 100%;
            left: 100%;
            visibility: visible;
            width: auto;
        }
    }
`;

// Style de conteneur contenu du Bloc Newsletter
const BlockNewsletterContentContainer = styled.div`
    position: sticky;
    bottom: 50%;
    transform: translate(0, 50%);
    margin: 0px 50px;

    @media screen and (min-width: ${breakpoints.medium}) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`

// Style de conteneur tous les titres
const TitlesContainer = styled.div`
    max-width: 250px;
    margin: auto;

    @media screen and (min-width: ${breakpoints.medium}) {
        max-width: none;
    }
`;

// Style de conteneur 1er titre
const Title1Container = styled.h1`
    text-align: center;
    font-size: 30px;
    margin: 0px;
    margin-bottom: 20px;
    font-weight: ${weight.medium};

    @media screen and (min-width: ${breakpoints.medium}) {
        font-size: 50px;
        margin-bottom: 10px;
    }
`;

// Style de conteneur 2ème titre
const Title2Container = styled.h3`
    text-align: center;
    line-height: 20px;
    font-weight: normal;
    margin: 0;
    margin-bottom: 35px;
    font-weight: ${weight.regular};

    @media screen and (min-width: ${breakpoints.medium}) {
        line-height: 25px;
    }
`;

// Style de conteneur de formulaire
const FormContainer = styled.form`
    display: flex;
    flex-direction: row;
    border: 1px solid ${colors.white};
    padding: .25rem;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.075) 100%);
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    max-width: 415px;
    margin: auto;

    & > input {
        background-color: transparent;
        border: none;
        width: 100%;
        flex-grow: 2;
        color: ${colors.white};
        padding: 0.5rem 0.7rem 0.5rem 0.7rem;

        &:focus {
            border: none;
        }

        &::placeHolder {
            color: rgba(255, 255, 255, 0.7);
            font-family: 'Sofia', sans-serif;
        }
    }

    & > button {
        background-color: ${colors.white};
        border: 1px solid ${colors.white};
    }

    @media screen and (min-width: ${breakpoints.medium}) {
        width: 45%;
    }
`;

/**
 * Composant Bloc Newsletter
 * @returns Contenu de Newsletter bloc
 */
const BlockNewsletter = () => {
    const { t } = useTranslation('translation', { keyPrefix: 'newsletterblock' });

    const [email, setEmail] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const displayModal = (message) => {
        setShowModal(true);
        setModalMessage(message);
    }

    const submitContactForm = () => {
        // On log le clic
        ClickEventGA4('clic-CTA', `clic-CTA-sinscrire`)

        // On prépare les valeurs à envoyer
        const formData = new FormData();
        formData.append('email', email);

        // On envoie le formulaire
        fetch(`${neonomadeUrl}/inc/ajax/landings/newsletter.php`, {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then((data) => {
                // On affiche une maodale avec le message de retour
                displayModal(data.message);

                // Si tout est ok on nettoie le champ email
                if (!!data.status) {
                    setEmail('');
                }
            }).catch(() => {
                // Si une erreur s'est produite alors on affiche la modale avec un message d'erreur
                displayModal('Une erreur est survenue. Veuillez réessayer');
            });;
    }

    return (
        <BlockNewsletterContainer>
            <img alt="forme 1" src={formeN1} />
            <img alt="forme 2" src={formeN2} />
            <img alt="forme 3" src={formeN3} />
            <img alt="forme 4" src={formeN4} />
            <img alt="forme 5" src={formeN5} />
            <img alt="forme 6" src={formeN6} />

            <BlockNewsletterContentContainer>
                <TitlesContainer>
                    <Title1Container>{t('title')}</Title1Container>
                    <Title2Container>{t('subtitle')}</Title2Container>
                </TitlesContainer>

                <FormContainer>
                    <InputText onChange={(e) => setEmail(e.target.value)} placeHolder={t('placeHolder')} />
                    <CtaButton className='CtaWhite' onClick={submitContactForm}>{t('inscriptionButton')}</CtaButton>
                </FormContainer>
            </BlockNewsletterContentContainer>

            {showModal && <Modal setShowModal={setShowModal} title="Inscription à la newsletter">{modalMessage}</Modal>}

        </BlockNewsletterContainer>

    );
};

export default BlockNewsletter;