import HomePageBlockType from '../structure/HomePageBlockType';
import { Trans } from 'react-i18next';


const PartnerBlock = () => {
    
    // On prépare le titre
    const title = <Trans i18nKey="partnerBlock.title" components={{ blue: <span /> }}> </Trans>

    return (
        <>
            <HomePageBlockType
                title={title}
                text={<Trans i18nKey="partnerBlock.text" components={{ strong: <strong /> }}> </Trans>}
                pageName='partner'
                className='partnerPage'
            />
        </>
    );
}

export default PartnerBlock;