import styled from 'styled-components';
import { breakpoints, colors, layout, weight, fontSizes } from '../constants';
import LogosCarousel from '../structure/LogosCarousel';
import { clientsData } from '../../datas/blocks';
import HomePageBlockType from '../structure/HomePageBlockType';
import { useTranslation, Trans} from 'react-i18next';
import { block1SubtitleDatas } from '../../datas/blocks';

const backgroundCircleWidth = 1134;

const Block1Container = styled.div`
    position: relative;
    overflow: hidden;
    background: linear-gradient(314.17deg, rgba(0, 0, 0, 0.2) -27%, rgba(0, 0, 0, 0) 37.66%);
`;

const Block1UnderContentDesk = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin: ${layout.large}px ${layout.huge}px 0;

    @media (min-width: ${breakpoints.large}) {
        margin-left: 30px;
    }

    @media (min-width: ${breakpoints.extraLarge}) {
        width: calc(100% - 850px);
        margin-left: 130px;
    }

    .slick-slider {
        width: 100%;

        .slick-track {
            display:flex;
            align-items: center;

            .slick-slide  {
                display: flex;
                align-items: center;
                justify-content: center;

                & > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        img {
            width: initial !important;
        }
    }

    p {
        color: ${colors.darkGrey};
        margin: ${layout.small}px 0 50px 0;
        font-weight: ${weight.light};
        font-size: ${fontSizes.large}px;
    }

    img {
        max-height: 29px;

        // max-width: 130px;
        // width: initial;

        // @media (min-width: ${breakpoints.large}) {
        //     &.allianzLogo1, &.atosLogo1 {
        //         display: none;
        //     }
        // }

        // @media (min-width: ${breakpoints.extraLarge}) {
        //     &.allianzLogo1, &.atosLogo1 {
        //         display: block;
        //     }
        // }

        &.atosLogo1 {
            max-height: 22px;
        }

        &.prestashopLogo {
            max-height: 68px;
        }
    }
`;

const BackgroundCircle = styled.div`
    width: ${backgroundCircleWidth}px;
    height: ${backgroundCircleWidth}px;
    background-color: ${colors.main};
    border-radius: 50%;
    position: absolute;
    left: calc(100% - ${backgroundCircleWidth / 2}px);
    bottom: -275px;
    z-index: -1;
    background: linear-gradient(180deg, rgba(47, 179, 190, 0.25) 0%, rgba(247, 92, 175, 0.25) 100%);

    @media (min-width: ${breakpoints.large}) {
        bottom: -400px;
    }
`;

const Block1 = () => {
   const { t } = useTranslation('translation', { keyPrefix: 'home.block1' });

    const carouselResponsive = [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 3,
            }
        },

        {
            breakpoint: Number(breakpoints.extraLarge.replace('px', '')),
            settings: {
                slidesToShow: 4,
            }
        },

        {
            breakpoint: Number(breakpoints.medium.replace('px', '')),
            settings: {
                slidesToShow: 3,
            }
        },

        {
            breakpoint: Number(breakpoints.small.replace('px', '')),
            settings: {
                slidesToShow: 2,
            }
        },
    ];

   // On prépare le titre
   const title = <Trans i18nKey="home.block1.title" components={{ blue: <span /> }}> </Trans>

    return (
        <Block1Container>
            <BackgroundCircle />
            <HomePageBlockType
                title={title}
                text={t("subtitle")}
                textData={block1SubtitleDatas}
                secondText={t("secondSubtitle")}
                pageName='homePage'
                subCtaText={t("subCtaText")}
            />

            <Block1UnderContentDesk>
                <LogosCarousel logosDatas={clientsData} responsive={carouselResponsive} mode="color"></LogosCarousel>
                <p>
                    Les entreprises qui nous font confiance
                </p>
            </Block1UnderContentDesk>
        </Block1Container>
    );
}

export default Block1;