import styled from 'styled-components';
import { colors } from '../../constants';

const RadioButtonContainer = styled.div`

    input[type='radio'] {
        height: 20px;
        width: 20px;
        margin: 0;
        padding: 0;
        border : 1px solid ${colors.darkGrey2};
        border-radius: 10px;
        margin-right: 9px;
        transition: all;
        transition-duration: 0.2s;
        transition-timing-function: ease;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
       

        &:hover:not(:checked):not(:disabled) {
            border-color: ${colors.blueHover};
        }

        &:focus {
            outline: 0;
        }

        &:disabled {
            border-color: ${colors.grey};
            cursor: not-allowed;
        }

        &:checked {
            border: 5px solid ${colors.main};

            &:disabled {
                border-color : ${colors.grey};
            }
        }
    }
`;

const RadioButton = (props) => {
    return (
        <RadioButtonContainer>
            <input type='radio' {...props} />
        </RadioButtonContainer>
    );
}

export default RadioButton;