import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import blueLogo from '../../assets/logos/blue.svg';
import whiteLogo from '../../assets/logos/white.svg';
import { layout, breakpoints, fontSizes, colors } from '../constants';
import OnlyIconButton from '../ui/buttons/OnlyIconButton';
import { ClickEventGA4 } from '../hook/TrackingGA4';
import MobileMenu from './MobileMenu';
import { useEffect, useState, useContext } from 'react';
import { menu } from '../../datas/menu';
import DesktopMenu from './DesktopMenu';
import { Link } from 'react-router-dom';
import { neonomadeUrl } from '../../datas/commonLinks';
import { UserContext } from '../../utils/context';

const MenuOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    background-color: rgba(53,53,53,.35);
    overflow-y: scroll;
    min-height: 100vh;
    width: 100%;
    z-index: 10;
`;

const HeaderContainer = styled.header`
    ${(props) => {
        if (props.fixed === true) {
            return css`
                position: fixed;
            `;
        } else {
            return css`
                position: relative;
                box-shadow: 0 0.3rem 1.5rem 0.7rem rgb(142 157 175 / 20%);
            `;
        }
    }}

    padding: ${layout.large}px ${layout.veryLarge}px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: inherit;
    width: 100%;
    z-index: 99;
    background: transparent;
    transition-property: box-shadow;
    transition-duration: 200ms;

    &.stickyHeader {
        background: white;
        box-shadow: 0 0.3rem 1.5rem 0.7rem rgb(142 157 175 / 20%);

        ${(props) => {
        //console.log(props.mode);
        if (props.mode === 'white') {
            return css`
                    .headerLinks {
                        @media (min-width: ${breakpoints.large}) {
                            & > a, & > div {
                                color: ${colors.darkGrey};

                                &:hover {
                                    color: ${colors.main};
                                }
                            }
                        }
                    }

                    .headerArrowButton {
                        color: ${colors.main};
                        border-color: ${colors.main};
                    }

                    & > a {
                        & > img {
                            &.blueLogo {
                                display: block;
                            }

                            &.whiteLogo {
                                display: none;
                            }
                        }
                    }
                `;
        }
    }}
    }

    & > a {
        text-decoration: none;
        height: 40px;

        & > img {
            margin-right: auto;
            height: 40px;

            ${(props) => {
        // En fonction du mode
        switch (props.mode) {
            case 'white': {
                return css`
                            &.blueLogo {
                                display: none;
                            }
                        `;
            }

            default: {
                return css`
                            &.whiteLogo {
                                display: none;
                            }
                        `;
            }
        };
    }}
        }
    }

    .headerLinks {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 30px;
        flex: 1;

        .headerLink {

            & > a, & > div {
                cursor: pointer;
                display:flex;
                text-decoration: none;
                font-size: ${fontSizes.large}px;
                margin: 0 ${layout.small}px;
                height: 40px;
                align-items: center;
                justify-content: start;
                margin-left: 30px;
                transition-property: color;
                transition-duration: 200ms;

                ${(props) => {
        // En fonction du mode
        switch (props.mode) {
            case 'white': {
                return css`
                                color: ${colors.white};

                                &:hover {
                                    color: ${colors.white};
                                }
                            `;
            }

            default: {
                return css`
                                color: ${colors.darkGrey};

                                &:hover {
                                    color: ${colors.main};
                                }
                            `;
            }
        };
    }}

                @media (min-width: ${breakpoints.extraLarge}) {
                    margin: 0 ${layout.veryLarge}px;
                }
            }
        }
    }

    .headerArrowButton {
        margin: 0px ${layout.small}px;

        span {
            font-family: "sofia-pro",sans-serif;
        }

        i {
            margin-top: 2px;
        }

        @media (min-width: ${breakpoints.large}) {
            margin: 0;
            margin-left: ${layout.small}px;
        }

        ${(props) => {
        // En fonction du mode
        switch (props.mode) {
            case 'white': {
                return css`
                        color: ${colors.white};
                        border-color: ${colors.white};

                        &:hover {
                            border-color: ${colors.main};
                        }
                    `;
            }

            default: {
                return css``;
            }
        };
    }}
    }

    .userMenuContainer {
        display: flex;
        color: ${colors.darkGrey};
        cursor: pointer;

        .verticalDash {
            opacity: 0.6;
            color: ${colors.white};
            margin-right: 14px;
        }

        .blockMenu {
            display: flex;
            align-items: center;
            height: 100%;

            .userName {
                margin-right: 5px;
                margin-left: 10px;
            }

            i {
                height: 15px;
                width: 15px;
                font-size: 15px;
            }

            &:hover {

                .arrowMenu {
                    transition-property: transform;
                    transition-duration: 200ms;
                    transition-timing-function: ease;
                    transform: rotate(270deg);
                }
            }
        }

        &:hover {
            color: #2FB3BE;

            span {
                text-decoration-line: underline;

            }
        }
    }

    .ressourcesContainer{
        display: flex;
        align-items: center;

        i {
            transition-property: transform;
            transition-duration: 200ms;
            transition-timing-function: ease;
            height: 10px;
            margin-left: 5px;
        }

        &:hover {
            i {
                transform: rotate(270deg);
            }
        }
    }


    .connexionButton, .ressourcesContainer, .userMenuContainer {
        position: relative;

        &:hover {
            .subMenuConnexion, .subMenuRessources, .subUserMenu {
                max-height: initial;
                opacity: 1;
                visibility: visible;
                z-index: 99;
                margin-top: 0;
            }
        }

        .subUserMenu {

            .subMenuContainer {
                position: relative;
                margin: 5px;
                overflow: hidden;

            }

            ul {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }

            .fixedLink {

            }

            ul {
                .dynamicLink{
                    border-left: 1px solid ${colors.grey};
                    height: min-content;
                }
            }

            &.tripleColumn {
                width: 750px;

                ul {

                    grid-template-columns: 1fr 1fr 1fr;

                    .dynamicLink{
                        border-left: 1px solid ${colors.grey};
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-column-start: 2;
                        grid-column-end: end;
                        grid-row-start: 1;
                        grid-row-end: span 5;
                        height: min-content
                    }
                }

                .ManageForfaitBlock {
                    grid-template-columns: 1fr 1fr 1fr;

                    .forfaitList {
                        grid-template-columns: 1fr 1fr!important;
                        position: unset;
                        gap: 30px!important;
                        height: min-content;
                        font-size: ${fontSizes.large}px;
                    }

                    .textManageForfait {
                        padding-right: 30px;
                    }
                }
            }
        }
    }

    .connexionButton {
        &:hover {
            .headerArrowButton {
                background-color: ${colors.main};
                color: ${colors.white};
                border-color: ${colors.main};

                .arrowMenu {
                    transform: rotate(270deg);
                }
            }
        }
    }

    .imgProfil {
        height: 36px;
        width: 36px;
        border-radius: 50%;
    }

    #subMenuUserContainerToSlide {
        width: 200%;
        display: flex;
        transition: transform 0.5s ease;

        & > ul {
            width: 50%;
        }

        &.wentToSeeManageForfaitBlock {
            transform: translate(-50%);
        }
    }
`;

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const Header = ({ isScrollingUp, fixed = false, mode = 'classic' }) => {

    // On recupère la fonction de traduction
    const { t } = useTranslation('translation');

    const [menuUser, setUserMenu] = useState(null);

    const { setUser } = useContext(UserContext);

    // State pour l'ouverture du menu mobile
    const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

    // State pour la taille de l'écran
    const [windowSize, setWindowSize] = useState(getWindowSize());

    // Permet d'aller chercher le menu de l'utilisateur connecté
    const getUserMenu = async () => {
        // On va chercher le menu avec ajax
        const rep = await fetch(`${neonomadeUrl}/inc/ajax/landings/getUserMenu.php`, { method: 'GET' });
        const response = await rep.json();

        // Si on a bien un retour valide
        return !!response.status ? response.data : null;
    }

    useEffect(() => {
        document.body.style.overflow = mobileMenuOpened ? 'hidden' : null;
    }, [mobileMenuOpened])

    useEffect(() => {
        const handleWindowResize = () => setWindowSize(getWindowSize());
        window.addEventListener('resize', handleWindowResize);

        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    // On recupère le menu dans le local storage ou via ajax
    useEffect(() => {
        // On va chercher le menu avec une requete ajax
        navigator.userAgent !== "ReactSnap" && getUserMenu().then(result => {
            // Si on a un menu
            if (result) {
                setUserMenu(result);

                setUser(result.user);
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {mobileMenuOpened && <MenuOverlay />}
            <HeaderContainer mode={mode} fixed={fixed} className={`${isScrollingUp || mobileMenuOpened ? 'stickyHeader' : ''}`}>
                <Link to="/">
                    <img src={blueLogo} className="blueLogo" onClick={() => ClickEventGA4('clic-menu', 'clic-menu-logo')} alt={t('logoOf', { company: 'Neo-nomade' })} width="200" height="40" />
                    <img src={whiteLogo} className="whiteLogo" onClick={() => ClickEventGA4('clic-menu', 'clic-menu-logo')} alt={t('logoOf', { company: 'Neo-nomade' })} width="200" height="40" />
                </Link>

                {windowSize.innerWidth <= breakpoints.large.split('px').splice(0, 1) ? (
                    <>
                        {mobileMenuOpened && <MobileMenu menu={menu} menuUser={menuUser} setUserMenu={setUserMenu} />}
                        <OnlyIconButton onClick={() => setMobileMenuOpened(!mobileMenuOpened)} alt={t('header.altMenu')} icon="front-menu-1" />
                    </>
                ) : (
                    <DesktopMenu menu={menu} menuUser={menuUser} setUserMenu={setUserMenu} />
                )}
            </HeaderContainer>
        </>
    );
}

export default Header;