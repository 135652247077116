import styled from 'styled-components';

const NnTabsContainer = styled.div`
display: flex;
align-items: start;
overflow-x: auto;

label {
    margin: 0;
    display: inline-block;
    cursor: pointer;

    input[type="radio"] {
        display: none;
    }

    & > span {
        font-weight:300;
        transition-timing-function: ease-out;
        display: inline-block;
        color: #5B5B5B;
        font-size: 20px;
        padding: 11px 15px 12px;
        border-bottom: solid 1px #5B5B5B;
        white-space: nowrap;
        text-decoration: none;
        transition-property: color;
        transition-duration: 200ms;
    }

    input[type="radio"]:not(:disabled){
        &:checked + span, & + span:hover {
            font-weight:600;
            color: #60D0DA;
            border-bottom: solid 3px #60D0DA;
            padding: 11px 15px;
        }

        &:checked + span {
            color: #2FB3BE;
            border-bottom: solid 3px #2FB3BE;
        }
    } 
}
`

const NnTabs = ({tabs, defaultTabClick, activeTab}) => (	
    <NnTabsContainer>
        {tabs.map((tab,i) => (
            <label key={i}>
                <input type="radio" checked={i===activeTab} name="cguTab" onChange={() => defaultTabClick(i)} />
                <span>{tab.title}</span>
            </label>
        ))}
    </NnTabsContainer>
)

export default NnTabs;