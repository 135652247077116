import styled from 'styled-components';
import { useState } from 'react';
import SubMenu from './SubMenu';
import { ClickEvent } from '../hook/Tracking';
import Icon from '../ui/Icon';
import { Link } from "react-router-dom";
import { formateInternalUri } from '../hook/FormateInternalUri';
import ManageForfaitBlock from './ManageForfaitBlock';
import React from 'react';
import { neonomadeUrl } from '../../datas/commonLinks';

const MobileMenuContainer = styled.div`
    position: fixed;
    top: 0;
    background-color: #fff;
    width: 100%;
    margin-top: 73px;
    left: 0;
    display: flex;
    flex-direction: column;
    box-shadow: 0 1rem 1rem 0.7rem rgb(142 157 175 / 20%);
    max-height: calc(100vh - 60px);
    overflow-y: auto;
    padding: 0 15px;

    .menu-item {

        &:not(:last-child) {
            border-bottom: .1rem solid #c4c4c4;
        }

        padding: 20px 0;
        text-decoration: none;
        cursor: pointer;
        color: #5b5b5b;
        font-weight: 300;
        position: relative;
        overflow-x: hidden;
        min-height: 65px;

        .subMenuUserContainerToShow {
            position: relative;
            
        }

        .menu-item-title {
            display: flex;
            justify-content: space-between;
            width: 100%;
            overflow: hidden;

            i {
                align-items: center;
                transition-property: transform;
                transition-duration: 200ms;
                transition-timing-function: ease;
            }

            &.openedMenu {
                color: #2fb3be;
                font-weight:400;
            }

            &.userMenu {
                img {
                    margin-right: 15px;
                }
            }
        }

        .profilBlock {
            display: flex;
            align-items: center;
        }

    }
`;

const Menu = ({ menu, withMenuToConnect}) => {

    // Si on ne veut pas le menu pour se connecter, on le retire
    const menuToLoad = withMenuToConnect ? menu : menu.slice(0, -1);

    return (
        <>
            {menuToLoad.map((item, index) => (
            <React.Fragment key={`connectedBlockMenuMobileUser${index}`}>
                {item.type === 'link' && ! item.isInternal && (
                    <MobileLinkMenu key={`connectedMenuMobile${index}`} title={item.title} link={item.url} gaEventName={item.gaEventName} />
                )}

                {item.type === 'link' && !! item.isInternal && (
                    <Link key={`connectedMenuMobile${index}`} className='menu-item' to={formateInternalUri(item.url)} onClick={() => ClickEvent('Clic', item.gaEventName)}>
                        {item.title}
                    </Link>
                )}

                {item.type === 'submenu' && (
                    <FoldableMenu key={`connectedMenuMobile${index}`} title={item.title} menuList={item.subMenu} />
                )}
            </React.Fragment>
            ))}
        </>
    )
}

const MobileMenu = ({menu, menuUser = null, setUserMenu = null}) => (
    <MobileMenuContainer>
        { menuUser != null ? <MobileMenuConnected  menu={menu} menuUser={menuUser} setUserMenu={setUserMenu} /> : <MobileMenuOffline menu={menu} /> }
    </MobileMenuContainer>
)

const MobileMenuConnected = ({menu, menuUser, setUserMenu}) => (
    <MobileMenuContainer>
        <Menu menu={menu} withMenuToConnect={false}/>
        <FoldableConnectedMenu key={`connectedBlockMenuMobileUser`} menuUser={menuUser} setUserMenu={setUserMenu}/>
    </MobileMenuContainer>
)

const MobileMenuOffline = ({menu}) => (
    <MobileMenuContainer>
        <Menu menu={menu} withMenuToConnect={true} />
    </MobileMenuContainer>
)


const FoldableMenu = ({title, menuList}) => {
    const [open, setOpen] = useState(false);

    return (
            <div className='menu-item' onClick={() => setOpen(!open)}>
                <div className={`menu-item-title ${open ? 'openedMenu' : ''}`} datatypeclick="clic-entête-solutions">
                    <span>{title}</span>
                    <Icon icon="arrow-slider-right" orientation={open ? 'top' : 'bottom'} />
                </div>
                {!! open && (
                <SubMenu className='subMenuRessources' menuList={menuList} mobileView={true}/>
            )}
            </div>

    );
}


const FoldableConnectedMenu = ({menuUser, setUserMenu}) => {
    const [open, setOpen] = useState(false);
    const needManageForfaitBlock = menuUser.forfaits.length > 1;

    return (
            <div className='menu-item' onClick={() => setOpen(!open)}>
                <div className={`menu-item-title userMenu ${open ? 'openedMenu' : ''}`} datatypeclick="clic-entête-solutions">
                    <div className='profilBlock'>
                        <img src={`${neonomadeUrl}/profilPicture/${menuUser.user.id}=72x72xcarre`} alt="profil" className='imgProfil' />
                        <span>{`${menuUser.user.firstname} ${menuUser.user.lastname}`}</span>
                    </div>
                    <Icon icon="arrow-slider-right" orientation={open ? 'top' : 'bottom'} />
                </div>
                {!! open && (
                    <>
                    <div className="subMenuUserContainerToShow">
                        <div id="subMenuUserContainerToSlide">
                            <SubMenu className='subMenuRessources' menuList={menuUser.menu} connected={true} needManageForfaitBlock={needManageForfaitBlock} access={menuUser.access} mobileView={true}/>
                            { needManageForfaitBlock && <ManageForfaitBlock menuUser={menuUser} setUserMenu={setUserMenu} mobileView={true} /> }
                        </div>
                    </div>   
                    </>
                )}
            </div>
    );
}

const MobileLinkMenu = ({title, link, gaEventName}) => (
    <a  className='menu-item'
        href={link} 
        onClick={() => ClickEvent('Clic', {gaEventName})}
    >{title}</a>
)

export default MobileMenu;