import styled from 'styled-components';
import { colors, layout, fontSizes } from '../../constants';
import Icon from '../Icon';

const ArrowButtonContainer = styled.button`
    display: flex;
    align-items: center;
    padding: 0px 30px;
    height: 40px;
    background-color: transparent;
    border-radius: 8px;
    border: solid 1px ${colors.main};
    color: ${colors.main};
    transition-property: background-color, color, border-color;
    transition-duration: 200ms;
    font-size: ${fontSizes.medium}px;

    i {
        margin-bottom: 2px;
        transition-property: transform;
        transition-duration: 200ms;
        transition-timing-function: ease;
    }

    &:hover {
        background-color: ${colors.main};
        color: ${colors.white};

        i {
            transform: rotate(270deg);
        }
    }

    & > span {
        margin-right: ${layout.verySmall}px;
    }
`;

const ArrowButton = ({ className, children }) => {
    return (
        <ArrowButtonContainer className={className}>
            <span>{children}</span>
            <Icon icon="arrow-slider-right" size="smaller" orientation="bottom" gap="2" />
        </ArrowButtonContainer>
    );
}

export default ArrowButton;