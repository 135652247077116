import styled from 'styled-components';
import { colors, fontSizes } from '../constants';

const LinkContainer = styled.a`
    text-decoration: none;
    font-size: ${fontSizes.large}px;
    color: ${colors.main};
    line-height: 25px;
    border-bottom: 1px solid ${colors.main};
    transition-property: color, border;
    transition-duration: 200ms;
    cursor: pointer;

    &:hover {
        color: ${colors.darkGrey};
        border-color: ${colors.darkGrey};
    }
`;

const NNLink = ({ href, className, onClick, children }) => {
    return (
        <LinkContainer href={href} className={className} onClick={onClick}>
            <span>{children}</span>
        </LinkContainer>
    );
}

export default NNLink;