import styled from 'styled-components';
import Icon from '../ui/Icon';
import { colors, fontSizes, layout, weight } from '../constants';

const NotionImg = styled.img`
    margin: auto;
    display: block;
    max-width: 100%;
`

const NotionHr = styled.hr`
    height: 1px;
    border: 0;
    background-color: #C6E5E8;
    margin: 40px 0;
`

const NotionLi = styled.li`
    display: flex;
    margin-top: ${layout.small}px;
    margin-bottom: ${layout.small}px;
    align-items: baseline;
    white-space: pre-wrap;

    & > i {
        border: solid 1px ${colors.darkGrey2};
        border-radius: 6px;
        min-width: 18px;
        height: 18px;
        font-size: 10px;
        align-items: center;
        justify-content: center;
    }

    & > div {
        margin-left: ${layout.small}px;
        font-size: ${fontSizes.large}px;
        font-weight: ${weight.light};
    }
`

const NotionH3 = styled.h3`
    color: #2FB3BE;
    font-size: 20px;
    font-weight: 500;
`
    
const NotionH1 = styled.h1`
    font-size: 30px;
    color: #4D4D4D;
    margin: 20px 0 30px 0;
    font-weight: 500;
    line-height: 120%;
`

const NotionParagraph = styled.p`
    font-size: 16px;
    font-weight: 300;
    white-space: pre-wrap;

    a {
        color: ${colors.main};
    }
`

/**
 * Formate un texte en html
 * @param {object} richText 
 * @returns {string} texte formaté en html
 */
const formatText = (richText, i) => {
    // On récupère l'objet anotation
    const annotations = richText.annotations;

    // On transforme les /n en <br/>
    let formatedContent = richText.text.content;

    // Suivant le format on ajoute les balises correspondantes
    formatedContent = annotations.bold ? (<b key={`notionFormat${i}`}>{formatedContent}</b>) : formatedContent;
    formatedContent = annotations.italic ? (<i key={`notionFormat${i}`}>{formatedContent}</i>) : formatedContent;
    formatedContent = annotations.underline ? (<u key={`notionFormat${i}`}>{formatedContent}</u>) : formatedContent;
    formatedContent = annotations.strikethrough ? (<s key={`notionFormat${i}`}>{formatedContent}</s>) : formatedContent;
    formatedContent = annotations.code ? (<code key={`notionFormat${i}`}>{formatedContent}</code>) : formatedContent;

    // Si on a un lien on l'ajoute
    formatedContent = richText.href ? (<a href={richText.href} target="_blank" rel="noreferrer">{formatedContent}</a>) : formatedContent;

    // On retourne le texte formaté
    return formatedContent;
} 


const NotionBlock = ({notionData}) => notionData.results.map((block, i) => {
    // On formate le texte en html
    const content = block[block.type].hasOwnProperty('rich_text') ? block[block.type].rich_text.map((richText,i) => formatText(richText,i)) : '';

    // En fonction du type on encadre des balises correspondantes
    if (block.type === 'paragraph') {
        return (<NotionParagraph key={`notionBlock${i}`}>{content}</NotionParagraph>)
    }
    if (block.type === 'heading_1') {
        return (<NotionH1 key={`notionBlock${i}`}>{content}</NotionH1>)
    }
    if (block.type === 'heading_2') {
        return (<h2 key={`notionBlock${i}`}>{content}</h2>)
    }
    if (block.type === 'heading_3') {
        return (<NotionH3 key={`notionBlock${i}`}>{content}</NotionH3>)
    }
    if (block.type === 'bulleted_list_item') {
        return (<NotionLi key={`notionBlock${i}`}><Icon icon="check" /> <div>{content}</div></NotionLi>)
    }
    if (block.type === 'divider') {
        return (<NotionHr key={`notionBlock${i}`}/>)
    }
    if (block.type === 'image') {
        return (<NotionImg key={`notionBlock${i}`} alt={block.id} src={block.image.external.url} />)
    }
    return (<p key={`notionBlock${i}`}>{block.type}</p>)
});

export default NotionBlock;