import styled from 'styled-components';
import { Trans } from 'react-i18next';
import { colors, fontSizes, layout, weight, breakpoints } from '../constants';
import { trustOpinions } from '../../datas/blocks';

const TrustOpinionsContainer = styled.div`
    padding: 0 ${layout.huge}px 80px ${layout.huge}px;

    @media (min-width: ${breakpoints.medium}) {
        padding: 70px 135px 150px 135px;
    }

    @media (min-width: ${breakpoints.ultraLarge}) {
        padding-top: 150px;
    }

    h2 {
        font-weight: ${weight.regular};
        color: ${colors.darkGrey};
        text-align: center;
        margin-bottom: 50px;
        margin-top: 0;
        font-size: 30px;

        @media (min-width: ${breakpoints.medium}) {
            font-size: 50px;
            margin-bottom: 80px;

            br {
                display: none;
            }
        }
    }

    & > div {
        display: flex;
        flex-direction: column;

        @media (min-width: ${breakpoints.large}) {
            flex-direction: row;
            flex-wrap: wrap;

            & > div {
                width: calc(50% - ${layout.large}px);
                margin-right: ${layout.large}px;
            }
        }

        @media (min-width: ${breakpoints.extraLarge}) {
            flex-wrap: nowrap;
        }
    }
`;

const TrustOpinion = styled.div`
    border: solid 1px #C4C4C4;
    border-radius: 8px;
    padding: ${layout.huge}px;
    margin-bottom: ${layout.veryLarge}px;

    @media (min-width: ${breakpoints.medium}) {
        margin-bottom: ${layout.large}px;
    }

    & > div {
        display: flex;

        & > img {
            margin-right: ${layout.large}px;
            width: 60px;
            height: 60px;
            border-radius: 6px;
            object-fit: cover;
        }

        & > div {
            & > h3 {
                font-size: 20px;
                line-height: 24px;
                font-weight: ${weight.regular};
                margin: 0;
                color: ${colors.darkGrey};
            }

            & > p {
                margin: 0;

                &:first-of-type {
                    color: #909090;
                    font-style: italic;
                    font-size: ${fontSizes.medium}px;
                    line-height: 16.8px;
                }

                &:last-of-type {
                    color: ${colors.main};
                    font-size: ${fontSizes.large}px;
                    line-height: 19.2px;
                }
            }
        }
    }

    & > p {
        font-style: italic;
        font-weight: ${weight.light};
        font-size: ${fontSizes.large}px;
        color: ${colors.darkGrey};
        line-height: 19.2px;
        margin-bottom: 0;
        margin-top: ${layout.large}px;
    }
`;

const chunk = (entryArray, columnCount) => {
    // On calcule le nombre d'element maxi par colonnes pour avoir columnCount colonnes
    const chunkSize = Math.ceil(entryArray.length / columnCount);

    // On décompose notre tableau en x tableau de chunkSize elements
    return [...Array(Math.ceil(entryArray.length / chunkSize))].map(_ => entryArray.splice(0, chunkSize));
}

const groupedTrustOpinions = chunk([...trustOpinions], 3);


const TrustOpinionsBlock = () => {
    return (
        <TrustOpinionsContainer>
            <h2>
                <Trans i18nKey="trustOpinions.TheyTrustUs">
                </Trans>
            </h2>

            <div>
                {groupedTrustOpinions.map((group, index) => (
                    <div key={`group-${index}`}>
                        {group.map((data, index) => (
                            <TrustOpinion key={`opinion-${index}`}>
                                <div>
                                    <img src={data.picture} alt={data.fullName} width="60" height="60" />

                                    <div>
                                        <h3>{data.fullName}</h3>
                                        <p>{data.job}</p>
                                        <p>@{data.client}</p>
                                    </div>
                                </div>

                                <p>
                                    ”{data.opinion}”
                                </p>
                            </TrustOpinion>
                        ))}
                    </div>
                ))}
            </div>
        </TrustOpinionsContainer>
    );
}

export default TrustOpinionsBlock;