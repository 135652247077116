import styled from 'styled-components';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { colors, fontSizes } from '../constants';


const PaginationContainer = styled.div`
    width:100%;
    text-align: center;
    margin-top: 40px;

    display: flex;
    justify-content: center;

    & > * {
        font-size: ${fontSizes.medium}px;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1px #d6d6d6;
        margin-left: -1px;
        color: #737373;
        padding: 4px 0;
        cursor: pointer;
        text-decoration: none;
        background-color: #FFF;

        &:hover, &.active{
            border-color: ${colors.darkGrey2};
            color: ${colors.darkGrey2};
            z-index: 0;
        }

        &:first-child {
            border-bottom-left-radius: 3px;
            border-top-left-radius: 3px;
        }

        &:last-child {
            border-bottom-right-radius: 3px;
            border-top-right-radius: 3px;
        }
    }
`

const Pagination = ({page, pageCount}) => {
    const location = useLocation();
    const baseUri = location.pathname.split('/page').shift();
    const [searchParams] = useSearchParams();

    const getPageUri = (pageNb) => `${baseUri}/page/${pageNb}?${searchParams.toString()}`;

    return pageCount > 1 ? (
        <PaginationContainer className='lato'>
            {page > 1 && <Link to={getPageUri(page - 1)}>&lt;</Link>}

            {page > 3 && (<Link to={getPageUri(1)}>1</Link>)}
            {page > 4 && (<span>...</span>)}

            {Array(5).fill().map((_, i) => {
                const p = i + (page - 2 > 0 ? page - 2 : 1);
                return p > pageCount ? '' : p === page ? (<span className="active latoBold" key={`page${p}`}>{p}</span>) : (<Link key={`page${p}`} to={getPageUri(p)}>{p}</Link>);
            })}
            
            {page < pageCount - 2 && (<><span>...</span><Link to={getPageUri(pageCount)}>{pageCount}</Link></>)}

            {page < pageCount && <Link to={getPageUri(page + 1)}>&gt;</Link>}
        </PaginationContainer>
    ) : null
};

export default Pagination;