import Header from '../components/structure/Header';
import Meta from "../components/structure/Meta"
import RenderHeaderSticky from '../components/hook/RenderHeaderSticky';
import Footer from '../components/structure/Footer';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { breakpoints } from '../components/constants';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import NotionBlock from '../components/block/NotionBlock';
import { fetchNotionData } from '../utils/notionUtils';


const PageContent = styled.div`
    padding: 80px 30px 0;

    @media (min-width: ${breakpoints.small}) {
        padding: 100px 120px 20px;
        ${props => props.smallwidth && css`
        max-width:800px;
        margin:auto;
        `}
    }
`


const NotionPage = ({title, description, pageId=null, smallwidth=false}) => {
	const [notionData, setNotionData] = useState(null);
    const { t } = useTranslation('translation');
    const { notionPageId } = useParams();

    // Récupération des données notion et enregistrement dans le state notionData au changement du pageId
    useEffect(() => {
        // On enlève les data pour afficher le chargement
        setNotionData(null);

        // Si on est pas sur reactsnap ou si on est en production on appelle la fonction de récupération des données
        if (navigator.userAgent !== "ReactSnap" || process.env.REACT_APP_ENV === 'production') {
            // Fonction de récupération des données
            (async () => {
                const data = await fetchNotionData(pageId ?? notionPageId);
                setNotionData(data);
            })()
        }
    }, [pageId,notionPageId]);

    // On appelle le hook pour rendre le header sticky + ajouter un margin au block 1
    const isScrollingUp = RenderHeaderSticky();
    const location = useLocation();

    return (
        <>
            <Meta 
                title={`${notionData?.title ?? title} - Neo-nomade`}
                description = {description}
                url={location.pathname}
            />
            <Header isScrollingUp={isScrollingUp} fixed={true} />

            <PageContent smallwidth={smallwidth}>
                {! notionData && (<p>{t('loading')}...</p>)}
                {notionData && <>
                    <h1>{notionData?.title ?? title}</h1>
                    <NotionBlock notionData={notionData['content'] ?? notionData} />
                </>}
            </PageContent>

            <Footer />
        </>
    );
}

export default NotionPage;