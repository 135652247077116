import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchTags } from '../utils/blogUtils';
import ErrorPage from '../pages/ErrorPage';
import ContentLoader from 'react-content-loader';
import BlogSummary from './BlogSummary';

const BlogTagFromSlug = () => {
    const params = useParams();
    const [tag, setTag] = useState(null);

    useEffect(() => {
        (async () => {
            const tagsData = await fetchTags({slug:params.tagslug});
            setTag(tagsData.length === 1 ? tagsData.shift() : false);
        })();
    }, [params.tagslug]);

    return tag ? (
        <BlogSummary tag={tag} />
    ) : (
        <>{tag === false && <ErrorPage errorCode={4} />}
        {tag !== false && <ContentLoader viewBox="0 0 380 240">
            <rect x="5"   y="5"   rx="2" ry="2" width="370" height="125" />
            <rect x="5"   y="140" rx="3" ry="3" width="180" height="100"/>
            <rect x="195" y="140" rx="2" ry="2" width="180" height="100" />                 
        </ContentLoader>}
        </>
    );

    
}

export default BlogTagFromSlug;