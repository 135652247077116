import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';
import './plugin/i18next/i18next';

// Si on est en production et pas sur le snapshot
if (process.env.REACT_APP_ENV === 'production' && navigator.userAgent !== "ReactSnap") {
  // On charge google tags manager
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID
  });
}

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrateRoot(rootElement, <App />);
} else {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <App />
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
