import styled from 'styled-components';
import SubMenu from './SubMenu';
import ManageForfaitBlock from './ManageForfaitBlock'

const SubMenuContainer = styled.div`
    position: absolute;
    right: 0;
    top: 100%;
    width: 500px;
    padding-top:25px;
    max-height: 550px;
    transition-property: opacity, margin-top;
    transition: .2s ease;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    margin-top: 5px;

    &.subMenuRessources {
        width: 230px;
    }

    &.subMenuConnexion {
        width: 280px;
    }

    & > div {
        background: white;
        border-radius: 12px;
        box-shadow: 0 2px 7px 0 rgb(142 157 175 / 35%);
        padding: 5px 10px;
        
    }
`;

const DesktopSubMenu = ({ className, menuList, menuUser = null, setUserMenu = null}) => {

    let connected = false;
    let needManageForfaitBlock = false;
    let access = null;
    let menu = menuList;

    // Si on a un forfait (si on a un menu utilisateur)
    if (menuUser) {
        
        connected = true;
        access = menuUser.access;
        menu = menuUser.menu;

        // Si on a plus de 2 forfait
        if (menuUser.forfaits.length > 0) {
            needManageForfaitBlock = menuUser.forfaits.length > 1;
        }
    }

    return (
        <SubMenuContainer className={className}>
            <div className='subMenuContainer'>
                
                {!connected && (
                    <SubMenu menuList={menu} connected={connected} needManageForfaitBlock={needManageForfaitBlock} access={access} />
                )}
                
                {/* Si on veut le menu utilisateur */}
                {connected && (
                    <div id='subMenuUserContainerToSlide' className='connected'>
                        <SubMenu menuList={menu} connected={connected} needManageForfaitBlock={needManageForfaitBlock} access={access} />
                        
                        {/* Si on a des forfaits ET qu'il y en a plus d'un */}
                        {needManageForfaitBlock && <ManageForfaitBlock menuUser={menuUser} setUserMenu={setUserMenu}/>}

                    </div>
                )}
            </div>

           
        </SubMenuContainer>
    );
}

export default DesktopSubMenu;