import styled from 'styled-components';
import { colors } from '../../constants';
import Icon from '../Icon';

const OnlyIconButtonContainer = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.main};
    border-radius: 6px;
    color: ${colors.white};
    outline: none;
    border: solid 1px ${colors.main};
    width: 33px;
    height: 33px;
    cursor: pointer;
`;

const OnlyIconButton = ({ className, icon, alt, onClick }) => {
    return (
        <OnlyIconButtonContainer onClick={onClick} aria-label={alt} className={className}>
            <Icon icon={icon} />
        </OnlyIconButtonContainer>
    );
}

export default OnlyIconButton;