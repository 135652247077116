import styled from 'styled-components';
import { colors, fontSizes, weight } from '../../constants';

const InputTextContainer = styled.input`
    transition-property: border;
    transition-duration: 200ms;
    height: 40px;
    border: 1px solid ${colors.darkGrey};
    border-radius: 6px;
    font-size: ${fontSizes.large}px;
    color: ${colors.darkGrey};
    padding: 15px 22px;

    &::-webkit-input-placeholder {
        opacity: 0.7;
        font-size: 16px;
        font-weight: ${weight.light};
    }

    &:focus {
        outline: none !important;
        border:1px solid ${colors.main};
    }
`;

const InputText = ({ className, placeHolder, inputId, onClick, children, onChange }) => {
    return (
        <InputTextContainer onChange={onChange} type='text' className={className} placeholder={placeHolder} onClick={onClick}>
            {children}
        </InputTextContainer>
    );
}

export default InputText;