import styled from 'styled-components';
import { colors, breakpoints, weight } from '../../components/constants';
import Link from '../../components/ui/Link';
import Icon from '../../components/ui/Icon';
import { ClickEventGA4 } from '../../components/hook/TrackingGA4';

// Style du conteneur contenu de bloc 6
const BlogArticleThumbnailContainer = styled.div`
    flex:1;
    max-width: 520px;
    padding: 2rem 0;

    & > img {
        width: 100%;
        height: 100%;
        max-height: 280px;
        border-top-left-radius: 45px;
        border-top-right-radius: 10px;
        object-fit: cover;
        background-color: ${colors.white};
        border: 1px solid ${colors.main};
        border-bottom: 0;
    }

    @media screen and (min-width: ${breakpoints.large}) {
        margin: 0 2rem;
    }

    .readArticleLink  span {
            display: flex;
            align-items: center;

            i {
                margin-top: 5px;
                margin-left: 5px;
            }

    }
`;

// Style du conteneur dans contenu de bloc 6
const BlogArticleThumbnailInsideContainer = styled.ul`
   background-color: ${colors.white};
   list-style: none;
   line-height: 22px;
   border: 1px solid ${colors.main};
   border-bottom-right-radius: 65px;
   border-bottom-left-radius: 10px;
   border-top: 0;
   margin-top: -0.3rem;
   padding: 0 0 1.5rem 0;
   font-weight: ${weight.medium};

   & > li {
        padding: 0.8rem 3rem 0 2.2rem;

        &:last-child {
            font-weight: ${weight.regular};
        }
   }

   @media screen and (min-width: ${breakpoints.medium}) and (max-width: ${breakpoints.large}) {
        & > li {
            padding: 0.8rem 2rem 0 1.5rem;
        }
    }

    @media screen and (min-width: ${breakpoints.large}) and (max-width: ${breakpoints.extraLarge}) {
        & > li {
            padding: 0.8rem 3.2rem 0 2rem;
        }
    }
`;

/**
 * Composant BlogArticle
 * @returns Contenu de BlogArticle
 */
const BlogArticleThumbnail = ({ data, index, showMoreTitle }) => (
    <BlogArticleThumbnailContainer key={data.title}>
        <img src={data.image} alt={data.title} width="520" height="310" />
        <BlogArticleThumbnailInsideContainer>
            {data.html ? (<li dangerouslySetInnerHTML={{ __html: data.text }} />) : (<li>{data.text}</li>)}
            <li>
                <Link href={data.href}
                    className='readArticleLink'
                    onClick={() => ClickEventGA4('clic-article', 'clic-article-lire-article')}>
                    {showMoreTitle} <Icon icon="arrow-slider-right" size='smaller' />
                </Link>
            </li>
        </BlogArticleThumbnailInsideContainer>
    </BlogArticleThumbnailContainer>
);

export default BlogArticleThumbnail;