import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getSizePic, htmlDecode, formatDate } from '../../utils/blogUtils';

const MainArticle = styled.article`
 
    grid-column-end: span 2;
    width: 100%;
    position: relative;
    height: 400px;
    margin: 15px 0px;

    img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        object-fit: cover;
    }
    .entry-content{
        z-index: 1;
        position: absolute;
        color: white;
        bottom: 0px;
        padding: 20px;
    }

    .img-overlay {
        inset: 0;
        background-image: linear-gradient(transparent 0%, rgb(0, 0, 0) 100%);
        position: absolute;
        border-radius: 5px;
    }
`

/**
 * Composant BlogArticle
 * @returns Contenu de BlogArticle
 */
 const MainBlogArticleThumbnail = ({post}) => (
    <MainArticle>
        <Link to={`/blog/${post.slug}`}>
        <img className="mainImage" alt={htmlDecode(post.title.rendered)} src={getSizePic(post)} width="508" height="220" />
        <div className="img-overlay" />
        <div className="entry-content">
            <h2>{htmlDecode(post.title.rendered)}</h2>
            <div> Par&nbsp;: {post._embedded.author[0].name} | {formatDate(post.date)}</div>
        </div>
        </Link>
    </MainArticle>
);

export default MainBlogArticleThumbnail;