import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors, breakpoints, weight } from '../constants';
import { useState } from 'react';
import { trustOpinions } from '../../datas/blocks';
import { useMemo } from 'react';
import TestimonyBlock from './TestimonyBlock';
import { ClickEventGA4 } from '../hook/TrackingGA4';
import InternalLinkAsButton from '../ui/buttons/InternalLinkAsButton';

const Block4Container = styled.div`
    background: linear-gradient(90.48deg, rgba(47, 179, 190, 0.05) 0.32%, rgba(6, 229, 213, 0.05) 99.58%);
    padding: 80px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${breakpoints.large}) {
        padding: 150px 60px;
    }

    h2 {
        color: ${colors.darkGrey};
        text-align: center;
        font-size: 30px;
        margin: 0;
        margin-bottom: 80px;
        font-weight: ${weight.medium};

        @media (min-width: ${breakpoints.large}) {
            font-size: 40px;
            max-width: 780px;
        }
    }

    & > div {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        @media (min-width: ${breakpoints.large}) {
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
        }
    }

    .ctaBlock4 {
        display: none;

        @media (min-width: ${breakpoints.large}) {
            display: flex;
            margin: 80px auto 0px auto;
        }
    }
`;

const Block4 = () => {

    // On recupère la fonction de traduction
    const { t } = useTranslation('translation', { keyPrefix: 'home.block4' });

    // On créé un state pour la position du témoignage courant
    const [currentPosition, setCurrentPosition] = useState(0);

    const [useAnimation, setUseAnimation] = useState(false);

    // Fonction qui change de témoignage (number +1 ou -1 par exemple)
    const changeCard = useMemo(() => (number) => {
        // On calcule la nouvelle position
        let newPosition = currentPosition + number;

        // Si on est inférieur à 0 alors on assigne la dernière position
        newPosition = newPosition < 0 ? trustOpinions.length - 1 : newPosition;

        // Si on est après la dernière position alors on assigne 0
        newPosition = newPosition === trustOpinions.length ? 0 : newPosition;

        // On set le state current position avec la position que l'on vient de calculer
        setCurrentPosition(newPosition);
        // setUseAnimation(false);
    }, [currentPosition, setCurrentPosition])

    return (
        <Block4Container>
            <h2>{t('h2')}</h2>

            <TestimonyBlock {...trustOpinions[currentPosition]} changeCard={changeCard} useAnimation={useAnimation} setUseAnimation={setUseAnimation} />

            <InternalLinkAsButton mode="cta" to="/coworking/contact-us?from=je-me-lance" className="ctaBlock4" onClick={() => ClickEventGA4('clic-CTA', 'clic-CTA-je-me-lance')}>
                {t('imGoingForIt')}
            </InternalLinkAsButton>

        </Block4Container>
    );
}

export default Block4;