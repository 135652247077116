import styled, { css } from 'styled-components';
import { useTranslation, Trans} from 'react-i18next';
import { gradients, colors, fontSizes, layout, weight, breakpoints } from '../constants';
import { formContactData, formContactEntrepriseSize, formContactJobs, clientsData } from '../../datas/blocks';
import Icon from "../ui/Icon";
import GreyInput from '../ui/inputs/GreyInput';
import Submit from '../ui/buttons/Submit';
import GreySelect from '../ui/selects/GreySelect';
import LogosCarousel from '../structure/LogosCarousel';
import { neonomadeUrl } from '../../datas/commonLinks';
import { useState } from 'react';
import { ClickEvent } from '../hook/Tracking';

const exceptionalBreakpoint = '1200px';

const FormContactContainer = styled.div`
    padding: 90px 30px 80px 30px;
    position: relative;

    @media (min-width: ${breakpoints.medium}) {
        padding: 90px 135px 80px 135px;
    }

    @media (min-width: ${exceptionalBreakpoint}) {
        padding: 90px 30px 80px 30px;
        justify-content: center;
        display: flex;
        height: 865px;
    }

    @media (min-width: ${breakpoints.ultraLarge}) {
        padding: 150px 135px 0px 135px;
        justify-content: space-around;
        height: 785px;
    }

    &:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        border-bottom-right-radius: 200px;
        background: linear-gradient(90deg, ${gradients.blueBackgroundGradient});
        height: 850px;
        width: 100%;

        @media (min-width: ${exceptionalBreakpoint}) {
            height: 690px;
        }
    }

    & > div {
        &:first-of-type {
            @media (min-width: ${exceptionalBreakpoint}) {
                padding-top: 80px;
                margin-right: 100px;
                max-width: 500px;
            }

            @media (min-width: ${breakpoints.ultraLarge}) {
                max-width: 500px;
                padding-top: 40px;
            }

            h1 {
                color: ${colors.white};
                margin-top: 0px;
                font-weight: ${weight.regular};
                font-size: 40px;

                @media (min-width: ${exceptionalBreakpoint}) {
                    font-size: 50px;
                }
            }
        }
    }
`;

const Argument = styled.div`
    color: ${colors.white};
    display: flex;
    margin-bottom: ${layout.large}px;

    & > i {
        border: solid 1px ${colors.white};
        border-radius: 6px;
        min-width: 20px;
        height: 20px;
        font-size: ${fontSizes.small}px;
        align-items: center;
        justify-content: center;
    }

    & > div {
        margin-left: ${layout.small}px;
        font-size: ${fontSizes.large}px;
        font-weight: ${weight.light};

        @media (min-width: ${exceptionalBreakpoint}) {
            font-size: ${fontSizes.veryLarge}px;
        }
    }
`;

const FormContainer = styled.div`
    background-color: ${colors.white};
    border-radius: 8px;
    padding: ${layout.huge}px;
    margin-top: 40px;
    box-shadow: 0px 8px 14px -8px rgba(0, 0, 0, 0.25);

    @media (min-width: ${exceptionalBreakpoint}) {
        padding: 40px;
        max-width: 630px;
    }

    @media (min-width: ${breakpoints.ultraLarge}) {
        margin-top: 0;
    }

    & > h2 {
        margin-top: 0px;
        color: ${colors.darkGrey};
        font-size: 20px;
        line-height: 20px;
        font-weight: ${weight.regular};
        margin-bottom: ${layout.huge}px;

        @media (min-width: ${exceptionalBreakpoint}) {
            margin-bottom: 45px;
        }

        & > span {
            display: block;
            width: fit-content;

            &:after {
                margin-top: ${layout.verySmall}px;
                content: '';
                display: block;
                height: 2px;
                width: 100%;
                background: linear-gradient(90deg, ${gradients.blueBackgroundGradient});
            }
        }
    }

    & > form {
        @media (min-width: ${breakpoints.medium}) {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
        }

        & > * {
            &:not(button) {
                margin-bottom: ${layout.medium}px;
            }
        }

        .formField {
            height: 45px;

            @media (min-width: ${breakpoints.medium}) {
                height: 55px;
                width: calc(50% - ${layout.huge / 2}px);

                &:nth-of-type(odd) {
                    margin-right: ${layout.huge / 2}px;
                }

                &:nth-of-type(even) {
                    margin-left: ${layout.huge / 2}px;
                }

                &:nth-child(5) {
                    width: 100%;
                    margin-right: 0;
                }

                &:not(:nth-child(n+6)) {
                    margin-bottom: ${layout.huge}px;
                }
            }
        }

        & > p {
            color: ${colors.darkGrey};

            &:first-of-type {
                font-size: ${fontSizes.small}px;
                line-height: 14.4px;
                width: 100%;

                @media (min-width: ${exceptionalBreakpoint}) {
                    margin-top: 0;
                }
            }

            &:nth-of-type(2) {
                color: rgba(0, 0, 0, .35);
                font-size: ${fontSizes.medium}px;
                margin: ${layout.medium}px 0 ${layout.huge}px 0;
                line-height: 16.8px;

                a {
                    color: rgba(0, 0, 0, .7);
                }
            }

            &:nth-of-type(3) {
                font-size: ${fontSizes.medium}px;
                flex: 1;
                margin: 0 0 ${layout.huge}px 0;

                @media (min-width: ${breakpoints.medium}) {
                    margin: 0 ${layout.small}px 0 0;
                    align-self: center;
                    text-align: right;
                }

                ${(props) => {
                    if (props.hasError) { 
                        return css`
                            color: ${colors.orange};
                        `;
                    } else {
                        return css`
                            color: ${colors.green};
                        `;
                    }
                }}
            }
        }
    }
`;

const LogoContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    margin-top: 35px;

    @media (min-width: ${exceptionalBreakpoint}) {
        justify-content: flex-start;
        position: absolute;
        left: 0;
        top: 690px;
        margin-top: 0;
        height: 95px;
        z-index: -1;
        width: 50%;
        background: linear-gradient(90deg, rgba(47, 179, 190, .1) 0.32%, rgba(6, 229, 213, .1) 99.58%);
    }

    @media (min-width: ${breakpoints.ultraLarge}) {
        width: 55%;
    }

    .slick-slider {
        width: 100%;

        .slick-track {
            display:flex;
            align-items: center;

            .slick-slide  {
                display: flex;
                align-items: center;
                justify-content: center;

                & > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        img {
            width: initial !important;
        }
    }

    img {
        filter: invert(55%);
        height: 21px;

        @media (min-width: ${exceptionalBreakpoint}) {
            margin-right: ${layout.huge}px;
            max-height: 25px;
            height: auto;
        }

        @media (min-width: ${breakpoints.ultraLarge}) {
            margin-right: 60px;
        }

        &.atosLogo1 {
            max-height: 19px;
        }

        &.allianzLogo1, &.IledeFranceLogo {
            max-height: 29px;
        }

        &.prestashopLogo {
            max-height: 68px;
        }
    }
`;

/**
 * Vérifie et envoie le formulaire
 * @param  Object    event        - Évenement lié à l'envoi du formulaire
 * @param  Object    data         - Données du formulaire
 * @param  Function  setHasError  - Garde dans les states du formulaire s'il est correct ou non
 * @param  Function  setFeedback  - Renseigne le message de retour à afficher
 * @param  Function  resetForm    - Réinitialise le formulaire
 * @param  Function  setIsLoading - Indique si on est en train de faire une action
 * @return Void
 */
const submitContactForm = (event, data, setHasError, setFeedback, resetForm, setIsLoading) => {
    event.preventDefault();

    // On affiche le loader
    setIsLoading(true);

    // On supprime un éventuel message d'erreur affiché
    setFeedback('');

    let isCorrect = true;

    // On prépare une liste de propriétés qui n'ont pas besoin d'être vérifiées
    const excludedProperties = ['from'];

    // Si toutes les données sont remplies
    for (const property in data) {
        // Si la propriété n'est pas exclue de vérification
        if (! excludedProperties.includes(property)) {
            // Si elle est vide
            if (data[property] === '') {
                // On indique que le formulaire n'est pas correct
                isCorrect = false;
                break;
            }
        }
    }

    // On garde l'information
    setHasError(! isCorrect);

    // Si tout est correct
    if (isCorrect) {
        const formData = new FormData();

        formData.append('firstName', data.firstName);
        formData.append('lastName', data.lastName);
        formData.append('email', data.email);
        formData.append('phone', data.phone);
        formData.append('company', data.company);
        formData.append('companySize', data.companySize);
        formData.append('job', data.job);
        formData.append('from', data.from);

        // Si on a un gclid
        if (!! localStorage.getItem('gclid'))
            formData.append('gclid', localStorage.getItem('gclid'));

        // On envoie le formulaire
        fetch(`${neonomadeUrl}/inc/ajax/landings/sendContactForm.php`, {
            method: 'POST',
            body: formData

        })
        .then(response => response.json())
        .then((data) => {
            setFeedback(data.message);

            // On masque le loader dans tous les cas
            setIsLoading(false);

            // Si tout s'est bien passé
            if (data.status) {
                // On envoi le fait que l'envoi a bien eu lieu dans analytics
                ClickEvent('Clic', `envoi-Demander-une-demo-page-pass`)

                // On réinitialise le formulaire
                resetForm();

                // On supprime le gclid du localStorage
                localStorage.removeItem('gclid');

            // Sinon
            } else {
                // On indique qu'on a une erreur
                setHasError(true);
            }
        });

    // Sinon 
    } else {
        setFeedback('Veuillez renseigner tous les champs.');

        // On masque le loader
        setIsLoading(false);
    }
}

const FormContactBlock = () => {
    
    // On recupère la fonction de traduction
    const { t } = useTranslation('translation', { keyPrefix: 'formContact' });

    // On récupère les paramètres de l'URL
    const searchParams = new URLSearchParams(window.location.search);

    // On récupère le paramètre "from" si on le trouve
    const from = searchParams.get('from') || '';

    const [hasError, setHasError] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [formState, setFormState] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',
        companySize: '',
        job: '',
        from,
    });

    // On crée une fonction de réinitialisation du formulaire
    const resetForm = () => setFormState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',
        companySize: '',
        job: '',
        from,
    });

    const carouselResponsive = [
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 3,
            }
        },

        {
            breakpoint: 535,
            settings: {
                slidesToShow: 2,
            }
        },
    ];

    return (
        <FormContactContainer>
            <div>
                <h1>{t('h1')}</h1>

                <div>
                    {formContactData.map((data, index) => (
                        <Argument key={index}>
                            <Icon icon="check" />
                            <div>
                                {data.text}
                            </div>
                        </Argument>
                    ))}
                </div>
            </div>

            <FormContainer hasError={hasError}>
                <h2>
                    <span>{t('askDemo')}</span>
                </h2>

                <form id="contactForm" onSubmit={(event) => { submitContactForm(event, formState, setHasError, setFeedback, resetForm, setIsLoading); ClickEvent('Clic', `clic-Demander-une-demo-page-pass`) }}>
                    <GreyInput name="firstName" placeholder={t('inputFirstName')} className="formField" required={true} value={formState.firstName} onChange={(event) => setFormState({...formState, firstName: event.target.value})} />
                    <GreyInput name="lastName" placeholder={t('inputLastName')} className="formField" required={true} value={formState.lastName} onChange={(event) => setFormState({...formState, lastName: event.target.value})} />
                    <GreyInput name="email" placeholder={t('inputLastEmail')} type="email" className="formField" required={true} pattern="^([\w_\.-]+)@([\w\.-]+)\.([a-z\.]+)$" value={formState.email} onChange={(event) => setFormState({...formState, email: event.target.value})} />
                    <GreyInput name="phone" placeholder={t('inputTel')} type="tel" className="formField" required={true} value={formState.phone} onChange={(event) => setFormState({...formState, phone: event.target.value})} />
                    <GreyInput name="company" placeholder={t('inputCompany')} className="formField" required={true} value={formState.company} onChange={(event) => setFormState({...formState, company: event.target.value})} />
                    <GreySelect name="companySize" placeholder={t('inputCompanySize')} options={formContactEntrepriseSize} className="formField" required={true} defaultValue={formState.companySize} onChange={(selected) => setFormState({...formState, companySize: selected.value})} />
                    <GreySelect name="job" placeholder={t('inputJob')} options={formContactJobs} className="formField" required={true} defaultValue={formState.job} onChange={(selected) => setFormState({...formState, job: selected.value})} />

                    <p>
                        {t('requiredFields')}
                    </p>

                    <p>
                        <Trans i18nKey="formContact.CGULink">  
                            Vous êtes à l'aise avec notre <a href={`${neonomadeUrl}/donnees-personnelles`} rel="noreferrer" target="_blank">politique de confidentialité</a> et avec le fait qu'on vous recontacte pour parler de nos services ? Super ! On peut continuer alors.
                        </Trans>
                    </p>

                    {feedback !== '' && (
                        <p>{feedback}</p>
                    )}

                    <Submit isLoading={isLoading}>{t('askDemoButton')}</Submit>
                </form>
            </FormContainer>

            <LogoContainer>
                <LogosCarousel logosDatas={clientsData} responsive={carouselResponsive} mode="default"></LogosCarousel>
            </LogoContainer>
        </FormContactContainer>
    );
}

export default FormContactBlock;