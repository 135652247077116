import { Link } from 'react-router-dom';
import { ClickEventGA4 } from '../hook/TrackingGA4';

const InternalOrExternalLink = ({ className, href, children, relatedPost, isExternalLink = false }) => {
    let eventItem = 'clic-article-lire-article';
    if (!!relatedPost)
        eventItem = 'clic-article-articles-similaires';

    if (isExternalLink) {
        return <a target="_blank" rel="noreferrer" href={href} className={className} onClick={() => ClickEventGA4('clic-article', `${eventItem}`)}>{children}</a>
    }
    return <Link to={href} className={className} onClick={() => ClickEventGA4('clic-article', `${eventItem}`)}>{children}</Link>
}

export default InternalOrExternalLink;