import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const GclidHandler = () => {
    const [searchParams] = useSearchParams();

	// Une fois que la page est prête
	useEffect(() => {
		// Si on a un gclid dans l'url
        if (!! searchParams.get('gclid')) {
            localStorage.setItem('gclid', searchParams.get('gclid'));
        }

	}, [searchParams]);

    return null;
}

export default GclidHandler;