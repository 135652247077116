import Header from '../components/structure/Header';
import Meta from "../components/structure/Meta"
import RenderHeaderSticky from '../components/hook/RenderHeaderSticky';
import Footer from '../components/structure/Footer';
import { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { breakpoints, colors } from '../components/constants';
import { useTranslation } from 'react-i18next';
import Icon from '../components/ui/Icon';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getSizePic, htmlDecode, formatDate, fetchPosts, fetchRelatedPosts, formatPostToThumbnail } from '../utils/blogUtils';
import ErrorPage from '../pages/ErrorPage';
import ContentLoader from 'react-content-loader'
import AlternativeBlogArticleThumbnail from '../components/block/AlternativeBlogArticleThumbnail';
import BlogMenu from './components/BlogMenu';
import { initGA4 } from '../components/hook/TrackingGA4';
import { UserContext } from '../utils/context';

const PageContent = styled.div`
color: ${colors.darkGrey2};
max-width: 1140px;
padding: 160px 30px;
margin: 0 auto;

img {
    max-width: 100%;
    height: auto;
}

blockquote {
    margin-left: 0;
    padding-left: 1em;
    border-left: 4px solid rgba(0,0,0,.15);
    font-style: italic;

    p {
        margin:0;
    }
}

a {
    color: ${colors.main};
}

p, ul, ol {
    font-size: 18px;
    font-weight: 300;
    /*white-space: pre-wrap;*/
}

h3 {
    color: #2FB3BE;
    font-size: 20px;
    font-weight: 500;
}

h1 {
    &.page-title {
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 30px;

        @media screen and (min-width: ${breakpoints.medium}) and (max-width: ${breakpoints.large}) {
            margin-bottom: 30px;
        }

        @media screen and (min-width: ${breakpoints.large}) {
            margin-bottom: 30px;
        }
    }

    font-size: 30px;
    font-weight: 700;
}


hr {
    height: 1px;
    border: 0;
    background-color: #C6E5E8;
    margin: 40px 0;
}

.authorBar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 30px 0;
    align-items: center;
}

.authorBlock {
    display:flex;
    font-size: 14px;
    color: #c4c4c4;
    font-weight: 399;
    img {
        border-radius: 50%;
        border: 1px solid #ffc820;
        margin: auto 10px;

    }
}

.articleBody {
    max-width: 760px;
    margin: 0 auto;
}

& > .mainImage {
    height: 360px;
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.returnLink {
    display: flex;
    align-items: center;
    color: #5B5B5B;
    cursor:pointer;
    i {
        margin-right:15px
    }
}

a[role="button"] {
    color: #FFF;
    background-color: #2fb3be;
    box-shadow: 0px 3px 10px 0px rgba(142,157,175,0.35);
    border-radius: 3px;
    padding: 10px 22px;
    text-decoration: none;
    font-weight: 450;
    text-transform: uppercase;
    display: inline-block;
    font-size: 16px;

    &:hover{
        background-color: #60D0DA;
    }

    br {
        display:none;
    }
}

.secondary-title {
    color: #2fb3be;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 30px;
}

figure {
    margin: 0;
    max-width: inherit;
}

.alignleft {
    float: left;
    margin-right: 1rem;
}

.relatedPosts {
    display:grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;


    @media (min-width: ${breakpoints.medium}) {
        grid-template-columns: 1fr 1fr;

    }

    @media (min-width: ${breakpoints.extraLarge}) {
        grid-template-columns: 1fr 1fr 1fr;

    }

}`

const TagListContainer = styled.div`
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    color: #8e9daf;
    max-width: 760px;
    margin: 60px auto;

    a {
        margin: 0 10px;
        color: #8e9daf;
        text-decoration: none;
        text-transform: uppercase;
    }
`



const TagList = ({ tagList }) => !!tagList && !!tagList.length && (
    <TagListContainer>
        <Icon size="biggest" icon="privatisation" />
        {tagList.map(t => (<Link key={t.slug} to={`/blog/tag/${t.slug}`}>{t.name}</Link>)).reduce((r, a) => r.concat(a, '/'), []).slice(0, -1)}
    </TagListContainer>
)

const RelatedPostsList = ({ relatedPosts }) => !!relatedPosts && !!relatedPosts.length && (
    <>
        <hr />
        <h3 className="secondary-title">Articles similaires</h3>
        <div className="relatedPosts">
            {relatedPosts.map(p => (
                <AlternativeBlogArticleThumbnail key={`post-${p.id}`} post={formatPostToThumbnail(p)} relatedPost={true} />
            ))}
        </div>
        <hr />
    </>
)

const BlogArticle = ({ category }) => {
    // On appelle le hook pour rendre le header sticky + ajouter un margin au block 1
    const isScrollingUp = RenderHeaderSticky();

    const params = useParams();

    const [post, setPost] = useState(null);
    const [relatedPosts, setRelatedPost] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [isError, setError] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation('translation');
    const author = post && post._embedded.author.length ? post._embedded.author[0] : null;

    useEffect(() => {
        setLoading(true);
        //window.scrollTo({ top: 0, behavior: 'smooth' })

        const fetchData = async () => {
            const postsData = await fetchPosts({
                slug: params.slug,
            });
            const containsUniqueArticle = postsData.postList.length === 1;
            setError(!postsData.status || !containsUniqueArticle);
            setPost(containsUniqueArticle ? postsData.postList[0] : null);
            setLoading(false);
        }
        fetchData();
    }, [params]);

    useEffect(() => {
        const fetchData = async () => {
            const relatedPostsData = await fetchRelatedPosts(post.id);
            setRelatedPost(relatedPostsData);
        }
        !!post && fetchData();
    }, [post]);

    const {user} = useContext(UserContext);

    // Une fois que la page est prête
    useEffect(() => {

        if (navigator.userAgent !== "ReactSnap") {
            let forfaitType = 'forfait-grand-public';

            if (user && user.currentForfaitId !== 37) {
                forfaitType = 'forfait-grand-compte';
            }

            const gaOptions = {
                ...(user && { 'user-id': user.id }),
                'titre-page': 'Bureaux et coworking pour vos salariés',
                'content_type': 'article-blog',
                'type-connecte': (user) ? 'Oui' : 'Non',
                'type-forfait': forfaitType
            }

            initGA4('G-YKDRHSWN0X', gaOptions);
        }
    }, [user]);


    // Si on a une erreur on retourne la page d'erreur sinon on retourne l'article correctement formaté
    return isError ? (
        <ErrorPage errorCode={4} />
    ) : (
        <>
            {post && (
                <Meta
                    title={htmlDecode(post.title.rendered)}
                    description={htmlDecode(post.excerpt.rendered)}
                    url={`/blog/${post.slug}`}
                    imageUri={getSizePic(post)}
                />
            )}
            <Header isScrollingUp={isScrollingUp} fixed={true} />
            <BlogMenu />

            <PageContent>
                {isLoading && (<ContentLoader viewBox="0 0 380 300">
                    <rect x="0" y="5" rx="4" ry="4" width="370" height="10" />
                    <rect x="60" y="20" rx="3" ry="3" width="250" height="10" />
                    <rect x="0" y="40" rx="5" ry="5" width="380" height="120" />

                    <rect x="0" y="178" rx="4" ry="4" width="50" height="8" />
                    <circle cx="310" cy="182" r="10" />
                    <rect x="330" y="175" rx="4" ry="4" width="50" height="5" />
                    <rect x="330" y="185" rx="4" ry="4" width="50" height="5" />

                    <rect x="0" y="200" rx="3" ry="3" width="380" height="8" />
                    <rect x="0" y="210" rx="3" ry="3" width="380" height="8" />
                    <rect x="0" y="220" rx="3" ry="3" width="380" height="8" />
                    <rect x="0" y="230" rx="3" ry="3" width="380" height="8" />
                </ContentLoader>)}
                {post && (<>
                    <h1 className='page-title'>{htmlDecode(post.title.rendered)}</h1>
                    {!!getSizePic(post, 'large') && (<img className='mainImage' alt={htmlDecode(post.title.rendered)} src={getSizePic(post, 'large')} />)}

                    <div className='authorBar'>
                        <span className='returnLink' onClick={() => navigate(-1)}>
                            <Icon orientation="left" icon="right-slim-arrow" />
                            {t('goBack')}
                        </span>
                        <div className='authorBlock'>
                            <img alt={author.name} src={author.avatar_urls[48]} width="30" height="30" />
                            <div>{t('blog.by')} : {author.name}<br />
                                {t('blog.dateOn')} {formatDate(post.date)}
                            </div>
                        </div>
                    </div>
                    <div className='articleBody' dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>

                    <TagList tagList={post._embedded['wp:term'][1]} />
                    <RelatedPostsList relatedPosts={relatedPosts} />
                </>)}
            </PageContent>

            <Footer />
        </>
    );
}

export default BlogArticle;