import styled from 'styled-components';
import Icon from '../ui/Icon';
import { colors } from '../constants';

const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,.7);
    z-index: 99;

    .modalContainer {
        z-index: 1;
        width: 600px;
        max-height: 100%;
        background: white;
        display: flex;
        border-radius: 5px;
        position: relative;
        animation: animate 0.3s;
        flex-direction: column;
        padding: 20px;

        .modalHeader {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #e5e5e5;
            padding-top: 0;
            padding-bottom: 20px;

            .modalTitle {
                font-size: 25px;
                color: ${colors.main};
            }

            .modalExit {
                cursor: pointer;

                i {
                    color: ${colors.darkGrey};
                }

            }
        }

        .modalContent {
            padding-top: 20px;
            color: ${colors.darkGrey};
            margin-bottom: 20px;
        }
    }

    @keyframes animate {
        from {
          transform: scale(0.5);
        }
        to {
          transform: scale(1);
        }
      }
`;

const Modal = ({ setShowModal, title, children }) => {

    return (
        <ModalContainer>
            <div className="modalContainer">
                <div className='modalHeader'>
                    <div className='modalTitle'> {title} </div>
                    <div onClick={() => setShowModal(false)} className='modalExit'> <Icon icon="croix" /> </div>
                </div>
                <div className='modalContent'>
                    <div> {children} </div>

                </div>
            </div>
        </ModalContainer>
    );
};

export default Modal;