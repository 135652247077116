import ReactGA from "react-ga";

export const initGA = (trackingID) => {
    ReactGA.initialize(trackingID, {
        titleCase: false,
    });
}

export const PageView = () => {

    // Si on n'est pas en environnement de prod
    if (process.env.REACT_APP_ENV !== 'production') {
        console.log(`PageView : ${(window.location.pathname + window.location.search)}`);
    } else {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
};

/**
 * Event - Add custom tracking event
 * @param {string} event
 * @param {string} typeClick
 */
export const ClickEvent = (event, typeClick) => {
    const objectToSendToGAClic =
    {
        category: 'Intéraction',
        action: event,
        label: typeClick,
    }

    // Si on n'est pas en environnement de prod
    if (process.env.REACT_APP_ENV !== 'production') {
        console.log('Informations envoyées à Analytics : ', objectToSendToGAClic);
    } else {
        ReactGA.event(
            objectToSendToGAClic
        );
    }
};
