import styled from 'styled-components';
import Link from '../ui/Link';
import DirectionButton from '../ui/buttons/DirectionButton';
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { colors, layout, fontSizes, breakpoints, weight } from '../constants';
import { ClickEvent } from '../hook/Tracking';

const TestimonyBlockContainer = styled.div`
    .fade-enter {
        opacity: 0;
    }

    .fade-enter-active {
        opacity: 1;
        transition: opacity 200ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
    }

    .fade-exit {
        opacity: 1;
    }

    .fade-exit-active {
        opacity: 0;
        transition: opacity 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
`;

const ImageContainer = styled.div`
    position: relative;
    height: 200px;
    width: 200px;

    @media (min-width: ${breakpoints.large}) {
        height: auto;
        width: auto;
    }

    .photoBlock4 {
        height: 100%;
        width: 200px;
        border-radius: 60px 8px 8px;
        object-fit:cover;
        filter: grayscale(100%);

        @media (min-width: ${breakpoints.large}) {
            height: 290px;
            width: 290px;
            border-radius: 84px 10px 10px;
        }
    }

    .imageBlock4 {
        position: absolute;
        bottom: -20px;
        right: -20px;
        width: 58px;
        height: 58px;
        background-color:#FFF;
        border-radius: 10px;


        img {
            border: 4px solid #f3fdfd;
            border-radius: 10px;
            width: 100%;
            height: auto;
        }
    }
`;

const CommentContainer = styled.div`
    margin-top: 40px;
    padding: 35px ${layout.huge}px;
    background: ${colors.white};
    border: 1px solid ${colors.main};
    border-radius: 8px;
    border-bottom-right-radius: 50px;

    @media (min-width: ${breakpoints.large}) {
        height: 290px;
        margin-top: 0;
        padding: 30px 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 45px;
        max-width: 650px;
    }

    .commentText {
        color: ${colors.darkGrey};
        font-size: ${fontSizes.large}px;
        font-style: italic;
        line-height: 22px;
        font-weight: ${weight.light};

        @media (min-width: ${breakpoints.large}) {
            font-size: ${fontSizes.verylarge}px;
        }
    }

    .commentBottomPart {
        @media (min-width: ${breakpoints.large}) {
            display: flex;
            align-items:center;
            justify-content: space-between;
        }

        .commentInformations {
            margin-top: ${layout.large}px;

            .commentUser {
                color: ${colors.darkGrey};
                font-size: ${fontSizes.veryLarge}px;
                font-weight: ${weight.medium};

                @media (min-width: ${breakpoints.large}) {
                    font-size: 20px;
                }
            }

            .commentInfoUser {
                color: ${colors.main};
                font-size: ${fontSizes.medium}px;
                font-weight: ${weight.light};

                @media (min-width: ${breakpoints.large}) {
                    font-size: ${fontSizes.large}px;
                }

                & > a {
                    font-size: ${fontSizes.medium}px !important;
                    padding-bottom: 4px;

                    @media (min-width: ${breakpoints.large}) {
                        font-size: ${fontSizes.large}px !important;
                    }
                }
            }
        }

        .directionButtons {
            display: flex;
            margin-top: ${layout.veryLarge}px;

            & > button:first-child {
                margin-right: ${layout.verySmall}px;
            }
        }
    }

`;

const TestimonyBlock = ({ picture, image, opinion, fullName, job, client, entrepriseLink, changeCard, setUseAnimation }) => (
    <TestimonyBlockContainer>
        <SwitchTransition>
            <CSSTransition key={opinion} timeout={{ enter: 200, exit: 100 }} classNames="fade" addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
            }} >
                <ImageContainer>
                    <img alt={fullName} src={picture} width="290" height="290" className="photoBlock4" />
                    <div className='imageBlock4'>
                        <img alt={client} src={image} width="100%" height="auto" className="" />
                    </div>
                </ImageContainer>
            </CSSTransition>
        </SwitchTransition>

        <CommentContainer>
            <SwitchTransition>
                <CSSTransition key={opinion} timeout={200} classNames="fade" addEndListener={(node, done) => {
                    node.addEventListener("transitionend", done, false);
                }}>
                    <div className='commentText'>
                        ”{opinion}”
                    </div>
                </CSSTransition>
            </SwitchTransition>
            <div className='commentBottomPart'>
                <SwitchTransition>
                    <CSSTransition key={opinion} timeout={200} classNames="fade" addEndListener={(node, done) => {
                        node.addEventListener("transitionend", done, false);
                    }}>
                        <div className="commentInformations">
                            <div className="commentUser">
                                {fullName}
                            </div>
                            <div className="commentInfoUser">
                                {job} <Link href={entrepriseLink}> @{client} </Link>
                            </div>
                        </div>
                    </CSSTransition>
                </SwitchTransition>
                <div className='directionButtons'>
                    <DirectionButton label="Témoignage précédent" direction="left" onClick={() => { changeCard(-1); setUseAnimation(true); ClickEvent('Clic', `clic-Temoignage-fleche-gauche-home`) }} />
                    <DirectionButton label="Témoignage suivant" onClick={() => { changeCard(1); setUseAnimation(true); ClickEvent('Clic', `clic-Temoignage-fleche-droite-home`) }} />
                </div>
            </div>
        </CommentContainer>
    </TestimonyBlockContainer >
)

export default TestimonyBlock;