import Header from '../components/structure/Header';
import { CalendlyBlock } from '../components/block';

const CalendlyPage = ({url}) => {
    
    return (
        <>
            <Header/>
            <CalendlyBlock url={url} />            
        </>
    );
}

export default CalendlyPage;