import { Widget } from '@typeform/embed-react';
import styled from 'styled-components';

const TypeformContainer = styled.div`
    .TypeformWidget {
        height: calc(100vh - 85px);
    }
`;

const TypeformBlock = ({formId}) => {

    return (
        <TypeformContainer>
            <Widget id={formId} className="TypeformWidget" hideFooter hideHeaders />
        </TypeformContainer>        
    );
}

export default TypeformBlock;