import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors, layout, fontSizes, breakpoints, weight } from '../constants';
import InputText from '../ui/inputs/InputText';
import CtaButton from '../ui/buttons/CtaButton';
import { block7Datas } from '../../datas/blocks';
import { useState } from 'react';
import { neonomadeFinderUrl, neonomadeUrl } from '../../datas/commonLinks';
import { ClickEvent } from '../hook/Tracking';
import { ClickEventGA4 } from '../hook/TrackingGA4';

const Block7Container = styled.div`
    padding: 80px 30px;
    background-color: ${colors.white};

    @media (min-width: ${breakpoints.large}) {
        padding: 150px 0px 130px;
    }

    h2 {
        color: ${colors.darkGrey};
        text-align: center;
        font-size: 30px;
        margin: 0;
        margin-bottom: 30px;
        font-weight: ${weight.medium};
    }

    .subtitle {
        font-size: ${fontSizes.large}px;
        text-align: center;
        color: ${colors.darkGrey};
        font-weight: ${weight.regular};
    }

    .searchBlock {
        margin-top: 40px;

        @media (min-width: ${breakpoints.medium}) {
            display: flex;
            justify-content: center;
        }

        .searchInput {
            width: 100%;

            @media (min-width: ${breakpoints.medium}) {
                width: 350px;
            }
        }

        .ctaLaunchSearch {
            width: 100%;
            margin-top: ${layout.small}px;

            @media (min-width: ${breakpoints.medium}) {
                margin-top: 0px;
                width: auto;
                margin-left: ${layout.small}px;
            }
        }
    }

    .citiesContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 60px;

        @media (min-width: ${breakpoints.medium}) {
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: space-evenly;
        }
    }
`;

const CityContainer = styled.div`
    width: 100%;
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: auto;
    margin: 30px 10px;

    // @media (min-width: ${breakpoints.medium}) {
    //     width: calc(100% / 3 - 20px) !important;
    //     align-items: center;
    // }

    @media (min-width: ${breakpoints.medium}) {
        width: 100% !important;
        max-width: 210px;
    }

    .imgContainer {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        @media (min-width: ${breakpoints.medium}) {
            // width: 170px;
        }

        img{
            width: 100%;
            border-radius: 65px 10px;
            max-height: 390px;

            @media (min-width: ${breakpoints.medium}) {
                width: 100%;
                height: 250px;
                object-fit: cover;
                // height: 250px;
            }
        }

        .labelCity {
            position: absolute;
            top: 0;
            right: 0;
            font-size: ${fontSizes.veryLarge}px;
            background-color: white;
            box-shadow: 0px 0px 0px 3px rgba(255,255,255,0.5);
            border-radius: 8px;
            color: ${colors.darkGrey};
            padding: 6px 20px;
            margin: 12px;
            font-weight: ${weight.regular};

            @media (min-width: ${breakpoints.medium}) {
                font-size: ${fontSizes.medium}px;
                padding: 4px 12px;
                box-shadow: 0px 0px 0px 2px rgba(255,255,255,0.5);
            }
        }
    }

    .textContainer {
        margin-top: ${layout.huge}px;
        font-weight: ${weight.regular};
        font-size: ${fontSizes.medium}px;

        @media (min-width: ${breakpoints.medium}) {
            width: 100%;
            margin: auto;
            margin-top: ${layout.huge}px;
        }

        .textLocation {
            color: ${colors.darkGrey};
            margin-bottom: 15px;
            display: block;
            align-items:center;
            text-decoration:none;

            &.textLocationLink {
                color: ${colors.main};
                cursor: pointer;
            }

            &:hover {
                color: ${colors.dark};
                transition-property: color;
                transition-duration: 200ms;
            }

            i {
                display: inline-block;
                padding-top: 4px;
                margin-left: ${layout.verySmall}px;
            }
        }
    }
`;

const Block7 = () => {

    // On recupère la fonction de traduction
    const { t } = useTranslation('translation', { keyPrefix: 'home.block7' });

    const [searchCity, setSearchCity] = useState('')
    return (
        <Block7Container>
            <h2>{t('h2')}</h2>
            <div className='subtitle'>{t('subTitle')}</div>

            <div className='searchBlock'>
                <InputText onChange={(e) => setSearchCity(e.target.value)}
                    placeHolder="Paris, Nantes, Bordeaux, Toulouse..."
                    className='searchInput'
                    inputId='search'
                    onClick={() => ClickEvent('Clic', 'clic-champ-localisation-home')} />
                <CtaButton className='ctaLaunchSearch'
                    href={`${neonomadeUrl}/s/${searchCity.toLowerCase()}`}
                    onClick={() => ClickEventGA4('clic-trouver-bureau', 'clic-trouver-bureau-lancer-ma-recherche')}>
                    {t('startReseach')}
                </CtaButton>
            </div>

            <div className='citiesContainer'>
                {block7Datas.map((data) => (
                    <CityContainer key={`city-${data.city}`}>
                        <a className='imgContainer' href={`${neonomadeFinderUrl}/${data.city.toLowerCase()}/location-bureau-coworking`} onClick={() => ClickEventGA4('clic-trouver-bureau', `clic-trouver-bureau-photo-${data.city.toLowerCase()}`)}>
                            <img alt="city" src={data.picture} srcSet={`${data.picture2x} 2x`} width="210" height="250" />
                            <div className='labelCity'> {data.city} </div>
                        </a>
                        <div className='textContainer'>
                            <a className='textLocation' href={`${neonomadeFinderUrl}/${data.city.toLowerCase()}/location-bureau-coworking`} onClick={() => ClickEventGA4('clic-trouver-bureau', `clic-trouver-bureau-texte-bureau-${data.city.toLowerCase()}`)}> {t('textLocation', { city: data.city })} </a>
                            <a href={`${neonomadeFinderUrl}/${data.city.toLowerCase()}/espace-coworking`} className='textLocation textLocationLink' onClick={() => ClickEventGA4('clic-trouver-bureau', `clic-trouver-bureau-texte-espace-${data.city.toLowerCase()}`)}> {t('coworkingSpaceLocation', { city: data.city })} </a>
                            <a href={`${neonomadeFinderUrl}/${data.city.toLowerCase()}/salle-reunion-formation-location`} className='textLocation textLocationLink' onClick={() => ClickEventGA4('clic-trouver-bureau', `clic-trouver-bureau-texte-reunion-${data.city.toLowerCase()}`)}> {t('meetingSpaceLocation', { city: data.city })} </a>
                            <a href={`${neonomadeFinderUrl}/${data.city.toLowerCase()}/bureau-ferme-coworking`} className='textLocation textLocationLink' onClick={() => ClickEventGA4('clic-trouver-bureau', `clic-trouver-bureau-texte-privatif-${data.city.toLowerCase()}`)}> {t('privateSpaceLocation', { city: data.city })} </a>
                            <a href={`${neonomadeFinderUrl}/${data.city.toLowerCase()}/salle-seminaire-conference-evenementiel`} className='textLocation textLocationLink' onClick={() => ClickEventGA4('clic-trouver-bureau', `clic-trouver-bureau-texte-evenementiel-${data.city.toLowerCase()}`)}> {t('eventSpaceLocation', { city: data.city })} </a>
                        </div>
                    </CityContainer>
                ))}
            </div>

        </Block7Container>
    );
}

export default Block7;