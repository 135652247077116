import { useEffect, useState } from 'react';
import BlogArticleList from './components/BlogArticleList';
import { useParams } from 'react-router-dom';
import { fetchPosts, formatPostToThumbnail } from '../utils/blogUtils';
import ErrorPage from '../pages/ErrorPage';

const BlogCategory = ({title, description, categoryId, displayMenu, linkText}) => {
    const params = useParams();
    const page = !! params.page ? parseInt(params.page) : 1;
    
	const [isLoading, setLoading] = useState(true);
    const [apiData, setApiData] = useState({status:true});

    useEffect(() => {       
        const fetchData  = async () => {
            setLoading(true);
            window.scrollTo({ top: 0, behavior: 'smooth' })
            setApiData({status:true});
            const postsData = await fetchPosts({
                page,
                categories: categoryId,
                per_page: 9
            });
            setApiData(postsData);
            setLoading(false);
        }
        categoryId && fetchData();

    }, [page, categoryId]);


    return apiData.status ? (
        <BlogArticleList 
            title={title}
            description={description}
            isLoading={isLoading}
            page={page}
            pageCount={apiData.pageCount}
            postList={apiData.postList && apiData.postList.map(post => formatPostToThumbnail(post))}
            displayMenu={displayMenu}
            linkText={linkText}
        />
    ) : (
        <ErrorPage errorCode={4} />
    );    
}

export default BlogCategory;