import styled, { css } from 'styled-components';
import { layout, fontSizes, gradients, colors } from '../../constants';
import { Link } from 'react-router-dom';

const LinkContainer = styled(Link)`
    border-radius: 8px;
    padding: 0px ${layout.huge}px;
    cursor: pointer;
    text-decoration: none;
    transition-duration: 200ms;
    transition-property: background-color, color;
    line-height: ${fontSizes.large}px;
    text-align: center;
    display:flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    text-transform: uppercase;
    font-size: ${fontSizes.large}px;
    font-family: "latoBold", Helvetica, Arial, sans-serif;

    ${(props) => {
        switch (props.mode) {
            case 'cta': {
                return css`
                    transition-property: opacity;
                    border: 0px;
                    color: white;
                    
                    background: linear-gradient(
                        135deg,
                        ${gradients.mainGradient}
                    );
                    
                    &.CtaWhite {
                        background: white;
                        border: none;
                        color: ${colors.main};
                
                        &:hover {
                            opacity: 80%;
                        }
                    }
                
                    &:hover {
                        opacity: 60%;
                    }
                
                    & > span {
                        margin-right: ${layout.verySmall}px;
                    }
                `;
            }

            case 'shadow-blue': {
                return css`
                    background-color: ${colors.main};
                    color: ${colors.white};
                    border: solid 1px ${colors.main};
                    box-shadow: 0px 3px 20px rgba(142, 157, 175, 0.35);

                    &:hover {
                        background-color: ${colors.white};
                        color: ${colors.main};
                    }
                `;
            }

            case 'button':
            default: {
                return css`
                    background-color: transparent;
                    border: solid 1px ${colors.main};
                    color: ${colors.main};
                
                    &:hover {
                        background-color: ${colors.main};
                        color: ${colors.white};
                    }
                `;
            }
        }
    }}
`;

const InternalLinkAsButton = (props) => {
    return (
        <LinkContainer {...props}>{props.children}</LinkContainer>
    );
}

export default InternalLinkAsButton;