import ReactGA from "react-ga4";

/**
 * Initialization of GA4
 */
export const initGA4 = (trackingID, gaOptions) => {

    // Si il n'y a toujours pas de configuration
    if (!window.dataLayer ||  window.dataLayer.find(x => x[0] === 'config') === undefined) {

        ReactGA.initialize([
            {
                trackingId: trackingID,
                gaOptions: gaOptions
            },
        ]);

    } else {
        window.dataLayer.find(x => x[0] === 'config')[2] = gaOptions;
    }

}

/**
 * Event - Add custom tracking event for GA4
 * @param {string} event
 * @param {string} typeClick
 */
export const ClickEventGA4 = (eventName, typeClick) => {

    const objectToSendToGAClic =
    {
        'type-clic': typeClick
    }

    // Si on n'est pas en environnement de prod
    if (process.env.REACT_APP_ENV !== 'production') {
        console.log('Informations envoyées à Analytics :',
            "\nNom de l'évenement: ", eventName,
            "\nDonnées envoyées: ", objectToSendToGAClic,
            '\nLa configuration GA4 est:', window.dataLayer.find(x => x[0] === 'config')
        );
    } else {
        ReactGA.event(
            eventName,
            objectToSendToGAClic
        );
    }
};