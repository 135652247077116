import styled from 'styled-components';
import { breakpoints } from '../constants';
import {neonomadeBoUrl} from '../../datas/commonLinks'

const BannerWorklib = styled.div`
    display: flex;
    position: fixed;
    width:100%;
    top: 80px;
    box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0.08);
    height: 80px;
    color: white;
    padding: 16px 0px;
    background-color: #4D4DFF;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .worklibLogo {
        width: 155px;
        margin-right: 12px;

        @media (max-width: ${breakpoints.medium}) {
            width: 80px;
        }
    }

    .ctaButton {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #42B4C5;
        border-radius: 19.2px;
        width: 164px;
        height: 48px;
        font-size: 16px;

        @media (max-width: ${breakpoints.medium}) {
            width: 135px;
        }

        @media (max-width: ${breakpoints.small}) {
            width: 110px;
            font-size: 12px;
        }
    }

    h4 {
        margin: 0;
        font-size: 28px;
        margin-right: 12px;
        z-index: 1;

        @media (max-width: ${breakpoints.medium}) {
            font-size: 12px;
        }

        @media (max-width: ${breakpoints.tiny}) {
            font-size: 10px;
        }
    }

    .shapes {
        position: absolute;
        right: 0;
        height: 100%;
    }

    .shape {
        position: absolute;

        &.hand {
            z-index: 0;
            bottom: 0;

            &.leftHand {
                left: 15px;;
            }

            &.rightHand {
                right: 45px;
            }
        }

        @media (max-width: ${breakpoints.medium}) {
            display: none;
        }
    }

    a {
        color: white;
        z-index: 1;
        display: block;
        text-decoration: none;
    }

    .bannerTextContainer {
        display: contents;
        @media (max-width: ${breakpoints.tiny}) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
    
        }
        
    }
`

const WorklibBanner = () => {
    return (<BannerWorklib>
        <img alt='shape worklib' class="shape shapes" src={`${neonomadeBoUrl}/image/bannerWorklib/shapes.svg`} /> 
        <img alt='shape right hand' class="shape hand rightHand" src={`${neonomadeBoUrl}/image/bannerWorklib/zenitude-illustration.svg`}/>
        <img alt='shape left hand' class="shape hand leftHand" src={`${neonomadeBoUrl}/image/bannerWorklib/hands-illustration.svg`}/>
        <div class="bannerTextContainer">
            <h4>Neo-nomade rejoint</h4>
            <img alt='worklib logo' class="worklibLogo" src={`${neonomadeBoUrl}/image/bannerWorklib/worklib-logo.svg`}/>
        </div>
        <a target="_blank" rel="noreferrer" href="https://worklib.notion.site/Tout-savoir-sur-le-rachat-de-Neo-nomade-par-worklib-9e37ac5fbc9b44cfbee0278251e111c3">
            <div class="ctaButton">
                En savoir plus
            </div>
        </a>
    </BannerWorklib>)
};

export default WorklibBanner;