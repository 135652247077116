import styled, { keyframes } from "styled-components";

const rotate = keyframes`
    to {
        transform: rotate(360deg);
    }
`;

const LoaderContainer = styled.span`
    display: inline-block;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-top-color: white;
    width: 20px;
    height: 20px;
    animation: ${rotate} 1.5s linear infinite;
`;

const Loader = (props) => {
    return (
        <LoaderContainer {...props} />
    );
}

export default Loader;