import styled, { css } from 'styled-components';

const IconContainer = styled.i`
    display: flex;

    ${(props) => {
        // En fonction de l'orientation demandée
        switch (props.orientation) {
            case 'bottom': {
                return css`
                    transform: rotate(90deg);
                `;
            }

            case 'left': {
                return css`
                    transform: rotate(180deg);
                `;
            }

            case 'top': {
                return css`
                    transform: rotate(270deg);
                `;
            }
            default: {
                return css``;
            }
        };
    }}

    ${(props) => {
        // En fonction de la taille demandée
        switch (props.size) {
            // 2 fois plus petit que la font-size du parent
            case 'smallest': {
                return css`
                    font-size: 0.5em;
                `;
            }

            // 30% plus petit que la font-size du parent
            case 'smaller': {
                return css`
                    font-size: 0.66em;
                `;
            }

            // 30% plus grand que la font-size du parent
            case 'bigger': {
                return css`
                    font-size: 1.33em;
                `;
            }

            // 2 fois plus grand que la font-size du parent
            case 'biggest': {
                return css`
                    font-size: 2em;
                `;
            }

            default: {
                return css`
                    font-size: inherit;
                `;
            }
        };
    }}

    ${(props) => props.gap > 0 && css`
        position: relative;
        top: ${props.gap}px;
    `}
`;

const Icon = ({ icon, className, size, orientation, gap = 0 }) => {
    const iconeClass = typeof icon === 'string' ? `nn-icon-${icon.replace('nn-icon-','')}` : '';

    return (
        <IconContainer
            className={`${iconeClass} ${className}`}
            size={size}
            orientation={orientation}
            gap={gap}
        />
    );
}

export default Icon;