import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components"
import { fetchCategories } from "../../utils/blogUtils";
import { ClickEvent } from '../../components/hook/Tracking';
import { breakpoints } from "../../components/constants";
import { blogMenuId } from "../../datas/commonLinks";

const SubHeader = styled.div`
    color: #5B5B5B;
    margin: 73px 0;
    overflow-x:auto;
    display: flex;
    background-color: #F5F5F5;
    width: 100%;
    position: fixed;
    z-index: 2;
    border-top: 1px solid #CECECE;
    border-bottom: 1px solid #CECECE;
    justify-content: center;
    max-width: inherit;
    
    @media screen and (min-width: ${breakpoints.large}) {
        margin: 80px 0;
    }
    
    .linkContainer {
        display: flex;
        width: 100%;
        max-width: 1500px;

        @media screen and (min-width: ${breakpoints.medium}) {
            justify-content: space-around;
        }

        & > * {
            flex: 0 0 auto;
            padding: 15px;
            margin: 0 10px;
            text-decoration: none;
            color: #5B5B5B;

            &:hover {
            color: #2FB3BE;
            }

            &.current {
                border-bottom: 5px solid #2FB3BE;
            }
        }

        form {
            padding: 10px 15px;

            input {
                height: 100%;
                border-radius: 5px;
                border: 1px solid #CECECE;
                font-size: 15px;
                padding: 0 10px;
                outline: none;

                &:focus {
                    border: 1px solid #2FB3BE;
                }
            }
        }    
    }
`

const BlogMenu = ({currentPage}) => {
    let [searchParams] = useSearchParams();
    const { t } = useTranslation('translation');
    let navigate = useNavigate();
    const [searchValue, setSearchValue] = useState(!! searchParams.get("s") ? searchParams.get("s") : '')
    const [categoriesData, setCategoriesData] = useState('')
	//const [isLoading, setLoading] = useState(true);

    const lauchSearch = (e) => {
        e.preventDefault();
        navigate(`/blog?s=${searchValue}`);
    }

    useEffect(() => {
        (async () => {
            const categoriesData = await fetchCategories({parent: blogMenuId});
            setCategoriesData(categoriesData);
        })();
    }, []);

    useEffect(() => {
        // On récupère l'element current
        const currentMenu = document.querySelector('#blogHeader .current');

        // Si on a un menu courant
        if (currentMenu) {
            // On calcul l'offset pour centrer le bloc
            const offset = (window.innerWidth - currentMenu.offsetWidth) / 2;

            // On récupère la position du bloc - l'offset
            const left = currentMenu.offsetLeft - offset;

            // On scroll le menu jusqu'à la position voulue
            document.querySelector('#blogHeader').scroll(left,0);
        }
    }, [categoriesData,currentPage])

    return (
        <SubHeader id="blogHeader">
            <div className="linkContainer">
                <Link to="/blog" className={currentPage === 'home' ? 'current' : ''}>{t('blog.home')}</Link>
                {categoriesData && categoriesData.map(c => <Link key={c.slug} to={`/blog/category/${c.slug}`} className={currentPage === c.slug ? 'current' : ''}>{c.name}</Link>)}            
                <form onSubmit={lauchSearch}>
                    <input onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                        placeholder={t('blog.articleSearch')}
                        onClick={() => ClickEvent('Clic', 'clic-champ-recherche-blog')} />
                </form>
            </div>
        </SubHeader>
    );
}

export default BlogMenu