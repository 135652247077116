import { FormContactBlock, BlockNewsletter, TrustOpinionsBlock } from '../components/block';
import Meta from "../components/structure/Meta"
import Header from '../components/structure/Header';
import Footer from '../components/structure/Footer';
import RenderHeaderSticky from '../components/hook/RenderHeaderSticky';

const Contact = () => {
    // On appelle le hook pour rendre le header sticky + ajouter un margin au block 1
    const isScrollingUp = RenderHeaderSticky();

    return (
        <>
            <Meta 
                title="Découvrez le Pass Coworking : Réservez une démo"
                description = "Donnez accès à plus de 1300 espaces de coworking à la demande à vos salariés partout en France"
                url="/coworking/contact-us"
            />
            <Header fixed={true} mode="white" isScrollingUp={isScrollingUp} />

            <div>
                <FormContactBlock />
                <TrustOpinionsBlock />
                <BlockNewsletter />
            </div>

            <Footer />
        </>
    );
}

export default Contact;