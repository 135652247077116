import Slider from 'react-slick';

const LogosCarousel = ({ logosDatas, responsive, mode }) => {
    const sliderSettings = {
        infinite: true,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        lazyLoad: true,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 3000,
        speed: 1500,
        pauseOnHover: false,
        responsive
    };

    return (
        <Slider {...sliderSettings}>
            {logosDatas.map((data, index) => (
                <img key={index} src={data.src[mode]} srcSet={data.srcset[mode]} className={data.className} alt={data.alt}></img>
            ))}
        </Slider>
    )
};

export default LogosCarousel;