import styled from 'styled-components';


const NnCheckBox = styled.label`
    display: flex;
    flex-direction: row-reverse;
    align-items: end;
    margin: 0;
    padding: 0;
    cursor:pointer;
   
    input[type="checkbox"] {
        -moz-appearance: none;
        -webkit-appearance: none;
        transition-property: all;
        transition-duration: 200ms;
        transition-timing-function: ease-out;
        height: 20px;
        width: 20px;
        max-height: 20px;
        max-width: 20px;
        margin: 0;
        padding: 0;
        border: 1px solid #5B5B5B;
        border-radius: 3px;
        background-color: white;
        position: relative;
        margin-right: 10px;
        margin-left: 10px;

        &:hover:not(:checked):not(:disabled) {
            border-color: #60D0DA;
        }

        ~ span {
            flex: 1;
            font-size: 16px;
            color: #5B5B5B;
            hyphens: none;
        }
    
        &:checked{
            border-color: #2FB3BE;
            background-color: #2FB3BE;

            ::after {
                font-family: "nnIcons" !important;
                content: '\\eb02';
                display: block;
                font-size: 13px;
                margin-top: 2px;
                text-align: center;
                color: white;
            }

            ~ span {
                font-weight:600;
            }
        }
    }
`

const CheckBox = ({title, onChange}) => (	
    <NnCheckBox>
        <input type="checkbox" onChange={(e) => onChange(e.target.checked)} />
        <span>{title}</span>
    </NnCheckBox>
)

export default CheckBox;