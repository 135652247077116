import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchCategories } from '../utils/blogUtils';
import ErrorPage from '../pages/ErrorPage';
import ContentLoader from 'react-content-loader';
import { BlogCategory } from '.';

const BlogCategoryFromSlug = () => {
    const params = useParams();
    const [category, setCategoryData] = useState(null);

    useEffect(() => {
        (async () => {
            const categoriesData = await fetchCategories({ slug: params.slug });
            setCategoryData(categoriesData.length === 1 ? categoriesData[0] : false);
        })();
    }, [params.slug]);

    return category ? (
        <BlogCategory
            title={category.name}
            description={category.description}
            categoryId={category.id}
            displayMenu={true}
        />
    ) : (
        <>{category === false && <ErrorPage errorCode={4} />}
            {category !== false && <ContentLoader viewBox="0 0 380 240">
                <rect x="5" y="5" rx="2" ry="2" width="370" height="125" />
                <rect x="5" y="140" rx="3" ry="3" width="180" height="100" />
                <rect x="195" y="140" rx="2" ry="2" width="180" height="100" />
            </ContentLoader>}
        </>
    );


}

export default BlogCategoryFromSlug;