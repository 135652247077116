import React from 'react';
import styled from 'styled-components';
import { weight, colors } from '../constants';
import Icon from '../ui/Icon';
import { ClickEvent } from '../hook/Tracking';
import { ClickEventGA4 } from '../hook/TrackingGA4';
import { neonomadeFinderUrl, neonomadeBoUrl } from '../../datas/commonLinks';
import i18n from '../../plugin/i18next/i18next';
import { Link, useLocation } from 'react-router-dom';

/**
 * Fonction pour ouvrir le menu de cookie
 */
const OpenMenuCookie = () => {
    // Si la fonction d'ouverture d'axeptio existe bien
    if (typeof (window.openAxeptioCookies) == "function") {
        // On ouvre la popup axceptio avec un paramètre qui permet d'ouvrir l'étape de choix des cookies
        window.openAxeptioCookies({ currentStepIndex: 1 });

        // On cache le bouton axceptio par défaut
        window.hideAxeptioButton();
    }
}

const SubMenuContainer = styled.ul`

padding: 0;
margin: 25px 0;
list-style-type: none;
overflow: hidden;

.contentMenu {
    font-weight: ${weight.light};

    .titleContent {
        font-weight: ${weight.regular};
    }
}

.blocMenuMobile {
    padding-bottom: 80px;
}

li {
    margin: 0 20px;

    &:not(:last-child) {
        margin-bottom: 20px;
    }

    .iconsMenu {
        color: ${colors.darkGrey};
        height: 25px !important;
        width: 25px!important;
        font-size: 25px !important;
        margin-top: 7px;
        margin-right: 13px;
        transition-property: color;
        transition-duration: 200ms;
    }

    a, .btnMenu {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        color: rgba(54, 54, 54, 0.5);
        cursor: pointer;
        text-decoration:none;
        transition-property: color;
        transition-duration: 200ms;

        .contentMenu {
            color: rgba(54,54,54,0.5);
            text-decoration: none;
            font-size: 13px;
            align-self: center;

            .titleContent {
                color: ${colors.darkGrey};
                transition-property: color;
                transition-duration: 200ms;
                font-size: 16px;

                :first-letter {
                    text-transform: uppercase;
                }
            }
        }

        &:hover {
            .contentMenu, .iconsMenu {
                color: ${colors.main};

                .titleContent {
                    color: ${colors.main};
                }
            }
        }
    }
}
`;

const LinkMenu = ({ url, icon, title, description, isInternal, gaEventName = "clic-menu-connecte", id }) => {
    const menuClick = () => ClickEventGA4('clic-menu', gaEventName);

    const contains = (
        <>
            {!!icon && (<Icon icon={icon} size='bigger' className='iconsMenu' />)}
            <div className='contentMenu'>
                <div className='titleContent'>{title}</div>
                {description && (<div>{description}</div>)}
            </div>
        </>
    )

    return (
        <li id={id}>
            {isInternal ? (
                <Link to={isInternal ? url : { pathname: url }} onClick={menuClick}>{contains}</Link>
            ) : (
                <a href={url} onClick={menuClick}>{contains}</a>
            )}
        </li>
    )
}

const ButtonMenu = ({ icon, title, description, gaEventName = "clic-menu-connecte", id, onClick }) => (
    <li id={id} onClick={onClick} >
        <div className='btnMenu' onClick={() => ClickEvent('Clic', gaEventName)}>
            {!!icon && (<Icon size='bigger' icon={icon} className={`iconsMenu`} />)}
            <div className='contentMenu'>
                <div className='titleContent'>{title}</div>
                {description && (<div>{description}</div>)}
            </div>
        </div>
    </li>
)

const CustomUserMenu = ({ menuList }) => menuList.map((m, i) => <LinkMenu key={`userMenu-${i}`} url={m.url} icon={m.icon} title={m.title} description={m.description} gaEventName={m.gaEventName} />);
const ForfaitListButton = ({ display, action }) => display && <ButtonMenu title={i18n.t('header.userMenu.blockForfaitManager.title')} icon="mes-contrats" description={i18n.t('header.userMenu.blockForfaitManager.btnDescription')} onClick={action} />
const DeconnectButton = () => {
    const location = useLocation();
    return <LinkMenu url={`${neonomadeFinderUrl}?deconnexion&redirect=${encodeURIComponent(location.pathname + location.search)}`} title={i18n.t('header.userMenu.logOutBtn.title')} icon="logout" description={i18n.t('header.userMenu.logOutBtn.description')} gaEventName="clic-menu-connecte" />
}
const AdminAcessButton = ({ accessAdmin }) => accessAdmin !== false && (<LinkMenu title={accessAdmin.title} icon={accessAdmin.icon} description={accessAdmin.description} url={accessAdmin.url} />)
const CookieButton = () => <ButtonMenu title={i18n.t('header.userMenu.manageCookie.title')} icon="cookie" gaEventName="clic-menu-cookies" onClick={OpenMenuCookie} />

const SubMenu = ({ menuList, connected, needManageForfaitBlock, access = null, mobileView = false, }) => {
    let accessAdmin = false;

    function toggleManageForfaitBlock(e) {
        e.stopPropagation();

        const subMenuUserContainerToSlide = document.getElementById('subMenuUserContainerToSlide');
        subMenuUserContainerToSlide.classList.toggle("wentToSeeManageForfaitBlock");
    }

    // Si l'utilisateur a accès à un admin
    if (connected && access !== null && (!!access.accessBoEntreprise || !!access.accessBoEspace)) {

        if (access.accessBoEntreprise) {
            accessAdmin = {
                "title": i18n.t('header.userMenu.accessBtn.accessBoEntreprise.title'),
                "description": i18n.t('header.userMenu.accessBtn.accessBoEntreprise.description'),
                "icon": "mes-espaces-3",
                "url": neonomadeBoUrl
            };
        }
        else if (access.accessBoEspace) {

            if (parseInt(access.countEspace) === 0) {
                accessAdmin = {
                    "title": i18n.t('header.userMenu.accessBtn.accessBoEspaceWithoutEspace.title'),
                    "description": i18n.t('header.userMenu.accessBtn.accessBoEspaceWithoutEspace.description'),
                    "icon": "ajoute-espace",
                    "url": `${neonomadeBoUrl}/espace/espaces`,
                };
            } else {
                accessAdmin = {
                    "title": i18n.t('header.userMenu.accessBtn.accessBoEspace.title'),
                    "description": i18n.t('header.userMenu.accessBtn.accessBoEspace.description'),
                    "icon": "mes-espaces-3",
                    "url": neonomadeBoUrl,
                };
            }
        }
    }

    return (
        <SubMenuContainer>

            {/* Si on veut le menu utilisateur et qu'on est sur la vue desktop */}
            {connected && !mobileView && menuList && (
                <>
                    <div className='fixedLink'>
                        <ForfaitListButton display={needManageForfaitBlock} action={toggleManageForfaitBlock} />
                        <AdminAcessButton accessAdmin={accessAdmin} />
                        <CookieButton />
                        <DeconnectButton />
                    </div>
                    <div className='dynamicLink'>
                        <CustomUserMenu menuList={menuList} />
                    </div>
                </>
            )}

            {/* Si on est sur la vue mobile */}
            {connected && mobileView && menuList && (
                <div className="blocMenuMobile">
                    <ForfaitListButton display={needManageForfaitBlock} action={toggleManageForfaitBlock} />
                    <CustomUserMenu menuList={menuList} />
                    <AdminAcessButton accessAdmin={accessAdmin} />
                    <CookieButton />
                    <DeconnectButton />
                </div>
            )}

            {/* Si on a pas d'information utilisateur */}
            {!connected && menuList && (
                menuList.map((m, i) => <LinkMenu key={i} url={m.url} icon={m.icon} title={m.title} isInternal={m.isInternal} description={m.description} gaEventName={m.gaEventName} />)
            )}

        </SubMenuContainer>
    );
}

export default SubMenu;